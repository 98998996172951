import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { AppContext } from "../../../App";
import {
  fetchJobsDataProto,
  fetchJobsProtoSimple,
  fetchMacroCategoriesProto,
  fetchPanelCodeDepartmentTemplatePresetsProto,
  fetchProblemCatalogs,
  fetchRemoteControlPresets,
  fetchUsers,
  getProblemCatalogFullName,
  parseJwt,
  picOnlySelect,
  remoteControlPresetToPayload,
  sendRemoteByName,
} from "../../../helpers";
import * as PahoMQTT from "paho-mqtt";
import { ExtDepartment, ExtUser, User } from "../../../models/model";
import {
  MasterJavaBaseModel,
  MeetingMeetingTaskProblemCatalog,
  MeetingProblemCatalog,
  MeetingProblemCatalogs,
  meetingProblemTypeToJSON,
  PpicJob,
  PpicJobs,
  PpicMacroCategories,
  PpicMacroCategory,
  PpicPanelCodeDepartmentTemplatePreset,
  PpicPanelCodeDepartmentTemplatePresets,
  PpicRemoteControlPayload,
  PpicRemoteControlPreset,
  PpicRemoteControlPresets,
  WorkOrderType,
  workOrderTypeFromJSON,
  workOrderTypeToJSON,
} from "../../../masterbigsystem";
import { Add, Delete, Refresh } from "@material-ui/icons";
import { v4 } from "uuid";
import murmur from "murmurhash-js";

export const woTypesData = [
  {
    label: "Job",
    value: WorkOrderType.WO_TYPE_JOB,
  },
  {
    label: "Generic",
    value: WorkOrderType.WO_TYPE_GENERIC,
  },
  {
    label: "Machine",
    value: WorkOrderType.WO_TYPE_MACHINE,
  },
  {
    label: "Task Detailed",
    value: WorkOrderType.WO_TYPE_DETAILS,
  },
  {
    label: "Job Overview",
    value: WorkOrderType.WO_TYPE_JOB_OVERVIEW,
  },
  {
    label: "Calendar",
    value: WorkOrderType.WO_TYPE_CALENDAR,
  },
  {
    label: "Dept Template",
    value: WorkOrderType.WO_TYPE_DEPT_TEMPLATE,
  },
  {
    label: "Journey Map",
    value: WorkOrderType.WO_TYPE_JOURNEY_MAP,
  },
  {
    label: "Meeting",
    value: WorkOrderType.WO_TYPE_MEETING,
  },
  {
    label: "Sales Plan",
    value: WorkOrderType.WO_TYPE_SALES_PLAN,
  },
  {
    label: "Sales Funnel",
    value: WorkOrderType.WO_TYPE_SALES_FUNNEL,
  },
  {
    label: "Sales Target vs Actual",
    value: WorkOrderType.WO_TYPE_SALES_TARGET_VS_ACTUAL,
  },
  {
    label: "Sales - Pre Sales",
    value: WorkOrderType.WO_TYPE_PRE_SALES,
  },
  {
    label: "JDP",
    value: WorkOrderType.WO_TYPE_JDP,
  },
  {
    label: "Problem Details",
    value: WorkOrderType.WO_TYPE_PROBLEM_DETAILS,
  },
  {
    label: "Global Attendance",
    value: WorkOrderType.WO_TYPE_GLOBAL_ATTENDANCE,
  },
  {
    label: "Company Sales",
    value: WorkOrderType.COMPANY_SALES,
  },
  {
    label: "Company Sales 2",
    value: WorkOrderType.COMPANY_SALES_2,
  },
  {
    label: "Project Master Template Link",
    value: WorkOrderType.WO_TYPE_PROJECT_MASTER_TEMPLATE_LINK,
  },
  {
    label: "Ready to Assign",
    value: WorkOrderType.WO_TYPE_READY_TO_ASSIGN,
  },
];

export type ButtonGenerationType =
  | "Dept/Skip Date"
  | "Dept Users"
  | "1 Month Progress";
export const buttonGenerationTypeList: {
  label: string;
  value: ButtonGenerationType;
}[] = [
  {
    label: "Dept/Skip Date",
    value: "Dept/Skip Date",
  },
  {
    label: "Dept Users",
    value: "Dept Users",
  },
  {
    label: "1 Month Progress",
    value: "1 Month Progress",
  },
];

export type RemoteControlPageTopic =
  | "Today"
  | "Prod 1-1 Week"
  | "Prod 1-2 Week"
  | "Prod 1-3 Week"
  | "Prod 1-4 Week"
  | "Prod 1 Week"
  | "Prod 2 Week"
  | "Prod 3 Week"
  | "Prod 4 Week"
  | "Service 1 Week"
  | "Service 2 Week"
  | "Service 3 Week"
  | "Service 4 Week"
  | "Calendar"
  | "Job Overview"
  | "Shortage List"
  | "Manpower";

export const pageTopics: RemoteControlPageTopic[] = [
  "Today",
  // "Prod 1-1 Week",
  "Prod 1-2 Week",
  "Prod 1-3 Week",
  "Prod 1-4 Week",
  "Prod 1 Week",
  "Prod 2 Week",
  "Prod 3 Week",
  "Prod 4 Week",
  "Calendar",
  "Job Overview",
  "Shortage List",
  "Manpower",
];

const RemoteControlPage = () => {
  const ctx = useContext(AppContext);

  const [
    remoteControlPresetGeneratorResult,
    setRemoteControlPresetGeneratorResult,
  ] = useState<PpicRemoteControlPresets>(
    PpicRemoteControlPresets.fromPartial({ presets: [] })
  );
  const [tab, setTab] = useState<
    | "Manual"
    | "Multitable"
    | "Template"
    | "Macro"
    | "Macro-Category"
    | "Alias"
    | "Generator"
  >("Manual");
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [jobsProto, setJobsProto] = useState<PpicJobs>(
    PpicJobs.fromPartial({ jobs: [] })
  );

  const [selectedRemoteProblemCatalog, setSelectedRemoteProblemCatalog] =
    useState<MeetingProblemCatalog | null>(null);
  const [
    remoteControlPresetCategoryDeleteIds,
    setRemoteControlPresetCategoryDeleteIds,
  ] = useState<string[]>([]);
  const [remoteControlPresetCategories, setRemoteControlPresetCategories] =
    useState(PpicMacroCategories.fromPartial({ categories: [] }));
  const [skipDate, setSkipDate] = useState(0);
  const [picOnly, setPicOnly] = useState(null as boolean | null);
  const [remoteControlPresets, setRemoteControlPresets] = useState(
    PpicRemoteControlPresets.fromPartial({})
  );
  const [filterTaskDateOnly, setFilterTaskDateOnly] = useState(false);
  const [remoteControlPresetsFormer, setRemoteControlPresetsFormer] = useState(
    PpicRemoteControlPresets.fromPartial({})
  );
  const [jdpPreset, setJdpPreset] = useState(
    PpicPanelCodeDepartmentTemplatePreset.fromPartial({})
  );

  const [problemCatalogs, setProblemCatalogs] = useState(
    MeetingProblemCatalogs.fromPartial({})
  );
  const [remoteControlPresetDeleteIds, setRemoteControlPresetDeleteIds] =
    useState<string[]>([]);
  const [catalogPresetSearch, setCatalogPresetSearch] = useState("untitled");
  const [woScreenType, setWoScreenType] = useState(WorkOrderType.WO_TYPE_JOB);
  const [currentPageTopic, setCurrentPageTopic] =
    useState<RemoteControlPageTopic | null>(null);
  const [deptId, setDeptId] = useState<number | null>(null);
  const [userId, setUserId] = useState<number | null>(null);
  const [jobId, setJobId] = useState<string | undefined>(undefined);
  const [scheduled, setScheduled] = useState(false);
  const [jdpPresets, setJdpPrests] = useState(
    PpicPanelCodeDepartmentTemplatePresets.fromPartial({ presets: [] })
  );
  const [remoteControlGenerationSettings, setRemoteControlGenerationSettings] =
    useState({
      department: null as ExtDepartment | null,
      individual: null as ExtUser | null,
      skipDateAscending: true as boolean,
      skipDate: 0,
      tableType: WorkOrderType.WO_TYPE_GENERIC,
      name: "",
      screen: "Today" as RemoteControlPageTopic,
      generationType: "Dept/Skip Date" as ButtonGenerationType,
      job: null as PpicJob | null,
    });

  const [messageHistory, setMessageHistory] = useState<
    {
      msg: string | null | undefined;
      timestamp: any;
      topic: string | null | undefined;
    }[]
  >([]);
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    handleInit();
  }, []);
  const handleInit = async () => {
    fetchUsersData();
    fetchJobsData();
    fetchRemoteControlPresetsData();
    fetchMacroCategoriesData();
    fetchPresetsData();
    fetchProblemsData();
  };
  const fetchProblemsData = async () => {
    setProblemCatalogs(
      await fetchProblemCatalogs({ apiKey: ctx?.apiKey ?? "" })
    );
  };

  const fetchUsersData = async () => {
    setUsers(await fetchUsers({ apiKey: ctx?.apiKey }));
  };
  const fetchJobsData = async () => {
    const d = await fetchJobsProtoSimple({
      apiKey: ctx?.apiKey,
      withProducts: true,
    });
    if (d) {
      setJobsProto(d);
    }
  };
  const fetchPresetsData = async () => {
    setJdpPrests(
      await fetchPanelCodeDepartmentTemplatePresetsProto({
        apiKey: ctx?.apiKey ?? "",
      })
    );
  };

  const fetchRemoteControlPresetsData = async () => {
    setRemoteControlPresets(
      await fetchRemoteControlPresets({ apiKey: ctx?.apiKey ?? "" })
    );
    setRemoteControlPresetsFormer(
      await fetchRemoteControlPresets({ apiKey: ctx?.apiKey ?? "" })
    );
  };
  const fetchMacroCategoriesData = async () => {
    setRemoteControlPresetCategories(
      await fetchMacroCategoriesProto({ apiKey: ctx?.apiKey ?? "" })
    );
  };

  const handleGenerateRemoteControlButtons = () => {
    let buttons: PpicRemoteControlPreset[] = [];

    if (remoteControlGenerationSettings.generationType === "Dept/Skip Date") {
      buttons = [...Array(remoteControlGenerationSettings.skipDate)].map(
        (_, i) => {
          return PpicRemoteControlPreset.fromPartial({
            masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
              uuid: v4(),
            }),
            name: remoteControlGenerationSettings.name,
            jobId: remoteControlGenerationSettings.job?.masterJavaBaseModel?.id
              ? `${remoteControlGenerationSettings.job?.masterJavaBaseModel?.id}`
              : undefined,
            skipDate: i,
            workOrderType: remoteControlGenerationSettings.tableType,
            screen: remoteControlGenerationSettings.screen,
            picOnly: true,
            extDepartmentId: remoteControlGenerationSettings.department?.id
              ? `${remoteControlGenerationSettings.department?.id}`
              : undefined,
          });
        }
      );
    } else if (
      remoteControlGenerationSettings.generationType === "Dept Users"
    ) {
      buttons =
        ctx?.extUsers
          .filter(
            (u) =>
              `${u.departmentId}` ===
              `${remoteControlGenerationSettings.department?.id}`
          )
          .map((u) => {
            return PpicRemoteControlPreset.fromPartial({
              masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                uuid: v4(),
              }),
              name: remoteControlGenerationSettings.name,
              jobId: remoteControlGenerationSettings.job?.masterJavaBaseModel
                ?.id
                ? `${remoteControlGenerationSettings.job?.masterJavaBaseModel?.id}`
                : undefined,
              workOrderType: remoteControlGenerationSettings.tableType,
              screen: remoteControlGenerationSettings.screen,
              picOnly: true,
              extUserId: u.id ? `${u.id}` : undefined,
            });
          }) ?? [];
    } else if (
      remoteControlGenerationSettings.generationType === "1 Month Progress"
    ) {
      buttons = [
        "Prod 1 Week",
        "Prod 2 Week",
        "Prod 3 Week",
        "Prod 4 Week",
      ].map((w) => {
        return PpicRemoteControlPreset.fromPartial({
          masterJavaBaseModel: MasterJavaBaseModel.fromPartial({ uuid: v4() }),
          name: remoteControlGenerationSettings.name,
          jobId: remoteControlGenerationSettings.job?.masterJavaBaseModel?.id
            ? `${remoteControlGenerationSettings.job?.masterJavaBaseModel?.id}`
            : undefined,
          workOrderType: remoteControlGenerationSettings.tableType,
          screen: w,
          picOnly: true,
          extDepartmentId: remoteControlGenerationSettings.department?.id
            ? `${remoteControlGenerationSettings.department?.id}`
            : undefined,
        });
      });
    }

    setRemoteControlPresetGeneratorResult({
      ...remoteControlPresetGeneratorResult,
      presets: buttons,
    });
  };

  useEffect(() => {
    if (ctx?.apiKey) {
      console.log("User id:", selectedUser?.id);
      console.log("User topic:", `remote/${selectedUser?.id}`);

      // Create a client instance

      const client = new PahoMQTT.Client(
        `${process.env.REACT_APP_FRONTEND_MQTT_WS_URL}/mqtt`,
        `client-${selectedUser?.id}-${new Date().getTime()}`
      );

      // set callback handlers
      client.onConnectionLost = (responseObject) => {
        if (responseObject.errorCode !== 0) {
          console.log("onConnectionLost:" + responseObject.errorMessage);

          client.connect({
            onSuccess: () => {
              console.log("onConnect");
              console.log("Subscribe", `remote/#`);
              client.subscribe(`remote/#`);
            },
          });
        }
      };
      client.onMessageArrived = (message) => {
        console.log("onMessageArrived:" + message.payloadString);
        setMessageHistory((h) => [
          {
            msg: message.payloadString,
            timestamp: Intl.DateTimeFormat("en-US", {
              dateStyle: "long",
              timeStyle: "long",
              hourCycle: "h23",
            } as any).format(new Date().getTime()),
            topic: message.destinationName,
          },
          ...h,
        ]);
      };

      client.connect({
        onSuccess: () => {
          console.log("onConnect");
          console.log("Subscribe", `remote/#`);
          client.subscribe(`remote/#`);
        },
      });
    }
  }, []);

  return (
    <>
      <div>
        <div>
          <h4>Remote Control</h4>
        </div>
        <hr className="border border-dark" />
        <div className="d-flex">
          <button
            className={`btn btn-sm ${
              tab === "Manual" ? `btn-primary` : `btn-outline-primary`
            }`}
            onClick={() => {
              setTab("Manual");
            }}
          >
            Manual
          </button>
          <button
            className={`btn btn-sm ${
              tab === "Multitable" ? `btn-primary` : `btn-outline-primary`
            }`}
            onClick={() => {
              setTab("Multitable");
            }}
          >
            Multitable
          </button>
          <button
            className={`btn btn-sm ${
              tab === "Template" ? `btn-primary` : `btn-outline-primary`
            }`}
            onClick={() => {
              setTab("Template");
            }}
          >
            Template
          </button>
          <button
            className={`btn btn-sm ${
              tab === "Macro" ? `btn-primary` : `btn-outline-primary`
            }`}
            onClick={() => {
              setTab("Macro");
            }}
          >
            Macro
          </button>
          <button
            className={`btn btn-sm ${
              tab === "Macro-Category" ? `btn-primary` : `btn-outline-primary`
            }`}
            onClick={() => {
              setTab("Macro-Category");
            }}
          >
            Macro-Category
          </button>
          <button
            className={`btn btn-sm ${
              tab === "Generator" ? `btn-primary` : `btn-outline-primary`
            }`}
            onClick={() => {
              setTab("Generator");
            }}
          >
            Generator
          </button>
        </div>

        <div className="flex-grow-1">
          <input
            className="form-control form-control-sm"
            placeholder="Filter preset..."
            defaultValue={`untitled`}
            id="preset-search-input"
            onBlur={(e) => {
              setCatalogPresetSearch(e.target.value);
            }}
          />
        </div>

        <hr className="border border-dark" />

        {tab === "Manual" ? (
          <>
            <div className="d-flex flex-wrap">
              <div className="flex-grow-1">
                <div>
                  <Select
                    options={users}
                    getOptionLabel={(u) => `${u.username}: ${u.name}`}
                    onChange={(v) => {
                      const val = v as unknown as User;
                      setSelectedUser(val);
                      setSelectedUsers([...selectedUsers, val]);
                    }}
                    placeholder="User..."
                  />
                </div>

                <div className="d-flex flex-wrap">
                  {selectedUsers.map((u) => {
                    return (
                      <>
                        <div className="border border-dark rounded rounded-lg p-1">
                          <div>
                            <div style={{ cursor: "pointer" }}>
                              {u.username}: {u.name}
                            </div>
                          </div>
                          <div className="d-flex">
                            <button
                              className="btn btn-sm btn-success"
                              onClick={() => {
                                const client = new PahoMQTT.Client(
                                  `${process.env.REACT_APP_FRONTEND_MQTT_WS_URL}/mqtt`,

                                  `client-${u.id}-${new Date().getTime()}`
                                );
                                client.connect({
                                  onSuccess: () => {
                                    const message = new PahoMQTT.Message(
                                      JSON.stringify(
                                        PpicRemoteControlPayload.fromPartial({
                                          screen: u.id ? `${u.id}` : undefined,
                                          refresh: true,
                                        })
                                      )
                                    );
                                    message.destinationName = `remote/${u.id}`;
                                    client.send(message);
                                  },
                                });
                              }}
                            >
                              <Refresh />
                            </button>
                            <button
                              className="btn btn-sm btn-danger ml-2"
                              onClick={() => {
                                setSelectedUsers(
                                  selectedUsers.filter(
                                    (ux) => `${ux.id}` !== `${u.id}`
                                  )
                                );
                              }}
                            >
                              <Delete />
                            </button>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>

              {selectedUser ? (
                <>
                  <div className="flex-grow-1">
                    <Select
                      options={ctx?.extDepartments ?? []}
                      placeholder="Select Dept..."
                      getOptionLabel={(d) => d.name ?? ""}
                      onChange={(v) => {
                        const val = v as unknown as ExtDepartment;
                        setDeptId(val.id);
                        setUserId(null);
                      }}
                    />
                  </div>
                  <div className="flex-grow-1">
                    <Select
                      options={ctx?.extUsers ?? []}
                      placeholder="Select User..."
                      getOptionLabel={(d) => `${d.username}: ${d.name}`}
                      onChange={(v) => {
                        const val = v as unknown as ExtUser;
                        setUserId(val.id);
                        setDeptId(null);
                      }}
                    />
                  </div>
                  <div className="flex-grow-1">
                    <Select
                      options={jobsProto.jobs ?? []}
                      placeholder="Select job..."
                      getOptionLabel={(d) => d.name ?? ""}
                      onChange={(v) => {
                        const val = v as unknown as PpicJob;
                        setJobId(val.masterJavaBaseModel?.id);
                      }}
                    />
                  </div>
                  <div className="flex-grow-1">
                    <Select
                      options={woTypesData}
                      placeholder="Table type..."
                      onChange={(v) => {
                        const val = v as unknown as {
                          label: string;
                          value: WorkOrderType;
                        };

                        setWoScreenType(val.value);
                      }}
                    />
                  </div>
                  <div>
                    <input
                      className="form-control"
                      placeholder="Skip date..."
                      style={{ width: 75 }}
                      type="number"
                      onBlur={(e) => {
                        if (!isNaN(parseInt(e.target.value))) {
                          setSkipDate(parseInt(e.target.value));
                        }
                      }}
                    />
                  </div>

                  <div className="flex-grow-1">
                    <Select
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 10 }),
                      }}
                      options={
                        [
                          { label: "PIC", value: true },
                          { label: "SPV", value: false },
                          { label: "Both", value: null },
                        ] as { label: string; value: boolean }[]
                      }
                      placeholder="User type..."
                      onChange={(v) => {
                        const val = v as unknown as {
                          label: string;
                          value: boolean | null;
                        };
                        setPicOnly(val.value);
                      }}
                    />
                  </div>

                  <div className="flex-grow-1">
                    <Select
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 10 }),
                      }}
                      options={jdpPresets.presets.map((p) => ({
                        label: p.name ?? "No name",
                        value: p,
                      }))}
                      placeholder="JDP Preset..."
                      onChange={(v) => {
                        const val = v as {
                          value: PpicPanelCodeDepartmentTemplatePreset;
                        };

                        setJdpPreset(val.value);
                      }}
                    />
                  </div>

                  <div className="flex-grow-1">
                    <Select
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 10 }),
                      }}
                      options={problemCatalogs.catalogs.map((c) => ({
                        label: getProblemCatalogFullName(
                          ctx?.extDepartments ?? [],
                          c
                        ),
                        value: c,
                      }))}
                      placeholder="Problem... "
                      onChange={(v) => {
                        const val = v as { value: MeetingProblemCatalog };
                        setSelectedRemoteProblemCatalog(val.value);
                      }}
                    />
                    {selectedRemoteProblemCatalog ? (
                      <>
                        <small>
                          {getProblemCatalogFullName(
                            ctx?.extDepartments ?? [],
                            selectedRemoteProblemCatalog
                          )}
                        </small>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="flex-grow-1">
                    <Select
                      options={pageTopics.map((l) => ({ label: l, value: l }))}
                      placeholder="Range..."
                      getOptionLabel={(l) => l.label}
                      onChange={(v) => {
                        const val = v as unknown as {
                          label: RemoteControlPageTopic;
                          value: RemoteControlPageTopic;
                        };

                        selectedUsers.forEach((selectedUser) => {
                          const client = new PahoMQTT.Client(
                            `${process.env.REACT_APP_FRONTEND_MQTT_WS_URL}/mqtt`,

                            `client-${selectedUser.id}-${new Date().getTime()}`
                          );
                          client.connect({
                            onSuccess: () => {
                              const message = new PahoMQTT.Message(
                                JSON.stringify(
                                  PpicRemoteControlPayload.fromPartial({
                                    screen: val.value,
                                    workOrderType: woScreenType,
                                    jobId: jobId,
                                    extUserId: userId ? `${userId}` : undefined,
                                    deptId: deptId ? `${deptId}` : undefined,
                                    skipDate: skipDate,
                                    scheduled: scheduled,
                                    picOnly: picOnly ?? undefined,
                                    jdpPresetId:
                                      jdpPreset.masterJavaBaseModel?.id ??
                                      undefined,
                                    extProblemCatalogId:
                                      selectedRemoteProblemCatalog
                                        ?.masterJavaBaseModel?.id ?? undefined,
                                  })
                                )
                              );
                              message.destinationName = `remote/${selectedUser.id}`;
                              client.send(message);
                            },
                          });
                        });
                      }}
                    />
                  </div>

                  <div className="flex-grow-1">
                    <div
                      onClick={() => {
                        setFilterTaskDateOnly(!filterTaskDateOnly);
                      }}
                    >
                      <div>Filter task date?</div>
                      <div>
                        <input type="checkbox" checked={filterTaskDateOnly} />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <div
              className="overflow-auto border border-dark shadow shadow-md"
              style={{ resize: "vertical", height: "50vh" }}
            >
              <small>
                <pre className="font-weight-bold">
                  {JSON.stringify(messageHistory, null, 2)}
                </pre>
              </small>
            </div>
          </>
        ) : (
          <></>
        )}

        {tab === "Multitable" ? (
          <>
            <div>
              <div>Multitable</div>
              <hr className="border border-dark" />
              <div>
                <div>
                  <button
                    className="btn btn-primary btn-sm  mx-3 "
                    onClick={async () => {
                      try {
                        console.log("click");
                        const resp = await fetch(
                          `${process.env.REACT_APP_BASE_URL}/remotecontrolpresets-save-bulk`,
                          {
                            method: "post",
                            headers: {
                              "content-type": "application/json",
                              authorization: ctx?.apiKey ?? "",
                            },
                            body: JSON.stringify(
                              PpicRemoteControlPresets.toJSON({
                                ...remoteControlPresets,
                                presets: remoteControlPresets.presets.filter(
                                  (p) => {
                                    const presetFormer =
                                      remoteControlPresetsFormer.presets.find(
                                        (px) =>
                                          px.masterJavaBaseModel?.uuid ===
                                          p.masterJavaBaseModel?.uuid
                                      );

                                    return presetFormer
                                      ? murmur.murmur3(
                                          JSON.stringify(presetFormer)
                                        ) !== murmur.murmur3(JSON.stringify(p))
                                      : true;
                                  }
                                ),
                              })
                            ),
                          }
                        );

                        // Delete ids
                        await Promise.all([
                          ...remoteControlPresetDeleteIds.map(async (id) => {
                            try {
                              const respDelete = await fetch(
                                `${process.env.REACT_APP_BASE_URL}/remotecontrolpresets/${id}/empty`,
                                {
                                  method: "delete",
                                  headers: {
                                    authorization: ctx?.apiKey ?? "",
                                  },
                                }
                              );
                            } catch (e) {}
                          }),
                        ]);

                        window.location.reload();
                      } catch (e) {
                        console.error(e);
                      }
                    }}
                  >
                    Save
                  </button>
                </div>
                <div>
                  <input
                    className="form-control form-control-sm"
                    placeholder="Filter multi table..."
                  />
                </div>
              </div>
              <div
                className="border border-dark shadow shadow-md overflow-auto"
                style={{ height: "65vh", resize: "vertical" }}
              >
                <table
                  className="table table-sm"
                  style={{ borderCollapse: "separate" }}
                >
                  <tr>
                    {[
                      "Action",
                      "#",
                      "Dept",
                      "User",
                      "Job",
                      "Table Type",
                      "Skip Date",
                      "Range",
                      "User Type",
                      "JDP Preset",
                      "Prob",
                      "Refresh?",
                      "WO Graph?",
                      "Code",
                      "Filter DateOnly",
                    ].map((h, i) => {
                      return (
                        <>
                          <th className="bg-dark text-light">{h}</th>
                        </>
                      );
                    })}
                  </tr>

                  {remoteControlPresets.presets
                    .filter((p) =>
                      catalogPresetSearch && catalogPresetSearch !== ""
                        ? p.name
                            ?.toLowerCase()
                            .includes(catalogPresetSearch.toLowerCase())
                        : true
                    )
                    .map((p, i) => {
                      return (
                        <>
                          <tr className="border border-dark">
                            <td className="border border-dark"></td>

                            <td className="border border-dark">{i + 1}</td>
                            <td className="border border-dark" colSpan={11}>
                              <div className="d-flex">
                                <input
                                  className="form-control form-control-sm"
                                  defaultValue={p.name}
                                  style={{ width: 200 }}
                                  key={`inp-name-${p.masterJavaBaseModel?.uuid}`}
                                  onBlur={(e) => {
                                    setRemoteControlPresets({
                                      ...remoteControlPresets,
                                      presets: remoteControlPresets.presets.map(
                                        (px, ix) =>
                                          p.masterJavaBaseModel?.uuid ===
                                          px.masterJavaBaseModel?.uuid
                                            ? {
                                                ...px,
                                                name: e.target.value,
                                              }
                                            : px
                                      ),
                                    });
                                  }}
                                />
                                <div style={{ minWidth: 200 }}>
                                  <Select
                                    placeholder="Monitor..."
                                    key={`inp-user=${p.masterJavaBaseModel?.uuid}`}
                                    options={[
                                      { id: 0, name: "None" } as User,
                                      ...users,
                                    ]}
                                    getOptionLabel={(u) =>
                                      `${u.username}: ${u.name}`
                                    }
                                    value={users.find(
                                      (u) => `${u.id}` === `${p.userId}`
                                    )}
                                    onChange={(v) => {
                                      const val = v as unknown as User;

                                      setRemoteControlPresets({
                                        ...remoteControlPresets,
                                        presets:
                                          remoteControlPresets.presets.map(
                                            (px, ix) =>
                                              p.masterJavaBaseModel?.uuid ===
                                              px.masterJavaBaseModel?.uuid
                                                ? {
                                                    ...px,
                                                    userId:
                                                      val.id && val.id !== 0
                                                        ? `${val.id}`
                                                        : undefined,
                                                  }
                                                : px
                                          ),
                                      });
                                    }}
                                  />
                                  {
                                    users.find(
                                      (u) => `${u.id}` === `${p.userId}`
                                    )?.username
                                  }
                                </div>
                                <div className="d-flex mx-3">
                                  <div>
                                    Quad screen?{" "}
                                    <input
                                      type="checkbox"
                                      checked={p.quadScreen ? true : false}
                                      onClick={() => {
                                        setRemoteControlPresets({
                                          ...remoteControlPresets,
                                          presets:
                                            remoteControlPresets.presets.map(
                                              (px, ix) =>
                                                p.masterJavaBaseModel?.uuid ===
                                                px.masterJavaBaseModel?.uuid
                                                  ? {
                                                      ...px,
                                                      quadScreen:
                                                        !px.quadScreen,
                                                    }
                                                  : px
                                            ),
                                        });
                                      }}
                                    />
                                  </div>
                                  <div>
                                    Quad screen V2?{" "}
                                    <input
                                      type="checkbox"
                                      checked={p.quadScreenV2 ? true : false}
                                      onClick={() => {
                                        setRemoteControlPresets({
                                          ...remoteControlPresets,
                                          presets:
                                            remoteControlPresets.presets.map(
                                              (px, ix) =>
                                                p.masterJavaBaseModel?.uuid ===
                                                px.masterJavaBaseModel?.uuid
                                                  ? {
                                                      ...px,
                                                      quadScreenV2:
                                                        !px.quadScreenV2,
                                                    }
                                                  : px
                                            ),
                                        });
                                      }}
                                    />
                                  </div>
                                  {/* <div>
                                    WO Graph?{" "}
                                    <input
                                      type="checkbox"
                                      checked={p.woGraph ? true : false}
                                      onClick={() => {
                                        setRemoteControlPresets({
                                          ...remoteControlPresets,
                                          presets:
                                            remoteControlPresets.presets.map(
                                              (px, ix) =>
                                                p.masterJavaBaseModel?.uuid ===
                                                px.masterJavaBaseModel?.uuid
                                                  ? {
                                                      ...px,
                                                      quadScreenV2: undefined,
                                                      woGraph: true,
                                                    }
                                                  : px
                                            ),
                                        });
                                      }}
                                    />
                                  </div> */}
                                </div>

                                <button
                                  className="btn btn-sm btn-primary"
                                  onClick={() => {
                                    setRemoteControlPresets({
                                      ...remoteControlPresets,
                                      presets: remoteControlPresets.presets.map(
                                        (px, ix) =>
                                          p.masterJavaBaseModel?.uuid ===
                                          px.masterJavaBaseModel?.uuid
                                            ? {
                                                ...px,
                                                presetRecursive: [
                                                  ...px.presetRecursive,
                                                  PpicRemoteControlPreset.fromPartial(
                                                    {
                                                      name: "untitled",
                                                      masterJavaBaseModel:
                                                        MasterJavaBaseModel.fromPartial(
                                                          { uuid: v4() }
                                                        ),
                                                    }
                                                  ),
                                                ],
                                              }
                                            : px
                                      ),
                                    });
                                  }}
                                >
                                  <Add /> Add
                                </button>
                              </div>
                            </td>
                          </tr>
                          {p.presetRecursive.map((pr, j) => {
                            return (
                              <>
                                <tr>
                                  {" "}
                                  <td className="border border-dark">
                                    <button
                                      className="btn btn-sm btn-danger"
                                      onClick={() => {
                                        setRemoteControlPresetDeleteIds([
                                          ...remoteControlPresetDeleteIds,
                                          pr.masterJavaBaseModel?.id ?? "",
                                        ]);
                                        setRemoteControlPresets({
                                          ...remoteControlPresets,
                                          presets:
                                            remoteControlPresets.presets.map(
                                              (px, ix) =>
                                                p.masterJavaBaseModel?.uuid ===
                                                px.masterJavaBaseModel?.uuid
                                                  ? {
                                                      ...px,
                                                      presetRecursive:
                                                        px.presetRecursive.filter(
                                                          (prx, jx) =>
                                                            prx
                                                              .masterJavaBaseModel
                                                              ?.uuid !==
                                                            pr
                                                              .masterJavaBaseModel
                                                              ?.uuid
                                                        ),
                                                    }
                                                  : px
                                            ),
                                        });
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </td>
                                  <td className="border border-dark">
                                    {i + 1}.{j + 1}
                                  </td>
                                  <td className="border border-dark">
                                    <Select
                                      key={`inp-dept=${p.masterJavaBaseModel?.uuid}`}
                                      options={[
                                        {
                                          id: null,
                                          name: "None",
                                        } as ExtDepartment,
                                        ...(ctx?.extDepartments ?? []),
                                      ]}
                                      getOptionLabel={(d) => `${d.name}`}
                                      value={ctx?.extDepartments.find(
                                        (d) =>
                                          `${d.id}` === `${pr.extDepartmentId}`
                                      )}
                                      onChange={(v) => {
                                        const val =
                                          v as unknown as ExtDepartment;

                                        setRemoteControlPresets({
                                          ...remoteControlPresets,
                                          presets:
                                            remoteControlPresets.presets.map(
                                              (px, ix) =>
                                                p.masterJavaBaseModel?.uuid ===
                                                px.masterJavaBaseModel?.uuid
                                                  ? {
                                                      ...px,
                                                      presetRecursive:
                                                        px.presetRecursive.map(
                                                          (prx, jx) =>
                                                            pr
                                                              .masterJavaBaseModel
                                                              ?.uuid ===
                                                            prx
                                                              .masterJavaBaseModel
                                                              ?.uuid
                                                              ? {
                                                                  ...prx,
                                                                  extDepartmentId:
                                                                    val.id
                                                                      ? `${val.id}`
                                                                      : undefined,
                                                                }
                                                              : prx
                                                        ),
                                                    }
                                                  : px
                                            ),
                                        });
                                      }}
                                    />
                                    <small>
                                      {
                                        ctx?.extDepartments.find(
                                          (d) =>
                                            `${d.id}` ===
                                            `${pr.extDepartmentId}`
                                        )?.name
                                      }
                                    </small>
                                  </td>
                                  <td className="border border-dark">
                                    <Select
                                      options={[
                                        { id: null, name: "None" } as ExtUser,
                                        ...(ctx?.extUsers ?? []),
                                      ]}
                                      getOptionLabel={(u) =>
                                        `${u.username}: ${u.name}`
                                      }
                                      value={ctx?.extUsers.find(
                                        (d) => `${d.id}` === `${pr.extUserId}`
                                      )}
                                      onChange={(v) => {
                                        const val = v as unknown as ExtUser;

                                        setRemoteControlPresets({
                                          ...remoteControlPresets,
                                          presets:
                                            remoteControlPresets.presets.map(
                                              (px, ix) =>
                                                p.masterJavaBaseModel?.uuid ===
                                                px.masterJavaBaseModel?.uuid
                                                  ? {
                                                      ...px,
                                                      presetRecursive:
                                                        px.presetRecursive.map(
                                                          (prx, jx) =>
                                                            pr
                                                              .masterJavaBaseModel
                                                              ?.uuid ===
                                                            prx
                                                              .masterJavaBaseModel
                                                              ?.uuid
                                                              ? {
                                                                  ...prx,
                                                                  extUserId:
                                                                    val.id
                                                                      ? `${val.id}`
                                                                      : undefined,
                                                                }
                                                              : prx
                                                        ),
                                                    }
                                                  : px
                                            ),
                                        });
                                      }}
                                    />
                                  </td>
                                  <td className="border border-dark">
                                    <Select
                                      options={[
                                        { name: "None" } as PpicJob,
                                        ...(jobsProto.jobs ?? []),
                                      ]}
                                      getOptionLabel={(j) => `${j.name}`}
                                      value={jobsProto.jobs.find(
                                        (j) =>
                                          `${j.masterJavaBaseModel?.id}` ===
                                          `${pr.jobId}`
                                      )}
                                      onChange={(v) => {
                                        const val = v as unknown as PpicJob;

                                        setRemoteControlPresets({
                                          ...remoteControlPresets,
                                          presets:
                                            remoteControlPresets.presets.map(
                                              (px, ix) =>
                                                p.masterJavaBaseModel?.uuid ===
                                                px.masterJavaBaseModel?.uuid
                                                  ? {
                                                      ...px,
                                                      presetRecursive:
                                                        px.presetRecursive.map(
                                                          (prx, jx) =>
                                                            pr
                                                              .masterJavaBaseModel
                                                              ?.uuid ===
                                                            prx
                                                              .masterJavaBaseModel
                                                              ?.uuid
                                                              ? {
                                                                  ...prx,
                                                                  jobId: val
                                                                    .masterJavaBaseModel
                                                                    ?.id
                                                                    ? `${val.masterJavaBaseModel.id}`
                                                                    : undefined,
                                                                }
                                                              : prx
                                                        ),
                                                    }
                                                  : px
                                            ),
                                        });
                                      }}
                                    />
                                  </td>
                                  <td className="border border-dark">
                                    <Select
                                      options={woTypesData}
                                      placeholder="Table type..."
                                      value={
                                        pr.workOrderType
                                          ? woTypesData.find(
                                              (d) =>
                                                workOrderTypeToJSON(d.value) ===
                                                workOrderTypeToJSON(
                                                  pr.workOrderType!
                                                )
                                            )
                                          : undefined
                                      }
                                      onChange={(v) => {
                                        const val = v as unknown as {
                                          label: string;
                                          value: WorkOrderType;
                                        };

                                        setRemoteControlPresets({
                                          ...remoteControlPresets,
                                          presets:
                                            remoteControlPresets.presets.map(
                                              (px, ix) =>
                                                p.masterJavaBaseModel?.uuid ===
                                                px.masterJavaBaseModel?.uuid
                                                  ? {
                                                      ...px,
                                                      presetRecursive:
                                                        px.presetRecursive.map(
                                                          (prx, jx) =>
                                                            pr
                                                              .masterJavaBaseModel
                                                              ?.uuid ===
                                                            prx
                                                              .masterJavaBaseModel
                                                              ?.uuid
                                                              ? {
                                                                  ...prx,
                                                                  workOrderType:
                                                                    val.value,
                                                                }
                                                              : prx
                                                        ),
                                                    }
                                                  : px
                                            ),
                                        });
                                      }}
                                    />
                                    <div>
                                      <small>
                                        <small>
                                          {pr.workOrderType ? (
                                            <>
                                              {workOrderTypeToJSON(
                                                workOrderTypeFromJSON(
                                                  pr.workOrderType
                                                )
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </small>
                                      </small>
                                    </div>
                                  </td>
                                  <td className="border border-dark">
                                    <input
                                      className="form-control"
                                      placeholder="Skip date..."
                                      style={{ width: 75 }}
                                      type="number"
                                      defaultValue={p.skipDate}
                                      key={`inp-skipdate-${p.masterJavaBaseModel?.uuid}`}
                                      onChange={(e) => {
                                        setRemoteControlPresets({
                                          ...remoteControlPresets,
                                          presets:
                                            remoteControlPresets.presets.map(
                                              (px, ix) =>
                                                p.masterJavaBaseModel?.uuid ===
                                                px.masterJavaBaseModel?.uuid
                                                  ? {
                                                      ...px,
                                                      presetRecursive:
                                                        px.presetRecursive.map(
                                                          (prx, jx) =>
                                                            pr
                                                              .masterJavaBaseModel
                                                              ?.uuid ===
                                                            prx
                                                              .masterJavaBaseModel
                                                              ?.uuid
                                                              ? {
                                                                  ...prx,
                                                                  skipDate:
                                                                    isNaN(
                                                                      parseInt(
                                                                        e.target
                                                                          .value
                                                                      )
                                                                    )
                                                                      ? 0
                                                                      : parseInt(
                                                                          e
                                                                            .target
                                                                            .value
                                                                        ),
                                                                }
                                                              : prx
                                                        ),
                                                    }
                                                  : px
                                            ),
                                        });
                                      }}
                                    />
                                    {pr.skipDate}
                                  </td>
                                  <td className="border border-dark">
                                    <Select
                                      key={`inp-topic=${p.masterJavaBaseModel?.uuid}`}
                                      options={pageTopics.map((t) => ({
                                        label: t,
                                        value: t,
                                      }))}
                                      value={{
                                        label: pr.screen,
                                        value: pr.screen,
                                      }}
                                      onChange={(v) => {
                                        const val = v as unknown as {
                                          label: RemoteControlPageTopic;
                                          value: RemoteControlPageTopic;
                                        };

                                        setRemoteControlPresets({
                                          ...remoteControlPresets,
                                          presets:
                                            remoteControlPresets.presets.map(
                                              (px, ix) =>
                                                p.masterJavaBaseModel?.uuid ===
                                                px.masterJavaBaseModel?.uuid
                                                  ? {
                                                      ...px,
                                                      presetRecursive:
                                                        px.presetRecursive.map(
                                                          (prx, jx) =>
                                                            pr
                                                              .masterJavaBaseModel
                                                              ?.uuid ===
                                                            prx
                                                              .masterJavaBaseModel
                                                              ?.uuid
                                                              ? {
                                                                  ...prx,
                                                                  screen:
                                                                    val.value as string,
                                                                }
                                                              : prx
                                                        ),
                                                    }
                                                  : px
                                            ),
                                        });
                                      }}
                                    />
                                  </td>
                                  <td className="border border-dark">
                                    <Select
                                      styles={{
                                        menu: (provided) => ({
                                          ...provided,
                                          zIndex: 10,
                                        }),
                                      }}
                                      options={picOnlySelect}
                                      placeholder="User type..."
                                      value={picOnlySelect.find(
                                        (s) => s.value === pr.picOnly
                                      )}
                                      onChange={(v) => {
                                        const val = v as unknown as {
                                          label: string;
                                          value: boolean | null;
                                        };

                                        setRemoteControlPresets({
                                          ...remoteControlPresets,
                                          presets:
                                            remoteControlPresets.presets.map(
                                              (px, ix) =>
                                                p.masterJavaBaseModel?.uuid ===
                                                px.masterJavaBaseModel?.uuid
                                                  ? {
                                                      ...px,
                                                      presetRecursive:
                                                        px.presetRecursive.map(
                                                          (prx, jx) =>
                                                            pr
                                                              .masterJavaBaseModel
                                                              ?.uuid ===
                                                            prx
                                                              .masterJavaBaseModel
                                                              ?.uuid
                                                              ? {
                                                                  ...prx,
                                                                  picOnly:
                                                                    val.value ??
                                                                    undefined,
                                                                }
                                                              : prx
                                                        ),
                                                    }
                                                  : px
                                            ),
                                        });
                                      }}
                                    />
                                    {pr.picOnly}
                                  </td>
                                  <td className="border border-dark">
                                    <div className="flex-grow-1">
                                      <Select
                                        styles={{
                                          menu: (provided) => ({
                                            ...provided,
                                            zIndex: 10,
                                          }),
                                        }}
                                        options={jdpPresets.presets.map(
                                          (p) => ({
                                            label: p.name ?? "No name",
                                            value: p,
                                          })
                                        )}
                                        placeholder="JDP Preset..."
                                        onChange={(v) => {
                                          const val = v as unknown as {
                                            value: PpicPanelCodeDepartmentTemplatePreset;
                                          };

                                          setRemoteControlPresets({
                                            ...remoteControlPresets,
                                            presets:
                                              remoteControlPresets.presets.map(
                                                (px, ix) =>
                                                  p.masterJavaBaseModel
                                                    ?.uuid ===
                                                  px.masterJavaBaseModel?.uuid
                                                    ? {
                                                        ...px,
                                                        presetRecursive:
                                                          px.presetRecursive.map(
                                                            (prx, jx) =>
                                                              pr
                                                                .masterJavaBaseModel
                                                                ?.uuid ===
                                                              prx
                                                                .masterJavaBaseModel
                                                                ?.uuid
                                                                ? {
                                                                    ...prx,
                                                                    jdpPresetId:
                                                                      val.value
                                                                        .masterJavaBaseModel
                                                                        ?.id ??
                                                                      undefined,
                                                                  }
                                                                : prx
                                                          ),
                                                      }
                                                    : px
                                              ),
                                          });
                                        }}
                                      />
                                    </div>
                                    <small>
                                      {
                                        jdpPresets.presets.find(
                                          (px) =>
                                            `${px.masterJavaBaseModel?.id}` ===
                                            `${pr.jdpPresetId}`
                                        )?.name
                                      }
                                    </small>
                                  </td>
                                  <td className="border border-dark">
                                    <div className="flex-grow-1">
                                      <Select
                                        styles={{
                                          menu: (provided) => ({
                                            ...provided,
                                            zIndex: 10,
                                          }),
                                        }}
                                        options={problemCatalogs.catalogs.map(
                                          (p) => ({
                                            label: getProblemCatalogFullName(
                                              ctx?.extDepartments ?? [],
                                              p
                                            ),
                                            value: p,
                                          })
                                        )}
                                        placeholder="Problem..."
                                        onChange={(v) => {
                                          const val = v as unknown as {
                                            value: MeetingMeetingTaskProblemCatalog;
                                          };

                                          setRemoteControlPresets({
                                            ...remoteControlPresets,
                                            presets:
                                              remoteControlPresets.presets.map(
                                                (px, ix) =>
                                                  p.masterJavaBaseModel
                                                    ?.uuid ===
                                                  px.masterJavaBaseModel?.uuid
                                                    ? {
                                                        ...px,
                                                        presetRecursive:
                                                          px.presetRecursive.map(
                                                            (prx, jx) =>
                                                              pr
                                                                .masterJavaBaseModel
                                                                ?.uuid ===
                                                              prx
                                                                .masterJavaBaseModel
                                                                ?.uuid
                                                                ? {
                                                                    ...prx,
                                                                    extProblemCatalogId:
                                                                      val.value
                                                                        .masterJavaBaseModel
                                                                        ?.id,
                                                                  }
                                                                : prx
                                                          ),
                                                      }
                                                    : px
                                              ),
                                          });
                                        }}
                                      />
                                    </div>
                                    <small>
                                      <small>
                                        {pr.extProblemCatalogId ? (
                                          getProblemCatalogFullName(
                                            ctx?.extDepartments ?? [],
                                            problemCatalogs.catalogs.find(
                                              (c) =>
                                                `${c.masterJavaBaseModel?.id}` ===
                                                `${pr.extProblemCatalogId}`
                                            ) ??
                                              MeetingProblemCatalog.fromPartial(
                                                {}
                                              )
                                          )
                                        ) : (
                                          <></>
                                        )}
                                      </small>
                                    </small>
                                  </td>
                                  <td className="border border-dark">
                                    <input
                                      type="checkbox"
                                      checked={pr.refresh}
                                      onClick={() => {
                                        setRemoteControlPresets({
                                          ...remoteControlPresets,
                                          presets:
                                            remoteControlPresets.presets.map(
                                              (px, ix) =>
                                                p.masterJavaBaseModel?.uuid ===
                                                px.masterJavaBaseModel?.uuid
                                                  ? {
                                                      ...px,
                                                      presetRecursive:
                                                        px.presetRecursive.map(
                                                          (prx, jx) =>
                                                            pr
                                                              .masterJavaBaseModel
                                                              ?.uuid ===
                                                            prx
                                                              .masterJavaBaseModel
                                                              ?.uuid
                                                              ? {
                                                                  ...prx,
                                                                  refresh:
                                                                    !prx.refresh,
                                                                }
                                                              : prx
                                                        ),
                                                    }
                                                  : px
                                            ),
                                        });
                                      }}
                                    />
                                  </td>
                                  <td className="border border-dark">
                                    <input
                                      type="checkbox"
                                      checked={pr.woGraph}
                                      onClick={() => {
                                        setRemoteControlPresets({
                                          ...remoteControlPresets,
                                          presets:
                                            remoteControlPresets.presets.map(
                                              (px, ix) =>
                                                p.masterJavaBaseModel?.uuid ===
                                                px.masterJavaBaseModel?.uuid
                                                  ? {
                                                      ...px,
                                                      presetRecursive:
                                                        px.presetRecursive.map(
                                                          (prx, jx) =>
                                                            pr
                                                              .masterJavaBaseModel
                                                              ?.uuid ===
                                                            prx
                                                              .masterJavaBaseModel
                                                              ?.uuid
                                                              ? {
                                                                  ...prx,
                                                                  woGraph:
                                                                    !prx.woGraph,
                                                                }
                                                              : prx
                                                        ),
                                                    }
                                                  : px
                                            ),
                                        });
                                      }}
                                    />
                                  </td>
                                  <td className="border border-dark">
                                    X.
                                    {ctx?.extDepartments.find(
                                      (d) =>
                                        `${d.id}` === `${pr.extDepartmentId}`
                                    )?.code ?? "00"}
                                    .Z.AA
                                  </td>
                                  <td className="border border-dark">
                                    <input
                                      type="checkbox"
                                      checked={pr.filterTaskDateOnly}
                                      onClick={() => {
                                        setRemoteControlPresets({
                                          ...remoteControlPresets,
                                          presets:
                                            remoteControlPresets.presets.map(
                                              (px, ix) =>
                                                p.masterJavaBaseModel?.uuid ===
                                                px.masterJavaBaseModel?.uuid
                                                  ? {
                                                      ...px,
                                                      presetRecursive:
                                                        px.presetRecursive.map(
                                                          (prx, jx) =>
                                                            pr
                                                              .masterJavaBaseModel
                                                              ?.uuid ===
                                                            prx
                                                              .masterJavaBaseModel
                                                              ?.uuid
                                                              ? {
                                                                  ...prx,
                                                                  filterTaskDateOnly:
                                                                    !prx.filterTaskDateOnly,
                                                                }
                                                              : prx
                                                        ),
                                                    }
                                                  : px
                                            ),
                                        });
                                      }}
                                    />
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </>
                      );
                    })}
                </table>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        {tab === "Template" ? (
          <>
            <div>Template Catalog</div>
            <div className="d-flex">
              <div>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    console.log(remoteControlPresets.presets);
                    setRemoteControlPresets({
                      ...remoteControlPresets,
                      presets: remoteControlPresets.presets
                        ? [
                            ...remoteControlPresets.presets,
                            PpicRemoteControlPreset.fromPartial({
                              name: "untitled",
                              masterJavaBaseModel:
                                MasterJavaBaseModel.fromPartial({
                                  uuid: v4(),
                                }),
                            }),
                          ]
                        : [
                            PpicRemoteControlPreset.fromPartial({
                              name: "untitled",
                              masterJavaBaseModel:
                                MasterJavaBaseModel.fromPartial({
                                  uuid: v4(),
                                }),
                            }),
                          ],
                    });
                  }}
                >
                  <Add /> Add
                </button>
              </div>
              <div>
                <button
                  className="btn btn-primary btn-sm  mx-3 "
                  onClick={async () => {
                    try {
                      const resp = await fetch(
                        `${process.env.REACT_APP_BASE_URL}/remotecontrolpresets-save-bulk`,
                        {
                          method: "post",
                          headers: {
                            "content-type": "application/json",
                            authorization: ctx?.apiKey ?? "",
                          },
                          body: JSON.stringify(
                            PpicRemoteControlPresets.toJSON({
                              ...remoteControlPresets,
                              presets: remoteControlPresets.presets.filter(
                                (p) => {
                                  const presetFormer =
                                    remoteControlPresetsFormer.presets.find(
                                      (px) =>
                                        px.masterJavaBaseModel?.uuid ===
                                        p.masterJavaBaseModel?.uuid
                                    );

                                  return presetFormer
                                    ? murmur.murmur3(
                                        JSON.stringify(presetFormer)
                                      ) !== murmur.murmur3(JSON.stringify(p))
                                    : true;
                                }
                              ),
                            })
                          ),
                        }
                      );

                      // Delete ids
                      await Promise.all([
                        ...remoteControlPresetDeleteIds.map(async (id) => {
                          try {
                            const respDelete = await fetch(
                              `${process.env.REACT_APP_BASE_URL}/remotecontrolpresets/${id}/empty`,
                              {
                                method: "delete",
                                headers: {
                                  authorization: ctx?.apiKey ?? "",
                                },
                              }
                            );
                          } catch (e) {}
                        }),
                      ]);

                      window.location.reload();
                    } catch (e) {
                      console.error(e);
                    }
                  }}
                >
                  Save
                </button>
              </div>
            </div>

            <div
              style={{ resize: "vertical", height: "60vh" }}
              className="overflow-auto border border-dark shadow shadow-md"
            >
              <table
                className="table table-sm"
                style={{ borderCollapse: "separate" }}
              >
                <tr>
                  {[
                    "Action",
                    "#",
                    "Preset Name",
                    "Dept",
                    "User",
                    "Job",
                    "Monitor",
                    "Table Type",
                    "Skip Date",
                    "Range",
                    "User Type",
                    "JDP Preset",
                    "Refresh?",
                    "Code",
                    "Filter DateOnly",
                  ].map((h) => (
                    <th
                      className="bg-dark text-light"
                      style={{ position: "sticky", top: 0 }}
                    >
                      {h}
                    </th>
                  ))}
                </tr>
                {remoteControlPresets.presets
                  .map((p, i) => {
                    return {
                      index: i,
                      data: p,
                      layout: (
                        <>
                          <tr>
                            <td className="border border-dark">
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={() => {
                                  setRemoteControlPresetDeleteIds([
                                    ...remoteControlPresetDeleteIds,
                                    p.masterJavaBaseModel?.id ?? "",
                                  ]);
                                  setRemoteControlPresets({
                                    ...remoteControlPresets,
                                    presets:
                                      remoteControlPresets.presets.filter(
                                        (_, ix) => ix !== i
                                      ),
                                  });
                                }}
                              >
                                <Delete />
                              </button>
                            </td>
                            <td className="border border-dark">{i + 1}</td>
                            <td className="border border-dark">
                              <div>
                                <input
                                  className="form-control form-control-sm"
                                  placeholder="Name..."
                                  key={`inp-name=${p.masterJavaBaseModel?.uuid}`}
                                  defaultValue={p.name}
                                  onBlur={(e) => {
                                    setRemoteControlPresets({
                                      ...remoteControlPresets,
                                      presets: remoteControlPresets.presets.map(
                                        (px, ix) =>
                                          p.masterJavaBaseModel?.uuid ===
                                          px.masterJavaBaseModel?.uuid
                                            ? { ...px, name: e.target.value }
                                            : px
                                      ),
                                    });
                                  }}
                                />
                              </div>
                              <div>
                                <button
                                  className="btn btn-sm btn-outline-primary"
                                  onClick={() => {
                                    setCatalogPresetSearch(p.name ?? "");
                                    (
                                      document.getElementById(
                                        "preset-search-input"
                                      ) as any
                                    ).value = p.name;
                                  }}
                                >
                                  Select
                                </button>
                              </div>

                              {/* {p.name} */}
                            </td>
                            <td className="border border-dark">
                              <Select
                                key={`inp-dept=${p.masterJavaBaseModel?.uuid}`}
                                options={[
                                  { id: null, name: "None" } as ExtDepartment,
                                  ...(ctx?.extDepartments ?? []),
                                ]}
                                getOptionLabel={(d) => `${d.name}`}
                                value={ctx?.extDepartments.find(
                                  (d) => `${d.id}` === `${p.extDepartmentId}`
                                )}
                                onChange={(v) => {
                                  const val = v as unknown as ExtDepartment;

                                  setRemoteControlPresets({
                                    ...remoteControlPresets,
                                    presets: remoteControlPresets.presets.map(
                                      (px, ix) =>
                                        p.masterJavaBaseModel?.uuid ===
                                        px.masterJavaBaseModel?.uuid
                                          ? {
                                              ...px,
                                              extDepartmentId: val.id
                                                ? `${val.id}`
                                                : undefined,
                                            }
                                          : px
                                    ),
                                  });
                                }}
                              />
                              {
                                ctx?.extDepartments.find(
                                  (d) => `${d.id}` === `${p.extDepartmentId}`
                                )?.name
                              }
                            </td>
                            <td className="border border-dark">
                              <Select
                                options={[
                                  { id: null, name: "None" } as ExtUser,
                                  ...(ctx?.extUsers ?? []),
                                ]}
                                getOptionLabel={(u) =>
                                  `${u.username}: ${u.name}`
                                }
                                value={ctx?.extUsers.find(
                                  (d) => `${d.id}` === `${p.extUserId}`
                                )}
                                onChange={(v) => {
                                  const val = v as unknown as ExtUser;

                                  setRemoteControlPresets({
                                    ...remoteControlPresets,
                                    presets: remoteControlPresets.presets.map(
                                      (px, ix) =>
                                        p.masterJavaBaseModel?.uuid ===
                                        px.masterJavaBaseModel?.uuid
                                          ? {
                                              ...px,
                                              extUserId: val.id
                                                ? `${val.id}`
                                                : undefined,
                                            }
                                          : px
                                    ),
                                  });
                                }}
                              />
                            </td>
                            <td className="border border-dark">
                              <Select
                                options={[
                                  { name: "None" } as PpicJob,
                                  ...(jobsProto.jobs ?? []),
                                ]}
                                getOptionLabel={(j) => `${j.name}`}
                                value={jobsProto.jobs.find(
                                  (j) =>
                                    `${j.masterJavaBaseModel?.id}` ===
                                    `${p.jobId}`
                                )}
                                onChange={(v) => {
                                  const val = v as unknown as PpicJob;

                                  setRemoteControlPresets({
                                    ...remoteControlPresets,
                                    presets: remoteControlPresets.presets.map(
                                      (px, ix) =>
                                        p.masterJavaBaseModel?.uuid ===
                                        px.masterJavaBaseModel?.uuid
                                          ? {
                                              ...px,
                                              jobId: val?.masterJavaBaseModel
                                                ?.id
                                                ? `${val?.masterJavaBaseModel?.id}`
                                                : undefined,
                                            }
                                          : px
                                    ),
                                  });
                                }}
                              />
                            </td>

                            <td className="border border-dark">
                              <Select
                                key={`inp-user=${p.masterJavaBaseModel?.uuid}`}
                                options={[
                                  { id: 0, name: "None" } as User,
                                  ...users,
                                ]}
                                getOptionLabel={(u) =>
                                  `${u.username}: ${u.name}`
                                }
                                value={users.find(
                                  (u) => `${u.id}` === `${p.userId}`
                                )}
                                onChange={(v) => {
                                  const val = v as unknown as User;

                                  setRemoteControlPresets({
                                    ...remoteControlPresets,
                                    presets: remoteControlPresets.presets.map(
                                      (px, ix) =>
                                        p.masterJavaBaseModel?.uuid ===
                                        px.masterJavaBaseModel?.uuid
                                          ? {
                                              ...px,
                                              userId:
                                                val.id && val.id !== 0
                                                  ? `${val.id}`
                                                  : undefined,
                                            }
                                          : px
                                    ),
                                  });
                                }}
                              />
                              {
                                users.find((u) => `${u.id}` === `${p.userId}`)
                                  ?.username
                              }{" "}
                            </td>

                            <td className="border border-dark">
                              <Select
                                options={
                                  woTypesData as {
                                    label: string;
                                    value: WorkOrderType;
                                  }[]
                                }
                                placeholder="Table type..."
                                value={
                                  p.workOrderType
                                    ? woTypesData.find(
                                        (d) =>
                                          workOrderTypeToJSON(d.value) ===
                                          workOrderTypeToJSON(p.workOrderType!)
                                      )
                                    : undefined
                                }
                                onChange={(v) => {
                                  const val = v as unknown as {
                                    label: string;
                                    value: WorkOrderType;
                                  };

                                  setRemoteControlPresets({
                                    ...remoteControlPresets,
                                    presets: remoteControlPresets.presets.map(
                                      (px, ix) =>
                                        p.masterJavaBaseModel?.uuid ===
                                        px.masterJavaBaseModel?.uuid
                                          ? {
                                              ...px,
                                              workOrderType: val.value,
                                            }
                                          : px
                                    ),
                                  });
                                }}
                              />
                            </td>

                            <td className="border border-dark">
                              <input
                                className="form-control"
                                placeholder="Skip date..."
                                style={{ width: 75 }}
                                type="number"
                                defaultValue={p.skipDate}
                                key={`inp-skipdate-${p.masterJavaBaseModel?.uuid}`}
                                onChange={(e) => {
                                  setRemoteControlPresets({
                                    ...remoteControlPresets,
                                    presets: remoteControlPresets.presets.map(
                                      (px, ix) =>
                                        p.masterJavaBaseModel?.uuid ===
                                        px.masterJavaBaseModel?.uuid
                                          ? {
                                              ...px,
                                              skipDate: isNaN(
                                                parseInt(e.target.value)
                                              )
                                                ? 0
                                                : parseInt(e.target.value),
                                            }
                                          : px
                                    ),
                                  });
                                }}
                              />
                            </td>

                            <td className="border border-dark">
                              <Select
                                key={`inp-topic=${p.masterJavaBaseModel?.uuid}`}
                                options={pageTopics.map((t) => ({
                                  label: t,
                                  value: t,
                                }))}
                                value={{ label: p.screen, value: p.screen }}
                                onChange={(v) => {
                                  const val = v as unknown as {
                                    label: RemoteControlPageTopic;
                                    value: RemoteControlPageTopic;
                                  };

                                  setRemoteControlPresets({
                                    ...remoteControlPresets,
                                    presets: remoteControlPresets.presets.map(
                                      (px, ix) =>
                                        p.masterJavaBaseModel?.uuid ===
                                        px.masterJavaBaseModel?.uuid
                                          ? {
                                              ...px,
                                              screen: val.value as string,
                                            }
                                          : px
                                    ),
                                  });
                                }}
                              />
                            </td>
                            <td className="border border-dark">
                              <Select
                                styles={{
                                  menu: (provided) => ({
                                    ...provided,
                                    zIndex: 10,
                                  }),
                                }}
                                options={picOnlySelect}
                                value={picOnlySelect.find(
                                  (s) => s.value === p.picOnly
                                )}
                                placeholder="User type..."
                                onChange={(v) => {
                                  const val = v as unknown as {
                                    label: string;
                                    value: boolean | null;
                                  };

                                  setRemoteControlPresets({
                                    ...remoteControlPresets,
                                    presets: remoteControlPresets.presets.map(
                                      (px, ix) =>
                                        p.masterJavaBaseModel?.uuid ===
                                        px.masterJavaBaseModel?.uuid
                                          ? {
                                              ...px,
                                              picOnly: val.value ?? undefined,
                                            }
                                          : px
                                    ),
                                  });
                                }}
                              />
                              {JSON.stringify(p.picOnly)}
                            </td>
                            <td className="border border-dark">
                              <div className="flex-grow-1">
                                <Select
                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      zIndex: 10,
                                    }),
                                  }}
                                  options={jdpPresets.presets.map((p) => ({
                                    label: p.name ?? "No name",
                                    value: p,
                                  }))}
                                  placeholder="JDP Preset..."
                                  onChange={(v) => {
                                    const val = v as unknown as {
                                      value: PpicPanelCodeDepartmentTemplatePreset;
                                    };

                                    setRemoteControlPresets({
                                      ...remoteControlPresets,
                                      presets: remoteControlPresets.presets.map(
                                        (px, ix) =>
                                          p.masterJavaBaseModel?.uuid ===
                                          px.masterJavaBaseModel?.uuid
                                            ? {
                                                ...px,
                                                jdpPresetId:
                                                  val.value.masterJavaBaseModel
                                                    ?.id ?? undefined,
                                              }
                                            : px
                                      ),
                                    });
                                  }}
                                />
                                <small>
                                  {
                                    jdpPresets.presets.find(
                                      (px) =>
                                        `${px.masterJavaBaseModel?.id}` ===
                                        `${p.jdpPresetId}`
                                    )?.name
                                  }
                                </small>
                              </div>
                            </td>
                            <td className="border border-dark">
                              <input
                                type="checkbox"
                                checked={p.refresh}
                                onClick={() => {
                                  setRemoteControlPresets({
                                    ...remoteControlPresets,
                                    presets: remoteControlPresets.presets.map(
                                      (px, ix) =>
                                        p.masterJavaBaseModel?.uuid ===
                                        px.masterJavaBaseModel?.uuid
                                          ? {
                                              ...px,
                                              refresh: !px.refresh,
                                            }
                                          : px
                                    ),
                                  });
                                }}
                              />
                            </td>

                            <td className="border border-dark">
                              X.
                              {ctx?.extDepartments.find(
                                (d) => `${d.id}` === `${p.extDepartmentId}`
                              )?.code ?? "00"}
                              .Z.AA
                            </td>

                            <td className="border border-dark">
                              <input
                                type="checkbox"
                                checked={p.filterTaskDateOnly}
                                onClick={() => {
                                  setRemoteControlPresets({
                                    ...remoteControlPresets,
                                    presets: remoteControlPresets.presets.map(
                                      (px, ix) =>
                                        p.masterJavaBaseModel?.uuid ===
                                        px.masterJavaBaseModel?.uuid
                                          ? {
                                              ...px,
                                              filterTaskDateOnly:
                                                !px.filterTaskDateOnly,
                                            }
                                          : px
                                    ),
                                  });
                                }}
                              />
                            </td>
                          </tr>
                        </>
                      ),
                    };
                  })
                  .filter((d) =>
                    catalogPresetSearch !== ""
                      ? d.data.name
                          ?.toLowerCase()
                          .includes(catalogPresetSearch.toLowerCase())
                      : true
                  )
                  .map((d) => d.layout)}
              </table>
            </div>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  remoteControlPresets.presets
                    .filter((p) =>
                      catalogPresetSearch !== ""
                        ? p.name
                            ?.toLowerCase()
                            .includes(catalogPresetSearch.toLowerCase())
                        : true
                    )
                    .forEach((p) => {
                      const client = new PahoMQTT.Client(
                        `${process.env.REACT_APP_FRONTEND_MQTT_WS_URL}/mqtt`,

                        `client-${
                          p.masterJavaBaseModel?.uuid
                        }-${new Date().getTime()}`
                      );
                      client.connect({
                        onSuccess: () => {
                          const message = new PahoMQTT.Message(
                            JSON.stringify(remoteControlPresetToPayload(p))
                          );
                          message.destinationName = `remote/${p.userId}`;
                          client.send(message);
                        },
                      });
                    });
                }}
              >
                Execute
              </button>
            </div>
          </>
        ) : (
          <></>
        )}

        {tab === "Macro" ? (
          <>
            {" "}
            <div className="d-flex flex-wrap">
              {[
                ...new Set(
                  remoteControlPresets.presets.map(
                    (p) => p.name?.toLowerCase() ?? ""
                  )
                ),
              ].map((n) => {
                return (
                  <>
                    <button
                      className="btn btn-sm btn-primary m-2"
                      onClick={() => {
                        sendRemoteByName(remoteControlPresets, n);
                      }}
                    >
                      {n}
                    </button>
                  </>
                );
              })}
            </div>
          </>
        ) : (
          <></>
        )}

        {tab === "Macro-Category" ? (
          <>
            <div>
              <div className="d-flex">
                <div>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      setRemoteControlPresetCategories({
                        ...remoteControlPresetCategories,
                        categories: [
                          ...remoteControlPresetCategories.categories,
                          PpicMacroCategory.fromPartial({
                            masterJavaBaseModel:
                              MasterJavaBaseModel.fromPartial({
                                uuid: v4(),
                              }),
                          }),
                        ],
                      });
                    }}
                  >
                    <Add /> Add
                  </button>
                </div>
                <div className="mx-3">
                  <button
                    className="btn btn-sm btn-success"
                    onClick={async () => {
                      try {
                        const resp = await fetch(
                          `${process.env.REACT_APP_BASE_URL}/macrocategories`,
                          {
                            method: "post",
                            headers: {
                              authorization: ctx?.apiKey ?? "",
                              "content-type": "application/json",
                            },
                            body: JSON.stringify(
                              PpicMacroCategories.toJSON(
                                remoteControlPresetCategories
                              )
                            ),
                          }
                        );

                        // wipe macro category
                        await Promise.all(
                          remoteControlPresetCategoryDeleteIds.map(
                            async (id) => {
                              try {
                                const resp = await fetch(
                                  `${process.env.REACT_APP_BASE_URL}/macrocategories/${id}/empty`,
                                  {
                                    method: "delete",
                                    headers: {
                                      authorization: ctx?.apiKey ?? "",
                                    },
                                  }
                                );
                              } catch (e) {}
                            }
                          )
                        );

                        window.location.reload();
                      } catch (e) {
                      } finally {
                      }
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>

              <hr className="border border-dark" />

              <div
                className="border border-dark shadow shadow-md overflow-auto"
                style={{ height: "65vh", resize: "vertical" }}
              >
                <table
                  className="table table-sm"
                  style={{ borderCollapse: "separate" }}
                >
                  <tr>
                    {["Action", "#", "Buttons"].map((h) => (
                      <>
                        <th
                          className="bg-dark text-light"
                          style={{ position: "sticky", top: 0 }}
                        >
                          {h}
                        </th>
                      </>
                    ))}
                  </tr>
                  {remoteControlPresetCategories.categories.map((c, i) => {
                    return (
                      <>
                        <tr>
                          <td colSpan={2}>
                            <div className="d-flex">
                              <button
                                className="btn btn-sm btn-danger"
                                onClick={() => {
                                  setRemoteControlPresetCategoryDeleteIds([
                                    ...remoteControlPresetCategoryDeleteIds,
                                    c.masterJavaBaseModel?.id ?? "",
                                  ]);
                                  setRemoteControlPresetCategories({
                                    ...remoteControlPresetCategories,
                                    categories:
                                      remoteControlPresetCategories.categories.filter(
                                        (cx, ix) => ix !== i
                                      ),
                                  });
                                }}
                              >
                                <Delete />
                              </button>
                              <input
                                className="form-control form-control-sm"
                                placeholder="Name..."
                                defaultValue={c.name}
                                onBlur={(e) => {
                                  setRemoteControlPresetCategories({
                                    ...remoteControlPresetCategories,
                                    categories:
                                      remoteControlPresetCategories.categories.map(
                                        (cx, ix) =>
                                          ix === i
                                            ? {
                                                ...cx,
                                                name: e.target.value,
                                              }
                                            : cx
                                      ),
                                  });
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-dark">{i + 1}</td>
                          <td className="border border-dark">
                            <div>
                              <div>
                                <Select
                                  placeholder="Select button..."
                                  options={[
                                    ...new Set(
                                      remoteControlPresets.presets.map(
                                        (p) => p.name ?? ""
                                      )
                                    ),
                                  ].map((v) => ({ label: v, value: v }))}
                                  onChange={(v) => {
                                    const val = v as unknown as {
                                      label: string;
                                      value: string;
                                    };

                                    if (
                                      !remoteControlPresetCategories.categories
                                        .find((_, ix) => ix === i)
                                        ?.items.find(
                                          (i) => i.name === val.value
                                        )
                                    ) {
                                      setRemoteControlPresetCategories({
                                        ...remoteControlPresetCategories,
                                        categories:
                                          remoteControlPresetCategories.categories.map(
                                            (cx, ix) =>
                                              ix === i
                                                ? {
                                                    ...cx,
                                                    items: [
                                                      ...cx.items,
                                                      PpicMacroCategory.fromPartial(
                                                        {
                                                          name: val.value,
                                                          masterJavaBaseModel:
                                                            MasterJavaBaseModel.fromPartial(
                                                              { uuid: v4() }
                                                            ),
                                                        }
                                                      ),
                                                    ],
                                                  }
                                                : cx
                                          ),
                                      });
                                    }
                                  }}
                                />
                              </div>
                              <div className="d-flex">
                                {/* {JSON.stringify(c)} */}
                                {c.items.map((i) => {
                                  return (
                                    <>
                                      <div className="d-flex shadow shadow-md mx-2">
                                        <button
                                          className="btn btn-sm btn-primary"
                                          onClick={() => {
                                            sendRemoteByName(
                                              remoteControlPresets,
                                              i.name
                                            );
                                          }}
                                        >
                                          {i.name}
                                        </button>
                                        <button
                                          className="btn btn-sm btn-danger px-1 py-0"
                                          onClick={async () => {
                                            if (
                                              !window.confirm(
                                                `Really delete button ${i.name}?`
                                              )
                                            ) {
                                              return;
                                            }

                                            try {
                                              const resp = await fetch(
                                                `${process.env.REACT_APP_BASE_URL}/macrocategoryitems/${i.masterJavaBaseModel?.id}/empty`,
                                                {
                                                  method: "delete",
                                                  headers: {
                                                    authorization:
                                                      ctx?.apiKey ?? "",
                                                  },
                                                }
                                              );
                                              window.location.reload();
                                            } catch (e) {}
                                          }}
                                        >
                                          <Delete />{" "}
                                        </button>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </table>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        {tab === "Generator" ? (
          <>
            <div>
              <div>
                <small>
                  <strong>Template Name</strong>
                </small>
              </div>
              <div>
                <input
                  className="form-control form-control-sm"
                  placeholder="Template name... "
                  onBlur={(e) => {
                    setRemoteControlGenerationSettings({
                      ...remoteControlGenerationSettings,
                      name: e.target.value,
                    });
                  }}
                />
              </div>
              {/* <hr className="border border-dark" /> */}
              <div>
                <small>
                  <strong>Department</strong>{" "}
                </small>
              </div>
              <div>
                <Select
                  options={ctx?.extDepartments}
                  placeholder="Dept..."
                  getOptionLabel={(d) => `${d.name}`}
                  onChange={(v) => {
                    const val = v as unknown as ExtDepartment;

                    setRemoteControlGenerationSettings({
                      ...remoteControlGenerationSettings,
                      department: val,
                    });
                  }}
                />
              </div>
              <div>
                <small>
                  <strong>Skip date</strong>{" "}
                </small>
              </div>
              <div>
                <input
                  className="form-control form-control-sm"
                  type="number"
                  placeholder="Skip date..."
                  onBlur={(e) => {
                    setRemoteControlGenerationSettings({
                      ...remoteControlGenerationSettings,
                      skipDate: isNaN(parseInt(e.target.value))
                        ? remoteControlGenerationSettings.skipDate
                        : parseInt(e.target.value),
                    });
                  }}
                />
              </div>
              <div>
                <small>
                  <strong>Table type</strong>{" "}
                </small>
              </div>
              <div>
                <Select
                  options={[
                    {
                      label: workOrderTypeToJSON(WorkOrderType.WO_TYPE_GENERIC),
                      value: WorkOrderType.WO_TYPE_GENERIC,
                    },
                    {
                      label: workOrderTypeToJSON(WorkOrderType.WO_TYPE_JOB),
                      value: WorkOrderType.WO_TYPE_JOB,
                    },
                    {
                      label: workOrderTypeToJSON(WorkOrderType.WO_TYPE_DETAILS),
                      value: WorkOrderType.WO_TYPE_DETAILS,
                    },
                  ]}
                  placeholder="Table type..."
                  // getOptionLabel={(d) => `${d.name}`}
                  onChange={(v) => {
                    const val = v as unknown as {
                      label: string;
                      value: WorkOrderType;
                    };

                    setRemoteControlGenerationSettings({
                      ...remoteControlGenerationSettings,
                      tableType: val.value,
                    });
                  }}
                />
              </div>
              <div>
                <small>
                  <strong>Job</strong>{" "}
                </small>
              </div>
              <div>
                <Select
                  options={jobsProto.jobs}
                  placeholder="Job..."
                  getOptionLabel={(j) => `${j.name}`}
                  onChange={(v) => {
                    const val = v as unknown as PpicJob;

                    setRemoteControlGenerationSettings({
                      ...remoteControlGenerationSettings,
                      job: val,
                    });
                  }}
                />
              </div>
              <div>
                <small>
                  <strong>Table type</strong>{" "}
                </small>
              </div>
              <div>
                <Select
                  options={buttonGenerationTypeList}
                  placeholder="Generation type..."
                  // getOptionLabel={(d) => `${d.name}`}
                  onChange={(v) => {
                    const val = v as unknown as {
                      label: string;
                      value: ButtonGenerationType;
                    };

                    setRemoteControlGenerationSettings({
                      ...remoteControlGenerationSettings,
                      generationType: val.value,
                    });
                  }}
                />
              </div>

              <div className="my-3">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    handleGenerateRemoteControlButtons();
                  }}
                >
                  Generate
                </button>
              </div>
              <div>
                <strong>Result</strong>
              </div>
              <div
                className="overflow-auto border border-dark shadow shadow-md"
                style={{ height: "75vh", resize: "vertical" }}
              >
                <table
                  className="table table-sm table-hover"
                  style={{
                    borderCollapse: "separate",
                  }}
                >
                  <tr>
                    {[
                      "#",
                      "Name",
                      "Monitor",
                      "Dept",
                      "User",
                      "Job",
                      "Type",
                      "Skip date",
                      "User type",
                      "Range",
                    ].map((h) => (
                      <>
                        <th className="bg-dark text-light">{h}</th>
                      </>
                    ))}
                  </tr>
                  {remoteControlPresetGeneratorResult.presets.map((p, i) => {
                    return (
                      <>
                        <tr>
                          <td className="border border-dark">{i + 1}</td>
                          <td className="border border-dark">{p.name}</td>
                          <td className="border border-dark">
                            <Select
                              placeholder="User..."
                              getOptionLabel={(u) => `${u.username}:${u.name}`}
                              options={users}
                              onChange={(v) => {
                                const val = v as unknown as User;

                                setRemoteControlPresetGeneratorResult({
                                  ...remoteControlPresetGeneratorResult,
                                  presets:
                                    remoteControlPresetGeneratorResult.presets.map(
                                      (px, ix) =>
                                        ix === i
                                          ? {
                                              ...px,
                                              userId: val.id
                                                ? `${val.id}`
                                                : undefined,
                                            }
                                          : px
                                    ),
                                });
                              }}
                            />
                            {p.userId ? (
                              <>
                                {
                                  users.find((u) => `${u.id}` === `${p.userId}`)
                                    ?.name
                                }
                              </>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td className="border border-dark">
                            {
                              ctx?.extDepartments.find(
                                (d) => `${d.id}` === `${p.extDepartmentId}`
                              )?.name
                            }
                          </td>
                          <td className="border border-dark">
                            {
                              ctx?.extUsers.find(
                                (u) => `${u.id}` === `${p.extUserId}`
                              )?.username
                            }
                          </td>
                          <td className="border border-dark">
                            {
                              jobsProto.jobs.find(
                                (j) =>
                                  `${j.masterJavaBaseModel?.id}` ===
                                  `${p.jobId}`
                              )?.name
                            }
                          </td>
                          <td className="border border-dark">
                            {p.workOrderType ? (
                              <>{workOrderTypeToJSON(p.workOrderType)}</>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td className="border border-dark">{p.skipDate}</td>
                          <td className="border border-dark">
                            {p.picOnly !== undefined ? (
                              p.picOnly ? (
                                <>PIC</>
                              ) : (
                                <>SPV</>
                              )
                            ) : (
                              <>Both</>
                            )}
                          </td>

                          <td className="border border-dark">{p.screen}</td>
                        </tr>
                      </>
                    );
                  })}
                </table>
              </div>
              {remoteControlPresetGeneratorResult.presets.length > 0 ? (
                <>
                  <div>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={async () => {
                        setRemoteControlPresets({
                          ...remoteControlPresets,
                          presets: [
                            ...remoteControlPresets.presets,
                            ...remoteControlPresetGeneratorResult.presets,
                          ],
                        });
                        setRemoteControlPresetGeneratorResult({
                          ...remoteControlPresetGeneratorResult,
                          presets: [],
                        });
                      }}
                    >
                      Execute
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default RemoteControlPage;
