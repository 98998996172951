import {
  ArrowDownward,
  CloudDownload,
  FilterSharp,
  Print,
} from "@material-ui/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalFooter, Spinner } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Link } from "react-router-dom";
import Select from "react-select";
import { AppContext } from "../../../App";
import {
  categoryPartNums,
  categoryPartNumsMR,
  checkUnapproved,
  createBomListFlattened,
  fetchAllPrByJob,
  fetchAutoMRWarehouseFilters,
  fetchBomLeveled,
  fetchBomLeveleds,
  fetchBomLeveledsProtoDetailedJob,
  fetchExtInventory,
  fetchExtInventoryRsvMr,
  fetchExtItemLastPrice,
  fetchExtReserveLists,
  fetchItems,
  fetchJob,
  fetchJobsDataProtoCompose,
  fetchJobsIdName,
  fetchJobsProtoSimple,
  fetchMRAuthorizationDefaults,
  fetchMRByJobProduct,
  fetchMaterialRequests,
  fetchPrItemDetail,
  fetchWorkOrdersProtoMongo,
  getItemFullDescription,
  intlFormat,
  makeDateString,
} from "../../../helpers";
import {
  BomItemType,
  MeetingTaskListView,
  MeetingTaskListsView,
  PpicAutoMRWarehouseFilters,
  PpicBomLeveled,
  PpicJob,
  PpicJobs,
  PpicMRAuthorizationDefaults,
  PpicPanelCode,
  PpicWarehouseReportQty,
  WarehouseItemCategoryPartNum,
  bomItemTypeFromJSON,
  bomItemTypeToJSON,
  warehouseItemCategoryPartNumFromJSON,
} from "../../../masterbigsystem";
import {
  BomLeveled,
  BomLeveledRecursive,
  ExtInventory,
  ExtItem,
  ExtPurchaseRequestPostBodyJson,
  ExtReserveList,
  ExtUser,
  ExtWarehouse,
  ExtWarehouseDetail,
  Job,
  MaterialRequest,
  MaterialRequestItem,
  MaterialRequestType,
  PanelCode,
  PrItemDetail,
} from "../../../models/model";
import {
  initialExtPurchaseRequestPostBodyItemJson,
  initialExtPurchaseRequestPostBodyJson,
  initialJob,
  initialMaterialRequest,
  initialMaterialRequestAuthorization,
  initialMaterialRequestItem,
} from "../../../models/modelinitials";
import { v4 } from "uuid";
import chroma from "chroma-js";

export const materialTypes = [
  BomItemType.BOM_MECH,
  BomItemType.BOM_ELEC,
  BomItemType.BOM_MAT_INST,
] as BomItemType[];

export type AutoMRRegularBulkType = "Regular" | "Bulk";
export const autoMRRegularBulkTypes: AutoMRRegularBulkType[] = [
  "Regular",
  "Bulk",
];

export interface ToMRDetailType {
  type: AutoMRRegularBulkType;
  data: BomParsedInfoType;
}

// export type
export interface BomParsedInfoType {
  bomLeveled: PpicBomLeveled;
  qtyRemaining: number;
  balanceRsv: number;
  warehouse?: ExtWarehouse | null;
  qtyMr: number;
  qtyPr: number;
  inPRQty: number;
  inventory?: ExtInventory | null;
  included?: boolean;
  extPanelCodeId?: string | undefined;
}
const MaterialRequestSimpleView = () => {
  const ctx = useContext(AppContext);

  const bulkMRPanelCodes = useRef(
    [] as { panelCodeId?: any; loading?: boolean }[]
  );
  const [selectQty, setSelectQty] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);
  const [loadingInventory, setLoadingInventory] = useState(false);
  const [loadingJobsIdName, setLoadingJobIdName] = useState(false);
  const [selectedMr, setSelectedMr] = useState<MaterialRequest | null>(null);
  const selectedMrItemsQty = useRef(
    [] as { mr: MaterialRequest; qty: number }[]
  );

  const mrDefaults = useRef(
    PpicMRAuthorizationDefaults.fromPartial({ defaults: [] })
  );
  const selectedAutoMRRegularBulkType = useRef(
    "Regular" as AutoMRRegularBulkType
  );

  const [selectedBom1, setSelectedBom1] = useState<BomLeveledRecursive | null>(
    null
  );
  const [selectedBom2, setSelectedBom2] = useState<BomLeveledRecursive | null>(
    null
  );
  const filterChangeMROnly = useRef(false);
  const bomMrRevisionShowModal = useRef(false);
  const [items, setItems] = useState<ExtItem[]>([]);
  const [addNewMr, setAddNewMr] = useState(false);
  const [addNewAutoMR, setAddNewAutoMR] = useState(false);
  const [reserveList, setReserveList] = useState<ExtReserveList[]>([]);
  const [newMr, setNewMr] = useState({
    ...initialMaterialRequest,
    type: "PRODUCTION",
  } as MaterialRequest);
  const bomLeveledsFetchLoading = useRef(false);
  const [selectItem, setSelectItem] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [searchWarehouse, setSearchWarehouse] = useState("");
  const autoMRRequestedById = useRef(null as number | null);

  const [jobs, setJobs] = useState<PpicJobs>(
    PpicJobs.fromPartial({ jobs: [] })
  );
  const [inventory, setInventory] = useState<ExtInventory[]>([]);
  const [inventoryRsvMr, setInventoryRsvMr] = useState<ExtInventory[]>([]);
  const [filters, setFilters] = useState({
    showHidden: false as boolean | null,
  });
  const selectedMaterialTypes = useRef([
    BomItemType.BOM_MECH,
    BomItemType.BOM_ELEC,
    BomItemType.BOM_MAT_INST,
  ] as BomItemType[]);
  const importing = useRef(false);
  const from = useRef(makeDateString(new Date()));
  const to = useRef(makeDateString(new Date()));
  const [, refresh] = useState(false);
  const bomLeveledAutoMR = useRef(PpicBomLeveled.fromPartial({ children: [] }));
  const selectedAutoMRJobId = useRef(null as number | null);
  const selectedAutoMRPanelCodeId = useRef(null as number | null);
  const selectPrLeftOnly = useRef(false);
  const autoMrLoading = useRef(false);
  const mrItemFailureData = useRef([] as MaterialRequestItem[]);
  const allPrItemDetail = useRef([] as PrItemDetail[]);
  const allPrLoading = useRef(false);
  const selectedStatus = useRef(null as number | null);
  const selectedAutoMRPanelCodeIds = useRef([] as (string | undefined)[]);
  const render = () => {
    refresh((n) => !n);
  };
  const showFinalMRResult = useRef(false);
  const expandedMR = useRef(
    [] as { mrs: MaterialRequest[]; extItemId: number }[]
  );

  const [bomLeveleds, setBomLeveleds] = useState<BomLeveledRecursive[]>([]);
  const bomRevisionMrJob = useRef(null as PpicJob | null);
  const [materialRequests, setMaterialRequests] = useState<MaterialRequest[]>(
    []
  );
  const [materialRequestsAll, setMaterialRequestsAll] = useState<
    MaterialRequest[]
  >([]);
  const selectedCategoryPartNumsMR = useRef(null as number | null);

  useEffect(() => {
    handleInit();
  }, [filters]);

  const handleInit = async () => {
    fetchMaterialRequestsData();
    fetchItemsData();
    fetchJobsIdNameData();
    fetchExtInventoryData();
    fetchExtInventoryRsvMrData();
    fetchExtReserveListData();
    fetchBomLeveledsData();
    fetchMrAuthorizationDefaultsData();
  };

  const mrsByJob = useRef([] as MaterialRequest[]);
  const mrsByJobManual = useRef([] as MaterialRequest[]);
  const mrsByItemManual = useRef(
    [] as { extItemId: any; mrs: MaterialRequest[] }[]
  );
  const mrsByJobManualShow = useRef(true);

  const fetchMrAuthorizationDefaultsData = async () => {
    const d = await fetchMRAuthorizationDefaults({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      mrDefaults.current = d;
      render();
    }
  };

  const handleGetMRByJobProduct = async (params: {
    extItemId: any;
    jobId: any;
  }) => {
    if (
      expandedMR.current.find((e) => `${e.extItemId}` === `${params.extItemId}`)
    ) {
      expandedMR.current = expandedMR.current.filter(
        (m) => `${m.extItemId}` !== `${params.extItemId}`
      );
      render();
      return;
    }

    // const d = await fetchMRByJobProduct({
    //   extItemId: params.extItemId,
    //   jobId: params.jobId,
    // });

    // if (d) {
    expandedMR.current.push({
      extItemId: params.extItemId,
      mrs: mrsByJob.current.filter((m) =>
        m.materialRequestItems?.find(
          (ix) => `${ix.extItemId}` === `${params.extItemId}`
        )
      ),
    });
    render();
    // }
  };

  const handleGetMRByJob = async (params: {
    // extItemId: any;
    jobId: any;
  }) => {
    const d = await fetchMRByJobProduct({
      // extItemId: params.extItemId,
      jobId: params.jobId,
    });

    if (d) {
      mrsByJob.current = d;
      render();
    }
  };

  useEffect(() => {
    setNewMr({
      ...newMr,
      materialRequestAuthorizations: mrDefaults.current.defaults.map((d) => ({
        ...initialMaterialRequestAuthorization,
        uuid: v4(),
        extUserId: isNaN(parseInt(d.extUserId ?? ""))
          ? null
          : parseInt(d.extUserId ?? ""),
      })),
    });
  }, [mrDefaults.current]);

  const handleGetMRByJobManual = async (params: {
    // extItemId: any;
    jobId: any;
  }) => {
    const d = await fetchMRByJobProduct({
      // extItemId: params.extItemId,
      jobId: params.jobId,
    });

    if (d) {
      mrsByJobManual.current = d;
      render();
    }
  };

  const fetchExtReserveListData = async () => {
    setReserveList(await fetchExtReserveLists({ apiKey: ctx?.apiKey ?? "" }));
  };

  const fetchBomLeveledsData = async () => {
    setBomLeveleds(
      await fetchBomLeveleds({ apiKey: ctx?.apiKey ?? "", recursive: false })
    );
  };

  const selectedId = useRef(null as any | null);

  const itemPrices = useRef(
    [] as { extItemId: number | null; price: PpicBomLeveled }[]
  );

  useEffect(() => {
    (async () => {
      const items = [
        ...(selectedBom1 ? createBomListFlattened([], [selectedBom1]) : []),
        ...(selectedBom2 ? createBomListFlattened([], [selectedBom2]) : []),
      ];

      items.forEach(async (i) => {
        if (
          !itemPrices.current.find(
            (ix) => `${i.bom.bom?.bomLeveled?.extItemId}` === `${ix.extItemId}`
          )
        ) {
          const itemPrice = await fetchExtItemLastPrice({
            apiKey: ctx?.apiKey ?? "",
            id: `${i.bom.bom?.bomLeveled?.extItemId}`,
          });

          if (itemPrice) {
            itemPrices.current.push({
              extItemId: i.bom.bom?.bomLeveled?.extItemId ?? null,
              price: itemPrice,
            });

            render();
          }
        }
      });
    })();
  }, [selectedBom1, selectedBom2]);

  const filterChangeMROnlyQty = (b: {
    bom: {
      bom: {
        bom: BomLeveledRecursive | null;
        multiplier?: number | null | undefined;
      };
      parent: BomLeveled | null;
    };
    oldQty: number;
    newQty: number;
    newItem: boolean;
  }) => {
    return b.newQty - b.oldQty > 0;
  };

  const fetchMaterialRequestsData = async () => {
    try {
      setLoading(true);
      setMaterialRequests(
        await fetchMaterialRequests({
          apiKey: ctx?.apiKey ?? "",
          showHidden: filters.showHidden,
          from: new Date(`${from.current}T00:00:00`).toISOString(),
          to: new Date(`${to.current}T23:59:59`).toISOString(),
          id: selectedId.current,
        })
      );
      setMaterialRequestsAll(
        await fetchMaterialRequests({
          apiKey: ctx?.apiKey ?? "",
          showHidden: false,
        })
      );
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const fetchItemsData = async () => {
    try {
      setLoadingItems(true);
      setItems(
        (await fetchItems(
          process.env.REACT_APP_BASE_URL ?? "",
          ctx?.apiKey ?? ""
        )) ?? []
      );
    } catch (e) {
    } finally {
      setLoadingItems(false);
    }
  };
  const fetchJobsIdNameData = async () => {
    try {
      setLoadingJobIdName(true);

      const d = await fetchJobsProtoSimple({
        apiKey: ctx?.apiKey ?? "",
        withProducts: true,
        all: true,
      });

      if (d) {
        setJobs(d);
      }
    } catch (e) {
    } finally {
      setLoadingJobIdName(false);
    }
  };

  const fetchPrItemsData = async (params: { jobId?: any }) => {
    const relevantPrs = await fetchAllPrByJob({
      jobId: params.jobId,
    });

    // Fetch all PR item details
    const d = (
      await Promise.all(
        relevantPrs.map(async (p) => {
          try {
            const d = await fetchPrItemDetail({ prId: p.id });

            return d;
          } catch (e) {
            return [];
          }
        })
      )
    ).flat();

    // console.log(
    //   "relevant prs",
    //   relevantPrs,
    //   j?.masterJavaBaseModel?.id
    // );
    console.log("pr item detail", d);

    // Set in pr item details
    allPrItemDetail.current = d;
    allPrLoading.current = false;

    render();
  };

  const fetchExtInventoryData = async () => {
    try {
      setLoadingInventory(true);
      setInventory(await fetchExtInventoryRsvMr({ apiKey: ctx?.apiKey ?? "" }));
    } catch (e) {
    } finally {
      setLoadingInventory(false);
    }
  };

  const fetchExtInventoryRsvMrData = async () => {
    try {
      // setLoadingInventory(true);
      setInventoryRsvMr(
        await fetchExtInventoryRsvMr({ apiKey: ctx?.apiKey ?? "" })
      );
    } catch (e) {
    } finally {
      // setLoadingInventory(false);
    }
  };

  const autoMrWarehouseFilters = useRef(
    PpicAutoMRWarehouseFilters.fromPartial({})
  );

  const fetchAutoMRWarehouseFiltersData = async () => {
    const d = await fetchAutoMRWarehouseFilters({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      autoMrWarehouseFilters.current = d;
      render();
    }
  };

  useEffect(() => {
    console.log(from, to);

    fetchMaterialRequestsData();
    fetchAutoMRWarehouseFiltersData();
  }, [from.current, to.current]);

  useEffect(() => {
    refreshBomParsedInfo();
  }, [items, inventory]);

  const bomParsedInfo = useRef([] as BomParsedInfoType[]);
  const mrBulk = useRef([] as BomParsedInfoType[]);

  const mrFinal = useRef([] as ToMRDetailType[]);

  useEffect(() => {
    const mrFinalData = [
      ...(selectedMaterialTypes.current.length === 1 &&
      selectedAutoMRPanelCodeId.current
        ? bomParsedInfo.current
            .filter((p) => p.qtyMr > 0 && p.included)
            .map((d) => ({ type: "Regular", data: d }))
        : mrBulk.current.map((b) => ({ type: "Bulk", data: b }))),
    ] as ToMRDetailType[];

    console.log("mrbulk", mrBulk.current);

    mrFinal.current = mrFinalData;
    render();
  }, [
    bomParsedInfo.current,
    mrBulk.current,
    selectedMaterialTypes.current,
    selectedAutoMRPanelCodeId.current,
  ]);

  const refreshBomParsedInfo = () => {
    const bpi = bomLeveledAutoMR.current.children.map((b, i) => {
      const qtyRemaining = (b.qty ?? 0) - (b.inMr ?? 0);

      const foundInv = inventory.find(
        (i) =>
          `${i.productId}` === `${b.extItemId}` &&
          i.balanceRsv !== null &&
          i.balanceRsv > 0 &&
          defaultWarehouse &&
          `${i.warehouse?.warehouse?.id}` === `${defaultWarehouse?.id}`
      );

      const qtyMr =
        (foundInv?.balanceRsv ?? 0) < 0
          ? 0
          : (foundInv?.balanceRsv ?? 0) < qtyRemaining
          ? foundInv?.balanceRsv ?? 0
          : qtyRemaining;

      const balanceRsvPr =
        (foundInv?.balanceRsv ?? 0) < 0 ? 0 : foundInv?.balanceRsv ?? 0;

      const qtyPr =
        qtyRemaining > balanceRsvPr ? qtyRemaining - balanceRsvPr : 0;

      const inPR = allPrItemDetail.current
        .filter((i) => `${i.productId}` === `${b.extItemId}`)
        .reduce((acc, d) => acc + (d.qtyPr ?? 0), 0);

      console.log(
        "all pr item detail",
        `${b.extItemId}`,
        allPrItemDetail.current,
        inPR
      );

      let qtyPrFiltered = qtyPr - inPR;

      if (qtyPrFiltered < 0) {
        qtyPrFiltered = 0;
      }

      return {
        bomLeveled: b,
        qtyRemaining: qtyRemaining,
        balanceRsv: foundInv?.balanceRsv ?? 0,
        qtyMr: Math.ceil(qtyMr),
        warehouse: foundInv?.warehouse,
        inventory: foundInv,
        included: (qtyMr ?? 0) > 0 ? true : false,
        qtyPr: qtyPrFiltered,
        inPRQty: inPR,
      };
    });

    bpi.sort((a, b) => b.qtyRemaining - a.qtyRemaining);

    bomParsedInfo.current = bpi;
    // .filter(d=>{
    //   const foundItem = items.find(i=>`${i.id}`===`${d.inventory?.productId}`)

    //   const foundCPN = categoryPartNums.find(
    //     (c) => `${c.value}` === `${foundItem?.categoryPartNum}`
    //   );

    //   return foundCPN?.label !== 'Supply' && foundCPN?.label !== 'Consumable'
    // })

    render();
  };

  const warehouses = (() => {
    const whFlats = inventory.map((i) => i.warehouse?.warehouse);

    const whIds = [...new Set(whFlats.map((w) => `${w?.id}`))];

    return whIds
      .map((i) => whFlats.find((w) => `${w?.id}` === `${i}`))
      .filter((i) => i)
      .map((i) => i as ExtWarehouseDetail);
  })();

  const defaultWarehouse = warehouses.find(
    (w) =>
      `${w.id}` ===
      `${autoMrWarehouseFilters.current.filters.find((_) => true)?.warehouseId}`
  );

  const handleSavePR = async () => {
    try {
      // setLoadingSave(true);
      // console.log(newPr);

      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/ext-purchase-requests-save-json`,
        {
          method: "post",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            ...initialExtPurchaseRequestPostBodyJson,
            jobId: selectedAutoMRJobId.current,
            extPurchaseRequestPostBodyItemsJson: bomParsedInfo.current
              .filter((pi) => pi.qtyPr > 0)
              .map((pi) => {
                return {
                  ...initialExtPurchaseRequestPostBodyItemJson,
                  productId: pi.bomLeveled.extItemId ?? 0,
                  qtyPr: pi.qtyPr ?? 0,
                };
              }),
          } as ExtPurchaseRequestPostBodyJson),
        }
      );

      // selectedJobBomLeveled.current = PpicBomLeveled.fromPartial({});
      // fetchAllPr();

      render();

      handleInit();
    } catch (e) {
      console.error(e);
      alert(e);
    } finally {
      // setShowModal(false);
      // setLoadingSave(false);
    }
  };

  const findRelevantItems = () => {
    return items.filter(
      (i) =>
        searchItem !== "" &&
        // by item name
        (() => {
          const searchWordResult = searchItem
            .toLowerCase()
            .split(" ")
            .map((w) =>
              `${i.mfr}${i.partDesc}${i.partName}${i.partNum}`
                .toLowerCase()
                .includes(w)
            );

          return (
            searchWordResult.filter((r) => r).length === searchWordResult.length
          );
        })() &&
        // by category partnum
        (newMr?.partNumCategory !== null
          ? `${i?.categoryPartNum}` === `${newMr?.partNumCategory}`
          : true)
    );
  };

  const relevantItems = (() => {
    if (searchItem !== "") {
      return findRelevantItems();
    } else if (searchItem === "" && searchWarehouse !== "") {
      return items.filter((it) =>
        inventory
          .filter((inv) => inv.productId === it.id)
          .find((i) =>
            searchWarehouse !== ""
              ? `${i.warehouse?.warehouse?.name}.${i.warehouse?.whName}`
                  .toLowerCase()
                  .includes(searchWarehouse.toLowerCase())
              : true
          )
      );
    } else {
      return findRelevantItems();
    }
  })();

  const selectedBomListFlattened1 = selectedBom1
    ? createBomListFlattened([], [selectedBom1])
    : [];
  const selectedBomListFlattened1Price = selectedBomListFlattened1.reduce(
    (acc, b) => {
      const foundItemPRice = itemPrices.current.find(
        (i) => `${i.extItemId}` === `${b.bom.bom?.bomLeveled?.extItemId}`
      );
      return (
        acc +
        (b.bom.bom?.bomLeveled?.qty ?? 0) *
          (foundItemPRice?.price.priceIdr ?? 0)
      );
    },
    0
  );
  const selectedBomListFlattened2 = selectedBom2
    ? createBomListFlattened([], [selectedBom2])
    : [];

  const selectedBomListFlattened2Price = selectedBomListFlattened2.reduce(
    (acc, b) => {
      const foundItemPRice = itemPrices.current.find(
        (i) => `${i.extItemId}` === `${b.bom.bom?.bomLeveled?.extItemId}`
      );
      return (
        acc +
        (b.bom.bom?.bomLeveled?.qty ?? 0) *
          (foundItemPRice?.price.priceIdr ?? 0)
      );
    },
    0
  );

  const filteredBom1 = selectedBomListFlattened1.filter(
    (b) => b.bom.bom?.bomLeveled?.extItemId
  );

  const filteredBom2 = selectedBomListFlattened2
    .filter((b) => b.bom.bom?.bomLeveled?.extItemId)
    .filter((b) => {
      // filter new items from bom 1
      return !filteredBom1.find(
        (bx) =>
          `${bx.bom.bom?.bomLeveled?.extItemId}` ===
          `${b.bom.bom?.bomLeveled?.extItemId}`
      );
    });

  const mappedItems = [
    filteredBom1.map((b) => {
      const foundNewQty = selectedBomListFlattened2.find(
        (bx) =>
          `${bx.bom.bom?.bomLeveled?.extItemId}` ===
          `${b.bom.bom?.bomLeveled?.extItemId}`
      );
      return {
        bom: b,
        oldQty: b.bom.bom?.bomLeveled?.qty ?? 0,
        newQty: foundNewQty?.bom.bom?.bomLeveled?.qty ?? 0,
        newItem: false,
      };
    }),
    filteredBom2.map((b) => {
      return {
        bom: b,
        oldQty: 0,
        newQty: b.bom.bom?.bomLeveled?.qty ?? 0,
        newItem: true,
      };
    }),
  ]
    .flat()
    .map((m) => {
      const foundInv = inventory.find(
        (i) =>
          `${i.productId}` === `${m.bom.bom.bom?.bomLeveled?.extItemId}` &&
          i.balanceRsv !== 0 &&
          defaultWarehouse &&
          `${i.warehouse?.warehouse?.id}` === `${defaultWarehouse?.id}`
      );

      let neededQty = 0;

      if (m.newQty > m.oldQty) {
        neededQty = m.newQty - m.oldQty;
      }

      const inStock = foundInv?.balanceRsv ?? 0;

      let finalMR = 0;

      if (inStock > 0) {
        if (inStock >= neededQty) {
          finalMR = neededQty;
        }

        if (inStock < neededQty) {
          finalMR = inStock;
        }
      }

      return { ...m, inventory: foundInv, finalMR: finalMR };
    });

  const mrWO = useRef(null as MeetingTaskListView | null);

  const handleFetchMRAuthorizationWO = async (mr: MaterialRequest) => {
    const d = await fetchWorkOrdersProtoMongo({
      apiKey: ctx?.apiKey ?? "",
      extMaterialRequestId: mr.id,
    });

    if (d.taskLists?.[0]) {
      mrWO.current = d.taskLists[0];
    } else {
      mrWO.current = null;
    }

    render();
  };

  const handleBulkAdd = async (params: {
    bomParsedInfoTypes: BomParsedInfoType[];
  }) => {
    if (
      !window.confirm(`Bulk add ${params.bomParsedInfoTypes.length} item(s)?`)
    ) {
      return;
    }

    if (params.bomParsedInfoTypes.length > 1) {
      mrBulk.current = [];
      render();
      render();
    }

    // Check each product need
    const foundJob = jobs.jobs.find(
      (j) => `${j.masterJavaBaseModel?.id}` === `${selectedAutoMRJobId.current}`
    );

    bulkMRPanelCodes.current =
      selectedAutoMRPanelCodeIds.current.map((c) => ({
        panelCodeId: c,
        loading: true,
      })) ?? [];

    render();
    render();

    for (let c of foundJob?.panelCodes.filter(
      (c) =>
        !c.masterJavaBaseModel?.hidden &&
        selectedAutoMRPanelCodeIds.current.find(
          (id) => `${id}` === `${c.masterJavaBaseModel?.id}`
        )
    ) ?? []) {
      render();

      const jobDetail = await fetchBomLeveledsProtoDetailedJob({
        apiKey: ctx?.apiKey ?? "",
        jobId: selectedAutoMRJobId.current,
        includePrice: false,
        types: selectedMaterialTypes.current,
        includedPanelCodes: [
          c.masterJavaBaseModel?.id ? c.masterJavaBaseModel.id : null,
        ],
      });

      params.bomParsedInfoTypes
        .filter((b) => b.included)
        .forEach((b, ix) => {
          // Search for item
          const foundBomChild = jobDetail?.children?.find(
            (c) => `${c.extItemId}` === `${b.bomLeveled.extItemId}`
          );

          if (foundBomChild) {
            const foundInv = inventory.find(
              (i) =>
                `${i.productId}` === `${b.bomLeveled.extItemId}` &&
                i.balanceRsv !== null &&
                i.balanceRsv > 0 &&
                defaultWarehouse &&
                `${i.warehouse?.warehouse?.id}` === `${defaultWarehouse?.id}`
            );

            // Find item already in MR (panel code, inv, and type)
            const foundDoneMRsQtyAcc = mrsByJob.current
              .flatMap((m) =>
                m.materialRequestItems?.map((i) => ({ mr: m, item: i }))
              )
              .filter(
                (d) =>
                  `${d?.item.extPanelCodeId}` ===
                    `${c.masterJavaBaseModel?.id}` &&
                  `${d?.item.extInventoryId}` === `${foundInv?.id}`
              )
              .reduce((acc, d) => acc + (d?.item.qty ?? 0), 0.0);

            const accMrBulk = mrBulk.current
              .filter((m) => `${m.inventory?.id}` === `${foundInv?.id}`)
              .reduce((acc, m) => acc + (m.qtyMr ?? 0), 0.0);

            let qtyNeededLeft = (foundBomChild.qty ?? 0) - foundDoneMRsQtyAcc;
            // -
            // (foundBomChild.inMr ?? 0);

            if (qtyNeededLeft < 0) {
              qtyNeededLeft = 0;
            }

            // console.log(
            //   `${c.type}:${c.name} ${foundInv?.products?.partNum} left: ${qtyNeededLeft}, acc: ${accMrBulk}, founddoneinMR: ${foundDoneMRsQtyAcc} `
            // );

            if (qtyNeededLeft > 0) {
              // Check amount left after subtraction with accumulated mr
              const balanceRsvAmt = (foundInv?.balanceRsv ?? 0) - accMrBulk;

              // console.log(
              //   `balance rsv: ${balanceRsvAmt}, foundbomchild ${
              //     foundBomChild.qty
              //   }, qty ${qtyNeededLeft}, balance - qty ${
              //     balanceRsvAmt - qtyNeededLeft > 0
              //   }`
              // );

              if (
                balanceRsvAmt > 0 &&
                (foundBomChild.qty ?? 0) > 0
                // &&
                // balanceRsvAmt - qtyNeededLeft > 0
              ) {
                let foundEnoughStock = 0;

                if (balanceRsvAmt - qtyNeededLeft < 0) {
                  foundEnoughStock = balanceRsvAmt;
                } else {
                  foundEnoughStock = qtyNeededLeft;
                }

                const data = {
                  inventory: foundInv,
                  bomLeveled: b.bomLeveled,
                  qtyRemaining: 0,
                  balanceRsv: 0,
                  qtyMr: foundEnoughStock,
                  qtyPr: 0,
                  inPRQty: 0,
                  included: true,
                  extPanelCodeId: c.masterJavaBaseModel?.id,
                };

                console.log("ok push", data);
                mrBulk.current = [...mrBulk.current, data];
                render();
              }
            }
          }

          bulkMRPanelCodes.current = bulkMRPanelCodes.current.map((cx) =>
            `${cx.panelCodeId}` === `${c.masterJavaBaseModel?.id}`
              ? { ...cx, loading: false }
              : cx
          );

          render();
        });
    }

    render();
  };

  return (
    <>
      <Modal
        // style={{ width: `90vw` }}
        // size="xl"
        dialogClassName="modal-xxl"
        show={bomMrRevisionShowModal.current}
        onHide={() => {
          bomMrRevisionShowModal.current = false;
          render();
        }}
      >
        <ModalHeader></ModalHeader>
        <ModalBody>
          <div>
            <div>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <Select
                    placeholder="Select BOM 1..."
                    options={
                      bomLeveleds.map((b) => ({
                        label: b.bomLeveled?.name,
                        value: b,
                      })) ?? []
                    }
                    // getOptionLabel={(b) => b.bomLeveled?.name ?? "No name"}
                    onChange={async (b) => {
                      const val = b as { value: BomLeveledRecursive };

                      // setBomLoading(true);
                      setSelectedBom1(
                        await fetchBomLeveled({
                          apiKey: ctx?.apiKey ?? "",
                          id: val.value.bomLeveled?.id,
                        })
                      );
                      // setBomLoading(false);
                    }}
                  />
                  <div
                    className={`font-weight-bold ${
                      selectedBom1 && selectedBom2
                        ? selectedBomListFlattened1Price >
                          selectedBomListFlattened2Price
                          ? selectedBomListFlattened2Price >
                            selectedBomListFlattened1Price
                            ? `bg-success text-light`
                            : `bg-danger text-light`
                          : ``
                        : ``
                    }`}
                  >
                    IDR{" "}
                    {Intl.NumberFormat("id-ID").format(
                      selectedBomListFlattened1Price
                    )}
                  </div>
                </div>
                <div className="flex-grow-1">
                  <Select
                    placeholder="Select BOM 2..."
                    options={
                      bomLeveleds.map((b) => ({
                        label: b.bomLeveled?.name,
                        value: b,
                      })) ?? []
                    }
                    // options={bomLeveleds ?? []}
                    // getOptionLabel={(b) => b.bomLeveled?.name ?? "No name"}
                    onChange={async (b) => {
                      const val = b as { value: BomLeveledRecursive };

                      // setBomLoading(true);
                      setSelectedBom2(
                        await fetchBomLeveled({
                          apiKey: ctx?.apiKey ?? "",
                          id: val.value.bomLeveled?.id,
                        })
                      );
                      // setBomLoading(false);
                    }}
                  />
                  <div
                    className={`font-weight-bold ${
                      selectedBom1 && selectedBom2
                        ? selectedBomListFlattened1Price >
                          selectedBomListFlattened2Price
                          ? selectedBomListFlattened2Price >
                            selectedBomListFlattened1Price
                            ? `bg-danger text-light`
                            : `bg-success text-light`
                          : ``
                        : ``
                    }`}
                  >
                    IDR{" "}
                    {Intl.NumberFormat("id-ID").format(
                      selectedBomListFlattened2Price
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex">
              Filter change MR only:{" "}
              <input
                type="checkbox"
                checked={filterChangeMROnly.current}
                onClick={() => {
                  filterChangeMROnly.current = !filterChangeMROnly.current;
                  render();
                }}
              />
            </div>

            <div>
              <strong>Job</strong>
            </div>
            <div>
              <Select
                options={jobs.jobs ?? []}
                getOptionLabel={(j) => `${j.name}`}
                placeholder="Select job... "
                onChange={(v) => {
                  const val = v as PpicJob;

                  if (val) {
                    bomRevisionMrJob.current = val;
                  }
                  render();
                }}
              />
            </div>
            <div
              className="border border-dark overflow-auto"
              style={{ resize: "vertical", height: "45vh" }}
            >
              <table
                style={{ borderCollapse: "separate" }}
                className="table table-sm"
              >
                <tr>
                  {[
                    "#",
                    "MFR",
                    "P/N",
                    "Part Name",
                    "Part Desc",
                    "Qty",
                    "vs Qty",
                    "Old Price",
                    "New Price",
                    "In Stock",
                    "Warehouse",
                    "To MR",
                    "UM",
                  ].map((h) => (
                    <>
                      <th
                        className="bg-dark text-light p-0 m-0"
                        style={{ position: "sticky", top: 0 }}
                      >
                        {h}
                      </th>
                    </>
                  ))}
                </tr>
                {(() => {
                  const bomToRow = (
                    b: {
                      bom: {
                        bom: {
                          bom: BomLeveledRecursive | null;
                          multiplier?: number | null | undefined;
                        };
                        parent: BomLeveled | null;
                      };
                      oldQty: number;
                      newQty: number;
                      newItem: boolean;
                      inventory: ExtInventory | undefined;
                      finalMR: number;
                    } | null,
                    i: number
                  ) => {
                    const foundItem = items.find(
                      (ix) =>
                        `${ix.id}` ===
                        `${b?.bom.bom.bom?.bomLeveled?.extItemId}`
                    );
                    const foundItemPrice = itemPrices.current.find(
                      (ix) =>
                        `${ix.extItemId}` ===
                        `${b?.bom.bom.bom?.bomLeveled?.extItemId}`
                    );

                    const oldPrice =
                      (foundItemPrice?.price.priceIdr ?? 0) * (b?.oldQty ?? 0);
                    const newPrice =
                      (foundItemPrice?.price.priceIdr ?? 0) * (b?.newQty ?? 0);

                    return (
                      <>
                        <tr className={`${b?.newItem ? `bg-warning` : ``}`}>
                          <td className="border border-dark">{i + 1}</td>
                          <td className="border border-dark">
                            {foundItem?.mfr}
                          </td>

                          <td className="border border-dark">
                            {foundItem?.partNum}
                          </td>
                          <td className="border border-dark">
                            {foundItem?.partName}
                          </td>
                          <td className="border border-dark">
                            {foundItem?.partDesc}
                          </td>
                          <td className="border border-dark">{b?.oldQty}</td>
                          <td
                            className={`border border-dark ${
                              (b?.newQty ?? 0) <= 0
                                ? `bg-danger`
                                : b?.oldQty !== b?.newQty
                                ? `bg-warning`
                                : ``
                            }`}
                          >
                            {b?.newQty}
                          </td>
                          <td className="border border-dark">
                            IDR{" "}
                            {Intl.NumberFormat("id-ID", {
                              minimumFractionDigits: 0,
                            }).format(oldPrice)}
                          </td>
                          <td className="border border-dark">
                            IDR{" "}
                            {Intl.NumberFormat("id-ID", {
                              minimumFractionDigits: 0,
                            }).format(newPrice)}
                          </td>

                          <td className="border border-dark">
                            {b?.inventory?.balanceRsv}
                          </td>
                          <td className="border border-dark">
                            {b?.inventory?.warehouse?.warehouse?.name}:
                            {b?.inventory?.warehouse?.whName}
                          </td>
                          <td className="border border-dark">{b?.finalMR}</td>

                          <td className="border border-dark">
                            {foundItem?.defaultUm}
                          </td>
                        </tr>
                      </>
                    );
                  };

                  return mappedItems
                    .filter((m) =>
                      filterChangeMROnly.current
                        ? filterChangeMROnlyQty(m)
                        : true
                    )
                    .map((m, i) => bomToRow(m, i));
                })()}
              </table>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-sm btn-primary"
            onClick={async () => {
              // if (selectedMaterialTypes.current.length !== 1) {
              //   alert("Selected material type must be exactly 1.");
              //   return;
              // }

              // if (!selectedAutoMRPanelCodeId.current) {
              //   alert("Panel code must be selected.");
              //   return;
              // }

              console.log(bomRevisionMrJob.current);
              if (!bomRevisionMrJob.current?.masterJavaBaseModel?.id) {
                alert("Job ID invalid.");
                return;
              }

              if (!window.confirm("Create MR?")) {
                return;
              }

              const foundJob = jobs.jobs.find(
                (j) =>
                  `${j.masterJavaBaseModel?.id}` ===
                  `${selectedAutoMRJobId.current}`
              );
              const foundPanelCode = jobs.jobs
                .flatMap((j) => j.panelCodes)
                .find(
                  (c) =>
                    `${c.masterJavaBaseModel?.id}` ===
                    `${selectedAutoMRPanelCodeId.current}`
                );

              const newMR = {
                ...initialMaterialRequest,
                uuid: v4(),
                name: `BOM-DIFF-MR: ${Intl.DateTimeFormat("en-US", {
                  dateStyle: "medium",
                  timeStyle: "short",
                }).format(new Date())}`,
                job: {
                  ...initialJob,
                  id: isNaN(
                    parseInt(bomRevisionMrJob.current.masterJavaBaseModel.id)
                  )
                    ? null
                    : parseInt(bomRevisionMrJob.current.masterJavaBaseModel.id),
                },
                neededDate: makeDateString(new Date()),
                materialRequestNumber: `BOM-DIFF-MR-${makeDateString(
                  new Date()
                )}T${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
                type: "PRODUCTION",
                materialRequestItems: mappedItems
                  .filter((m) => filterChangeMROnlyQty(m))
                  .map((p) => {
                    return {
                      ...initialMaterialRequestItem,
                      uuid: v4(),
                      extInventoryId: p.inventory?.id,
                      extItemId: p.inventory?.productId,
                      qty: p.newQty - p.oldQty,
                      // extPanelCodeId: selectedAutoMRPanelCodeId.current,
                      // bomItemType: bomItemTypeFromJSON(
                      //   selectedMaterialTypes.current?.[0]
                      // ),
                    };
                  }),
                // extRequestedById: autoMRRequestedById.current,
              } as MaterialRequest;

              try {
                autoMrLoading.current = true;
                render();

                const resp = await fetch(
                  `${process.env.REACT_APP_BASE_URL}/materialrequests-save`,
                  {
                    method: "post",
                    headers: {
                      "content-type": "application/json",
                    },
                    body: JSON.stringify({
                      ...newMR,
                    }),
                  }
                );

                // window.location.reload();
                bomRevisionMrJob.current = null;
                fetchMaterialRequestsData();
                fetchExtInventoryData();
                setAddNewAutoMR(false);
                bomParsedInfo.current = [];

                bomMrRevisionShowModal.current = false;
                render();

                render();
              } catch (e) {
                console.error(e);
              } finally {
                autoMrLoading.current = false;
                render();
              }
            }}
          >
            Create MR
          </button>
        </ModalFooter>
      </Modal>
      <div>
        <div className="d-flex align-items-end">
          <h5>Material Requests</h5>
          <div className="d-flex">
            <div className="mx-2">
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  setAddNewMr(true);
                }}
              >
                Add
              </button>
            </div>
            <div className="mx-2">
              {" "}
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  setAddNewAutoMR(true);
                }}
              >
                AutoMR
              </button>
            </div>

            <div className="mx-2">
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  bomMrRevisionShowModal.current = true;
                  render();
                }}
              >
                BOM MR Revision
              </button>
            </div>
          </div>
          <div className="mx-2">
            {loading ? (
              <>
                <div className="d-flex">
                  <Spinner animation="border" />
                  Loading MR...
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="mx-2">
            {loadingInventory ? (
              <>
                <div className="d-flex">
                  <Spinner animation="border" />
                  Loading inv...
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="my-2"></div>

        <hr />

        <div className="d-flex">
          <div className="d-flex">
            {[
              { label: "All", value: null },
              { label: "Valid", value: false },
              { label: "Cancelled", value: true },
            ].map((e) => {
              return (
                <>
                  <div className="mx-2">
                    <button
                      onClick={() => {
                        setFilters({ ...filters, showHidden: e.value });
                      }}
                      className={`btn btn-sm ${
                        filters.showHidden === e.value
                          ? "btn-primary"
                          : "btn-outline-primary"
                      }`}
                    >
                      {e.label}
                    </button>
                  </div>
                </>
              );
            })}
          </div>

          <div className="d-flex">
            <div>
              <div>
                <small>
                  <strong>From</strong>
                </small>
              </div>
              <div>
                <input
                  type="date"
                  className="form-control form-control-sm"
                  defaultValue={from.current}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      return;
                    }

                    from.current = e.target.value;
                    render();
                  }}
                />
              </div>
            </div>

            <div>
              <div>
                <small>
                  <strong>To</strong>
                </small>
              </div>
              <div>
                <input
                  type="date"
                  className="form-control form-control-sm"
                  defaultValue={to.current}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      return;
                    }

                    to.current = e.target.value;
                    render();
                  }}
                />
              </div>
            </div>
          </div>

          <div className="d-flex">
            <div>
              <div>
                <small>
                  <strong>Status</strong>
                </small>
              </div>
              <div style={{ width: 150 }}>
                {" "}
                <Select
                  options={[
                    { label: "Not Approved", value: 0 },
                    { label: "Approved", value: 1 },
                    { label: "All", value: null },
                  ]}
                  // getOptionLabel={(j) => `${j.name}`}
                  placeholder="Select status... "
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  value={[
                    { label: "Not Approved", value: 0 },
                    { label: "Approved", value: 1 },
                    { label: "All", value: null },
                  ].find((d) => d.value === selectedStatus.current)}
                  onChange={(v) => {
                    const val = v as { value: number | null };
                    selectedStatus.current = val.value;
                    render();
                  }}
                />
              </div>
            </div>
          </div>

          <div className="mx-2">
            <div>
              <small>
                <strong>Search by MR ID</strong>
              </small>
            </div>

            <div>
              <input
                className="form-control form-control-sm"
                placeholder="MR ID..."
                onBlur={(e) => {
                  selectedId.current = e.target.value;
                  render();

                  fetchMaterialRequestsData();
                }}
              />
            </div>
          </div>
        </div>

        <div
          className="overflow-auto border border-secondary shadow shadow-md"
          style={{ height: "75vh", resize: "vertical" }}
        >
          <table
            className="table table-sm table-striped table-bordered table-hover"
            style={{ borderCollapse: "separate" }}
          >
            <tr>
              {[
                "#",
                "Name",
                "Job",
                "MR number",
                "MR number (manual)",
                "BOM Item Type",
                "Date",
                "Type",
                "Status",
                "Select",
                "Cancellation Reason",
                "Requested by",
                "Created",
                "Updated",
              ].map((h) => (
                <th
                  className="border border-secondary text-light bg-secondary sticky-top top-0"
                  style={{ zIndex: 1 }}
                >
                  {h}
                </th>
              ))}
            </tr>
            {materialRequests
              .filter((m) =>
                selectedStatus.current !== null
                  ? m.status === selectedStatus.current
                  : true
              )
              ?.map((mr, i) => {
                return (
                  <>
                    <tr>
                      <td className="border border-dark">{i + 1}</td>
                      <td className="border border-dark">
                        <div style={{ width: 300, wordWrap: "break-word" }}>
                          {mr?.name}
                        </div>{" "}
                      </td>
                      <td className="border border-dark">
                        {
                          jobs.jobs.find(
                            (j) =>
                              `${j.masterJavaBaseModel?.id}` === `${mr.jobId}`
                          )?.name
                        }
                      </td>
                      <td className="border border-dark">
                        MR/PPIC/
                        {(() => {
                          try {
                            return new Date(mr.createdAt ?? "").getFullYear();
                          } catch (e) {
                            return 0;
                          }
                        })()}
                        /{mr?.id}{" "}
                      </td>

                      <td className="border border-dark">
                        {mr?.materialRequestNumber}{" "}
                      </td>

                      <td className="border border-dark">
                        {[
                          ...new Set(
                            mr.materialRequestItems
                              ?.filter((i) => i.bomItemType)
                              .map((i) =>
                                bomItemTypeToJSON(
                                  bomItemTypeFromJSON(i.bomItemType)
                                )
                              )
                          ),
                        ].join(", ")}
                      </td>

                      <td className="border border-dark">
                        {mr?.neededDate ? (
                          (() => {
                            try {
                              return Intl.DateTimeFormat(
                                navigator.language ?? "en-US",
                                { dateStyle: "long" } as any
                              ).format(new Date(mr.neededDate));
                            } catch (e) {
                              console.log(e);
                              return <></>;
                            }
                          })()
                        ) : (
                          <></>
                        )}
                      </td>
                      <td className="border border-dark">{mr.type}</td>
                      <td
                        className={`border border-dark  text-light font-weight-bold ${(() => {
                          switch (mr.status) {
                            case 0:
                              return `bg-danger`;
                            case 1:
                              return `bg-success`;
                            case 2:
                              return `bg-info`;
                            default:
                              return ``;
                          }
                        })()}`}
                      >
                        {(() => {
                          switch (mr.status) {
                            case 0:
                              return `Not Approved`;
                            case 1:
                              return `Approved`;
                            case 2:
                              return `CutStock`;
                            default:
                              return ``;
                          }
                        })()}
                      </td>
                      <td className="border border-dark">
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={(e) => {
                            console.log("click selected mr", mr);
                            setSelectedMr(mr);

                            handleFetchMRAuthorizationWO(mr);
                          }}
                        >
                          Details
                        </button>
                      </td>
                      <td className="border border-dark">
                        {mr.cancellationNote ?? ""}
                      </td>
                      <td className="border border-dark">
                        {mr.extRequestedById ? (
                          <>
                            {
                              ctx?.extUsers.find(
                                (u) => `${u.id}` === `${mr.extRequestedById}`
                              )?.username
                            }{" "}
                            (
                            {
                              ctx?.extDepartments.find(
                                (d) =>
                                  `${d.id}` ===
                                  `${
                                    ctx?.extUsers.find(
                                      (u) =>
                                        `${u.id}` === `${mr.extRequestedById}`
                                    )?.departmentId
                                  }`
                              )?.name
                            }
                            )
                          </>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td className="border border-dark">
                        {(() => {
                          try {
                            return Intl.DateTimeFormat(
                              navigator.language ?? "en-US",
                              { dateStyle: "short", timeStyle: "short" } as any
                            ).format(new Date(mr.createdAt ?? ""));
                          } catch (e) {
                            return "";
                          }
                        })()}
                      </td>
                      <td className="border border-dark">
                        {(() => {
                          try {
                            return Intl.DateTimeFormat(
                              navigator.language ?? "en-US",
                              { dateStyle: "short", timeStyle: "short" } as any
                            ).format(new Date(mr.updatedAt ?? ""));
                          } catch (e) {
                            return "";
                          }
                        })()}
                      </td>
                    </tr>
                  </>
                );
              })}
          </table>
        </div>
      </div>
      <Modal
        size="xl"
        show={selectedMr !== null}
        onHide={() => {
          setSelectedMr(null);
        }}
      >
        <ModalHeader>
          <div className="d-flex w-100 justify-content-between">
            <h5>MR detail</h5>

            <div className="d-flex">
              <div>
                {loading ? (
                  <>
                    <Spinner animation="border" />
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={async () => {
                        try {
                          const resp = await fetch(
                            `${process.env.REACT_APP_BASE_URL}/materialrequests-save`,
                            {
                              method: "post",
                              headers: {
                                authorization: ctx?.apiKey ?? "",
                                "content-type": "application/json",
                              },
                              body: JSON.stringify(selectedMr),
                            }
                          );
                          setSelectedMr(null);
                          handleInit();
                          setNewMr({ ...initialMaterialRequest, uuid: v4() });
                        } catch (e) {
                          console.error(e);
                        }
                      }}
                    >
                      Save
                    </button>
                    {selectedMr?.status === 0 ? (
                      <>
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={async () => {
                            // if (selectedMr?.status === 1) {
                            //   alert("Approved MR cannot be cancelled.");
                            //   return;
                            // }
                            const promptValue = window.prompt(
                              "Reason for cancellation:"
                            );

                            // console.log("selectedMr", selectedMr);
                            // return;

                            if (
                              promptValue &&
                              promptValue !== "" &&
                              selectedMr
                            ) {
                              try {
                                setLoading(true);
                                const resp = await fetch(
                                  `${process.env.REACT_APP_BASE_URL}/materialrequests-save`,
                                  {
                                    method: "post",
                                    headers: {
                                      "content-type": "application/json",
                                    },
                                    body: JSON.stringify({
                                      ...selectedMr,
                                      hidden: true,
                                      cancellationNote: promptValue,
                                    } as MaterialRequest),
                                  }
                                );
                              } catch (e) {}

                              setSelectedMr(null);
                              handleInit();
                            } else {
                              window.alert("Cancellation failed.");
                            }
                          }}
                        >
                          Cancel MR
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
              <Link
                target="_blank"
                to={`/materialrequestprint/${selectedMr?.id}`}
              >
                <button className="btn btn-primary">
                  <Print />
                  Print{" "}
                </button>
              </Link>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div>
            <small>
              <strong>Name</strong>
            </small>
          </div>
          <div>
            <input
              className="form-control form-control-sm"
              defaultValue={selectedMr?.originalName ?? ""}
              onBlur={(e) => {
                if (selectedMr) {
                  setSelectedMr({ ...selectedMr, name: e.target.value });
                }
              }}
            />
          </div>
          <div>
            For job:{" "}
            {selectedMr?.job?.name ? (
              selectedMr?.job?.name
            ) : (
              <span className="text-danger font-weight-bold">No job</span>
            )}
          </div>
          <div>
            Requested by:{" "}
            {selectedMr?.extRequestedById ? (
              <>
                {
                  ctx?.extUsers.find(
                    (u) => `${u.id}` === `${selectedMr?.extRequestedById}`
                  )?.username
                }{" "}
                (
                {
                  ctx?.extDepartments.find(
                    (d) =>
                      `${d.id}` ===
                      `${
                        ctx?.extUsers.find(
                          (u) => `${u.id}` === `${selectedMr?.extRequestedById}`
                        )?.departmentId
                      }`
                  )?.name
                }
                )
              </>
            ) : (
              <>
                {" "}
                <span className="text-danger font-weight-bold">
                  No requested by
                </span>
              </>
            )}
          </div>
          <div>
            PartNum Category:{" "}
            {
              categoryPartNums.find(
                (c) => `${c.value}` === `${selectedMr?.partNumCategory}`
              )?.label
            }
          </div>

          <div>Type: {selectedMr?.type}</div>
          <div>
            <Select
              options={jobs.jobs ?? []}
              getOptionLabel={(j) => `${j.name}`}
              placeholder="Select job... "
              onChange={(v) => {
                const val = v as unknown as Job;
                if (selectedMr) {
                  setSelectedMr({ ...selectedMr, jobId: val.id, job: val });
                }
              }}
            />
          </div>
          {selectedMr?.partNumCategory === 0 ? (
            <>
              <div>
                <hr className="border border-dark" />
              </div>
              <strong>Authorizations Needed</strong>
              <div>
                <ol>
                  {selectedMr.materialRequestAuthorizations?.map((a) => {
                    const foundUser = ctx?.extUsers.find(
                      (u) => `${u.id}` === `${a.extUserId}`
                    );
                    return (
                      <>
                        <li>
                          <div
                            className={`${
                              mrWO.current?.taskList?.meetingTasks.find((m) =>
                                m.meetingTaskInCharges.find(
                                  (c) => `${c.extUserId}` === `${foundUser?.id}`
                                )
                              )?.extPicCompletedDate
                                ? `text-success`
                                : `text-danger`
                            }`}
                          >
                            {foundUser?.name}
                          </div>
                        </li>
                      </>
                    );
                  })}
                </ol>
              </div>
              {mrWO.current ? (
                <>
                  <div>
                    WO ID: {mrWO.current.taskList?.masterJavaBaseModel?.id},{" "}
                    {intlFormat({
                      date: mrWO.current.taskList?.taskStart,
                      dateStyle: "medium",
                    })}{" "}
                    -{" "}
                    {intlFormat({
                      date: mrWO.current.taskList?.taskEnd,
                      dateStyle: "medium",
                    })}
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <div className="d-flex">
                    <div className="text-danger">No WO Detected </div>
                    <button className="btn btn-sm btn-primary px-1 py-0">
                      Create WO
                    </button>
                  </div>
                </>
              )}

              <div>
                <hr className="border border-dark" />
              </div>
            </>
          ) : (
            <></>
          )}

          <div>
            {selectedMr?.materialRequestItems?.map((mri, i_) => {
              const foundItem = items.find((i) => i.id === mri.extItemId);
              const foundInventory = inventory.find(
                (inv) => inv.id === mri.extInventoryId
              );

              const foundCode = jobs.jobs
                .flatMap((j) => j.panelCodes)
                .find(
                  (c) =>
                    mri.extPanelCodeId &&
                    `${c.masterJavaBaseModel?.id}` === `${mri.extPanelCodeId}`
                );

              return (
                <>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <div>
                        {loadingItems ? (
                          <>
                            <Spinner animation="border" />{" "}
                          </>
                        ) : (
                          <>
                            <strong>{`#${i_ + 1} (${foundItem?.partNum} ${
                              foundItem?.mfr
                            }) ${foundItem?.partName} - ${
                              foundItem?.partDesc
                            }`}</strong>{" "}
                          </>
                        )}
                      </div>
                      <div>
                        {loadingInventory ? (
                          <>
                            <div className="d-flex">
                              <Spinner animation="border" />
                              Loading inventory...
                            </div>
                          </>
                        ) : (
                          <>
                            WH: {foundInventory?.warehouse?.warehouse?.name}.
                            {foundInventory?.warehouse?.whName}
                          </>
                        )}
                      </div>
                      <div>
                        {mri.extReserveListId && mri.extReserveListId !== 0 ? (
                          <>
                            <div className="text-success">
                              <small className="font-weight-bold">
                                Reserve OK: {mri.extReserveListId} ( x
                                {
                                  reserveList.find(
                                    (r) => r.id === mri.extReserveListId
                                  )?.qtyReserve
                                }
                                )
                              </small>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="text-danger">
                              <small className="font-weight-bold">
                                No reserve list{" "}
                              </small>
                            </div>
                          </>
                        )}
                      </div>
                      <div>
                        Product:{" "}
                        {foundCode ? (
                          <>
                            <strong className="text-success">
                              {foundCode.type}:{foundCode.name}
                            </strong>
                          </>
                        ) : (
                          <>
                            {" "}
                            <strong className="text-danger">None</strong>
                          </>
                        )}
                      </div>
                      <div>
                        BOM Item type:{" "}
                        {mri.bomItemType ? (
                          <>
                            <strong className="text-success">
                              {bomItemTypeToJSON(
                                bomItemTypeFromJSON(mri.bomItemType)
                              )}
                            </strong>
                          </>
                        ) : (
                          <>
                            {" "}
                            <strong className="text-danger">None</strong>
                          </>
                        )}
                      </div>
                    </div>

                    <div>
                      <input
                        className="form-control form-control-sm"
                        placeholder="Qty..."
                        style={{ width: 75 }}
                        key={`qinp-${i_}`}
                        defaultValue={mri.qty}
                        onBlur={(e) => {
                          if (selectedMr) {
                            setSelectedMr({
                              ...selectedMr,
                              materialRequestItems:
                                selectedMr.materialRequestItems
                                  ? selectedMr.materialRequestItems.map(
                                      (mrix, ix_) =>
                                        ix_ === i_
                                          ? {
                                              ...mrix,
                                              qty: isNaN(
                                                parseInt(e.target.value)
                                              )
                                                ? mrix.qty
                                                : parseInt(e.target.value),
                                            }
                                          : mrix
                                    )
                                  : selectedMr.materialRequestItems,
                            });
                          }
                        }}
                      />
                      {/* <div className="d-flex justify-content-end font-weight-bold">
                        
                      </div> */}
                      <div className="d-flex justify-content-end font-weight-bold">
                        {foundItem?.defaultUm}
                      </div>
                    </div>
                  </div>
                  <hr className="border border-secondary" />
                </>
              );
            })}
          </div>
        </ModalBody>
      </Modal>

      <Modal
        // size="xl"
        dialogClassName="modal-xxl"
        show={addNewAutoMR}
        onHide={() => {
          setAddNewAutoMR(false);
        }}
      >
        <ModalHeader>AutoMR</ModalHeader>
        <ModalBody>
          <div className="d-flex">
            {materialTypes.map((m) => {
              return (
                <>
                  <div className="mx-2">
                    <button
                      onClick={async () => {
                        if (
                          selectedMaterialTypes.current.find(
                            (mx) =>
                              bomItemTypeFromJSON(mx) === bomItemTypeToJSON(m)
                          )
                        ) {
                          selectedMaterialTypes.current =
                            selectedMaterialTypes.current.filter((mx) => {
                              return (
                                bomItemTypeFromJSON(mx) !== bomItemTypeToJSON(m)
                              );
                            });
                        } else {
                          selectedMaterialTypes.current.push(m);
                        }

                        bomLeveledsFetchLoading.current = true;
                        render();
                        // Refetch bom
                        const jobDetail =
                          await fetchBomLeveledsProtoDetailedJob({
                            apiKey: ctx?.apiKey ?? "",
                            jobId: selectedAutoMRJobId.current,
                            includePrice: false,
                            types: selectedMaterialTypes.current,
                            includedPanelCodes: [
                              selectedAutoMRPanelCodeId.current
                                ? selectedAutoMRPanelCodeId.current
                                : null,
                            ],
                          });

                        if (jobDetail) {
                          console.log("found job", jobDetail);
                          bomLeveledAutoMR.current = jobDetail;

                          refreshBomParsedInfo();
                        }
                        bomLeveledsFetchLoading.current = false;

                        render();
                      }}
                      className={`btn btn-sm ${
                        selectedMaterialTypes.current.find(
                          (mx) =>
                            bomItemTypeFromJSON(mx) === bomItemTypeToJSON(m)
                        )
                          ? `btn-primary`
                          : `btn-outline-primary`
                      }`}
                    >
                      {bomItemTypeToJSON(m)}
                    </button>
                  </div>
                </>
              );
            })}

            {bomLeveledsFetchLoading.current ? (
              <>
                <div className="spinner-border spinner-border-sm"></div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div>
            <Select
              placeholder="Job..."
              options={jobs.jobs.map((j) => ({ label: j.name, value: j }))}
              onChange={async (j) => {
                const val = j as { value: PpicJob };

                bomLeveledsFetchLoading.current = true;
                render();

                const jobDetail = await fetchBomLeveledsProtoDetailedJob({
                  apiKey: ctx?.apiKey ?? "",
                  jobId: val.value.masterJavaBaseModel?.id,
                  includePrice: false,
                  types: selectedMaterialTypes.current,
                });
                handleGetMRByJob({ jobId: val.value.masterJavaBaseModel?.id });

                selectedAutoMRJobId.current = val.value.masterJavaBaseModel?.id
                  ? isNaN(parseInt(val.value.masterJavaBaseModel?.id ?? ""))
                    ? null
                    : parseInt(val.value.masterJavaBaseModel?.id)
                  : null;

                // selectedAutoMRPanelCodeIds.current = val.value.panelCodes.map(
                //   (c) => c.masterJavaBaseModel?.id
                // );

                fetchPrItemsData({ jobId: val.value.masterJavaBaseModel?.id });

                bomLeveledsFetchLoading.current = false;

                render();

                if (jobDetail) {
                  console.log("found job", jobDetail);
                  bomLeveledAutoMR.current = jobDetail;

                  refreshBomParsedInfo();

                  render();
                  // jobDetail.job.jobBomLeveleds?.map(b=>{
                  // })
                }
              }}
            />
          </div>
          {selectedAutoMRJobId.current ? (
            <>
              <div className="d-flex">
                {autoMRRegularBulkTypes.map((t) => {
                  return (
                    <>
                      <button
                        onClick={() => {
                          if (
                            t === "Bulk" &&
                            selectedAutoMRPanelCodeId.current
                          ) {
                            alert(
                              "Please remove product before selecting bulk."
                            );
                            return;
                          }

                          selectedAutoMRRegularBulkType.current = t;
                          render();
                        }}
                        className={`btn btn-sm ${
                          selectedAutoMRRegularBulkType.current === t
                            ? `btn-primary`
                            : `btn-outline-primary`
                        }`}
                      >
                        {t}
                      </button>
                    </>
                  );
                })}
              </div>

              {selectedAutoMRRegularBulkType.current === "Bulk" ? (
                <>
                  {" "}
                  <div>
                    <div>
                      <strong>Products</strong>
                    </div>
                    <div>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          if (selectedAutoMRPanelCodeIds.current.length > 0) {
                            selectedAutoMRPanelCodeIds.current = [];
                          } else {
                            selectedAutoMRPanelCodeIds.current =
                              jobs.jobs
                                .find(
                                  (j) =>
                                    `${j.masterJavaBaseModel?.id}` ===
                                    `${selectedAutoMRJobId.current}`
                                )
                                ?.panelCodes.map(
                                  (c) => c.masterJavaBaseModel?.id
                                ) ?? [];
                          }

                          render();
                        }}
                      >
                        Toggle All
                      </button>
                    </div>

                    <div>
                      {jobs.jobs
                        .filter(
                          (j) =>
                            `${j.masterJavaBaseModel?.id}` ===
                            `${selectedAutoMRJobId.current}`
                        )
                        .flatMap((j) => j.panelCodes)
                        .map((c, i) => {
                          return (
                            <>
                              <div
                                className="d-flex"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (
                                    selectedAutoMRPanelCodeIds.current.find(
                                      (id) =>
                                        `${id}` ===
                                        `${c.masterJavaBaseModel?.id}`
                                    )
                                  ) {
                                    selectedAutoMRPanelCodeIds.current =
                                      selectedAutoMRPanelCodeIds.current.filter(
                                        (id) =>
                                          `${id}` !==
                                          `${c.masterJavaBaseModel?.id}`
                                      );
                                  } else {
                                    selectedAutoMRPanelCodeIds.current.push(
                                      c.masterJavaBaseModel?.id
                                    );
                                  }
                                  render();
                                }}
                              >
                                <div>
                                  <input
                                    type="checkbox"
                                    checked={
                                      selectedAutoMRPanelCodeIds.current.find(
                                        (i) =>
                                          `${i}` ===
                                          `${c.masterJavaBaseModel?.id}`
                                      )
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                                <div>
                                  {c.type}:{c.name} ({c.qty})
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <Select
                      placeholder="Product..."
                      options={jobs.jobs
                        .filter(
                          (j) =>
                            `${j.masterJavaBaseModel?.id}` ===
                            `${selectedAutoMRJobId.current}`
                        )
                        .flatMap((j) => j.panelCodes)
                        .map((c) => ({
                          label: `${c?.type}:${c?.name}:${c.qty}`,
                          value: c,
                        }))}
                      onChange={async (c) => {
                        const val = c as { value: PpicPanelCode };

                        bomLeveledsFetchLoading.current = true;
                        render();

                        const jobDetail =
                          await fetchBomLeveledsProtoDetailedJob({
                            apiKey: ctx?.apiKey ?? "",
                            jobId: selectedAutoMRJobId.current,
                            includePrice: false,
                            types: selectedMaterialTypes.current,
                            includedPanelCodes: [
                              val.value.masterJavaBaseModel?.id
                                ? isNaN(
                                    parseInt(
                                      val.value.masterJavaBaseModel?.id ?? ""
                                    )
                                  )
                                  ? null
                                  : parseInt(
                                      val.value.masterJavaBaseModel?.id ?? ""
                                    )
                                : null,
                            ],
                          });

                        selectedAutoMRPanelCodeId.current = val.value
                          .masterJavaBaseModel?.id
                          ? isNaN(
                              parseInt(val.value.masterJavaBaseModel?.id ?? "")
                            )
                            ? null
                            : parseInt(val.value.masterJavaBaseModel?.id)
                          : null;

                        bomLeveledsFetchLoading.current = false;
                        render();

                        if (jobDetail) {
                          console.log("found job", jobDetail);
                          bomLeveledAutoMR.current = jobDetail;

                          refreshBomParsedInfo();

                          render();
                          // jobDetail.job.jobBomLeveleds?.map(b=>{
                          // })
                        }
                      }}
                    />
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}

          <div>Request By</div>
          <Select
            placeholder="Select request by.."
            options={ctx?.extUsers.map((u) => ({
              label: `${u.username}: ${
                ctx.extDepartments.find(
                  (d) => `${d.id}` === `${u.departmentId}`
                )?.name
              }`,
              value: u,
            }))}
            onChange={(v) => {
              const val = v as { value: ExtUser };

              autoMRRequestedById.current = val.value.id;
              render();
            }}
          />

          <div className="d-flex justify-content-between">
            <div>Default warehouse: {defaultWarehouse?.name}</div>
            <div className="d-flex">
              <div>Show PR only?</div>
              <div>
                <input
                  type="checkbox"
                  onClick={() => {
                    selectPrLeftOnly.current = !selectPrLeftOnly.current;
                    render();
                  }}
                />
              </div>
            </div>
          </div>

          {/* {selectedAutoMRRegularBulkType.current === "Bulk" ? (
            <>
              {" "}
              <div className="d-flex">
                Category Part Num:
                {categoryPartNumsMR.map((c) => {
                  return (
                    <>
                      <div className="">
                        <button
                          className={`btn btn-sm  ${
                            selectedCategoryPartNumsMR.current == c.value
                              ? `btn-primary`
                              : `btn-outline-primary`
                          }`}
                          onClick={() => {
                            selectedCategoryPartNumsMR.current = c.value;

                            render();
                          }}
                        >
                          {c.label}
                        </button>
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          ) : (
            <></>
          )} */}

          <div
            className="overflow-auto border border-dark"
            style={{ height: "35vh", resize: "vertical" }}
          >
            <table
              className="table table-sm"
              style={{ borderCollapse: "separate" }}
            >
              <tr>
                {[
                  "#",
                  "PN",
                  // "Warehouse",
                  "Material Type",
                  "PN Category",
                  "Qty in MR",
                  "Qty Need",
                  "Qty Remaining",
                  "Free Stock",
                  "Qty MR",
                  "Included?",
                  "MR Done",
                  <>
                    <div>
                      <div>Bulk MR All</div>
                      {selectedAutoMRPanelCodeIds.current.length > 0 &&
                      selectedAutoMRRegularBulkType.current === "Bulk" ? (
                        <>
                          <div>
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                // if (!window.confirm("Bulk all add?")) {
                                //   return;
                                // }

                                handleBulkAdd({
                                  bomParsedInfoTypes: bomParsedInfo.current,
                                });
                              }}
                            >
                              Bulk Add
                            </button>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>,
                  "PR left",
                  "in PR Qty",
                ].map((h) => {
                  return (
                    <>
                      <th
                        className="bg-dark text-light p-0 m-0"
                        style={{
                          position: "sticky",
                          top: 0,
                          cursor: h === "Included?" ? `pointer` : ``,
                        }}
                        onClick={() => {
                          if (h === "Included?") {
                            if (bomParsedInfo.current.find((i) => i.included)) {
                              bomParsedInfo.current.forEach((i) => {
                                i.included = false;
                              });
                            } else {
                              bomParsedInfo.current.forEach((i) => {
                                i.included = true;
                              });
                            }

                            render();
                          }
                        }}
                      >
                        {h}
                      </th>
                    </>
                  );
                })}
              </tr>
              {bomParsedInfo.current
                .filter((b, i) => {
                  return selectPrLeftOnly.current ? b.qtyPr > 0 : true;
                })
                .map((b, i) => {
                  const foundExpanded = expandedMR.current.find(
                    (e) => `${e.extItemId}` === `${b.bomLeveled.extItemId}`
                  );

                  const foundItem = items.find(
                    (i) => `${i.id}` === `${b.bomLeveled.extItemId}`
                  );
                  const foundCPN = categoryPartNums.find(
                    (c) => `${c.value}` === `${foundItem?.categoryPartNum}`
                  );
                  return (
                    <>
                      <tr
                        style={{
                          backgroundColor:
                            foundCPN?.label === "Consumable" ||
                            foundCPN?.label === "Supply"
                              ? `lightsalmon`
                              : b.included
                              ? ``
                              : `grey`,
                        }}
                      >
                        <td className="border border-dark p-0 m-0">{i + 1}</td>
                        <td className="border border-dark p-0 m-0">
                          {loadingItems ? (
                            <>
                              <div className="spinner-border spinner-border-sm" />{" "}
                            </>
                          ) : (
                            getItemFullDescription(foundItem)
                          )}
                        </td>
                        {/* <td className="border border-dark p-0 m-0">
                          {b.warehouse?.warehouse?.name}.{b.warehouse?.whName}
                        </td> */}
                        <td className="border border-dark p-0 m-0">
                          {b.bomLeveled.materialType}
                        </td>
                        <td className="border border-dark p-0 m-0">
                          {foundCPN?.label}
                        </td>

                        <td className="border border-dark p-0 m-0">
                          {Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 0,
                          }).format(b.bomLeveled.inMr ?? 0)}
                        </td>
                        <td className="border border-dark p-0 m-0">
                          {Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 0,
                          }).format(b.bomLeveled.qty ?? 0)}
                        </td>

                        <td
                          className="border border-dark p-0 m-0"
                          style={{
                            backgroundColor: chroma
                              .scale(["lightsalmon", "yellow", "lightgreen"])(
                                (b.bomLeveled.inMr ?? 0) /
                                  (b.bomLeveled.qty ?? 1)
                              )
                              .hex(),
                          }}
                        >
                          <strong>
                            {Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 0,
                            }).format(
                              b.qtyRemaining === 0
                                ? b.qtyRemaining
                                : (b.qtyRemaining ?? 0) * -1
                            )}
                          </strong>
                        </td>
                        <td className="border border-dark p-0 m-0">
                          {Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 0,
                          }).format(b.balanceRsv)}
                        </td>
                        <td className="border border-dark p-0 m-0">
                          <div className="d-flex">
                            <input
                              className="form-control form-control-sm mx-1"
                              // defaultValue={b.qtyMr}
                              placeholder="Qty.."
                              key={`bomx-${i}`}
                              style={{ width: 50 }}
                              onBlur={(e) => {
                                if (!isNaN(parseFloat(e.target.value))) {
                                  b.qtyMr = parseFloat(e.target.value);
                                  render();
                                }
                              }}
                            />
                            {Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 0,
                            }).format(b.qtyMr)}
                          </div>
                        </td>
                        <td className="border border-dark p-0 m-0">
                          <input
                            type="checkbox"
                            checked={b.included}
                            onClick={() => {
                              b.included = !b.included;
                              render();
                            }}
                          />
                        </td>
                        <td className="border border-dark p-0 m-0">
                          <div>
                            <div>
                              <button
                                className="btn btn-sm btn-primary px-1 py-0"
                                onClick={() => {
                                  handleGetMRByJobProduct({
                                    extItemId: b.bomLeveled.extItemId,
                                    jobId: selectedAutoMRJobId.current,
                                  });
                                }}
                              >
                                <ArrowDownward />{" "}
                              </button>
                            </div>
                            <div>
                              {foundExpanded ? (
                                <>
                                  <div className="bg-light px-1 py-0">
                                    <ol>
                                      {foundExpanded.mrs.map((m) => {
                                        return (
                                          <>
                                            <li>
                                              <div>
                                                <div>
                                                  <small>
                                                    MR/PPIC/
                                                    {new Date(
                                                      m.createdAt ?? ""
                                                    ).getFullYear()}
                                                    /{m.id} (
                                                    {intlFormat({
                                                      date: m.neededDate,
                                                      dateStyle: "medium",
                                                    })}
                                                    )
                                                  </small>
                                                </div>
                                              </div>
                                            </li>
                                          </>
                                        );
                                      })}
                                    </ol>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="border border-dark p-0 m-0">
                          {selectedAutoMRPanelCodeIds.current.length > 0 &&
                          selectedAutoMRRegularBulkType.current === "Bulk" ? (
                            <>
                              {" "}
                              <button
                                className="btn btn-sm btn-primary px-1 py-0"
                                onClick={async () => {
                                  handleBulkAdd({ bomParsedInfoTypes: [b] });
                                }}
                              >
                                Bulk Add
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td className="border border-dark p-0 m-0">
                          <div className="d-flex">
                            <input
                              style={{ width: 50 }}
                              className="form-control form-control-sm"
                              onBlur={(e) => {
                                if (!isNaN(parseFloat(e.target.value))) {
                                  b.qtyPr = parseFloat(e.target.value);
                                  render();
                                }
                              }}
                            />{" "}
                            <div className="bg-light px-1">
                              <strong>{b.qtyPr}</strong>
                            </div>
                          </div>
                        </td>
                        <td className="border border-dark p-0 m-0">
                          <div className="bg-light px-1">
                            <strong>{b.inPRQty}</strong>
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })}
            </table>
          </div>

          {/* <div className="d-flex">
            <div>
              <small>
                <strong>To MR Detail</strong>
              </small>
            </div>
            <div>
              <button className="btn btn-sm btn-primary" onClick={() => {}}>
                Expand/collapse
              </button>
            </div>
          </div> */}

          <ol>
            {jobs.jobs
              .find(
                (j) =>
                  `${j.masterJavaBaseModel?.id}` ===
                  `${selectedAutoMRJobId.current}`
              )
              ?.panelCodes.filter(
                (c) =>
                  bulkMRPanelCodes.current.find(
                    (cx) =>
                      `${cx.panelCodeId}` === `${c.masterJavaBaseModel?.id}`
                  )?.loading
              )
              .map((c) => {
                return (
                  <>
                    <li>
                      <div>
                        <div>
                          {c.type}:{c.name}({c.qty}) Loading...
                        </div>
                      </div>
                    </li>
                  </>
                );
              })}
          </ol>

          {/* <div>{mrBulk.current.length}</div> */}

          <div>
            <button
              className="btn btn-smb btn-primary"
              onClick={() => {
                showFinalMRResult.current = !showFinalMRResult.current;
                render();
              }}
            >
              Show/Hide Final MR Result...
            </button>
          </div>
          <div>
            MR Final items: {mrFinal.current.length} for{" "}
            {
              [...new Set(mrFinal.current.map((f) => f.data.extPanelCodeId))]
                .length
            }{" "}
            products.
          </div>
          {showFinalMRResult.current ? (
            <>
              <div>
                <table
                  className="table table-sm"
                  style={{ borderCollapse: "separate" }}
                >
                  <tr>
                    {[
                      "#",
                      "MFR",
                      "PN",
                      "Name",
                      "Desc",
                      "Qty Edit",
                      "Qty",
                      "Type",
                      "Product",
                      "Included",
                    ].map((h) => {
                      return (
                        <>
                          <th className="bg-dark text-light p-0 m-0">{h}</th>
                        </>
                      );
                    })}
                  </tr>
                  {mrFinal.current.map((p, i) => {
                    const foundItem = items.find(
                      (ix) => `${ix.id}` === `${p.data.inventory?.productId}`
                    );

                    const foundCPN = categoryPartNums.find(
                      (c) => `${c.value}` === `${foundItem?.categoryPartNum}`
                    );

                    const supplyConsumableCond =
                      foundCPN?.label !== "Supply" &&
                      foundCPN?.label !== "Consumable";

                    const panelCodeId =
                      // If one
                      selectedMaterialTypes.current.length === 1 &&
                      selectedAutoMRPanelCodeId.current
                        ? selectedAutoMRPanelCodeId.current
                        : // If bulk
                          p.data.extPanelCodeId;

                    const pCode = jobs.jobs
                      .flatMap((j) => j.panelCodes)
                      .find(
                        (c) =>
                          `${c.masterJavaBaseModel?.id}` === `${panelCodeId}`
                      );

                    return (
                      <>
                        <tr
                          style={{
                            backgroundColor: supplyConsumableCond
                              ? undefined
                              : `lightsalmon`,
                          }}
                        >
                          <td className="border border-dark p-0 m-0">
                            {i + 1}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {foundItem?.mfr}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {foundItem?.partNum}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {foundItem?.partName}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {foundItem?.partDesc}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            <input
                              placeholder="Qty..."
                              style={{ width: 75 }}
                              onBlur={(e) => {
                                if (!isNaN(parseFloat(e.target.value))) {
                                  p.data.qtyMr = parseFloat(e.target.value);
                                  render();
                                }
                              }}
                            />
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {p.data.qtyMr}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {p.type}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {pCode?.type}:{pCode?.name} ({pCode?.qty})
                          </td>
                          <td
                            className={`border border-dark p-0 m-0 text-light ${
                              p.data.included ? `bg-success` : `bg-danger`
                            }`}
                          >
                            {p.data.included ? "Yes" : "No"}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </table>
              </div>
            </>
          ) : (
            <></>
          )}
        </ModalBody>
        <ModalFooter>
          {autoMrLoading.current ? (
            <>
              <div className="spinner-border spinner-border-sm"></div>
            </>
          ) : (
            <>
              <div className="d-flex">
                <div className="mx-1">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={async () => {
                      if (
                        !window.confirm(
                          `Create PR?\n${bomParsedInfo.current
                            .filter((b) => b.qtyPr > 0)
                            .map((b) => {
                              const foundItem = items.find(
                                (ix) =>
                                  `${ix.id}` === `${b.bomLeveled.extItemId}`
                              );

                              return `- ${foundItem?.partNum}: ${foundItem?.partName} x${b.qtyPr}\n`;
                            })
                            .join("")}`
                        )
                      ) {
                        return;
                      }

                      handleSavePR();
                    }}
                  >
                    Create PR
                  </button>
                </div>
                <div className="mx-1">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={async () => {
                      // if MR bulk
                      const mrBulkCond = (mrBulk.current.length ?? 0) > 0;
                      // if MR nonbulk
                      const mrRegularCond =
                        selectedMaterialTypes.current.length === 1 &&
                        selectedAutoMRPanelCodeId.current;

                      const foundJob = jobs.jobs.find(
                        (j) =>
                          `${j.masterJavaBaseModel?.id}` ===
                          `${selectedAutoMRJobId.current}`
                      );

                      if (mrBulkCond && mrRegularCond) {
                        alert("Only one AutoMR type at once (BULK or REGULAR)");
                        return;
                      }

                      // if (mrBulkCond && !selectedCategoryPartNumsMR.current) {
                      //   alert("BULK MR: Category part num must be selected.");
                      // }

                      // Double check for exceeding values
                      const usedItemsExceeding = (() => {
                        if (mrBulkCond) {
                          return mrBulk.current;
                        } else if (mrRegularCond) {
                          return bomParsedInfo.current;
                        } else {
                          return [];
                        }
                      })()
                        .map((d) => {
                          const foundInventory = inventory.find(
                            (inv) => `${inv.id}` === `${d.inventory?.id}`
                          );

                          return { data: d, inStock: foundInventory };
                        })
                        .filter(
                          (d) =>
                            (d.data.qtyMr ?? 0) > (d.inStock?.balanceRsv ?? 0)
                        );

                      if (usedItemsExceeding.length > 0) {
                        alert(
                          `Stock not enough.\n${usedItemsExceeding
                            .map(
                              (d, i) =>
                                `${i + 1}. ${
                                  d.inStock?.products?.partNum
                                } | MR: ${d.data.qtyMr}, in stock: ${
                                  d.inStock?.balanceRsv
                                }`
                            )
                            .join("\n")}`
                        );

                        return;
                      }
                      const newMR = (() => {
                        if (mrBulkCond) {
                          console.log("mr bulk curr", mrBulk.current);
                          return {
                            ...initialMaterialRequest,
                            uuid: v4(),
                            name: `AutoMR (Bulk): ${Intl.DateTimeFormat(
                              "en-US",
                              {
                                dateStyle: "medium",
                                timeStyle: "short",
                              }
                            ).format(new Date())}${
                              selectedAutoMRJobId.current
                                ? ` ${foundJob?.name}`
                                : ``
                            }`,
                            isBulk: true,
                            job: {
                              ...initialJob,
                              id: selectedAutoMRJobId.current,
                            },
                            neededDate: makeDateString(new Date()),
                            materialRequestNumber: `AUTOMR-BULK-${makeDateString(
                              new Date()
                            )}T${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
                            type: "PRODUCTION",
                            materialRequestItems: mrBulk.current
                              .filter((p) => {
                                const foundItem = items.find(
                                  (i) =>
                                    `${i.id}` === `${p.inventory?.productId}`
                                );

                                const foundCPN = categoryPartNums.find(
                                  (c) =>
                                    `${c.value}` ===
                                    `${foundItem?.categoryPartNum}`
                                );

                                const supplyConsumableCond =
                                  foundCPN?.label !== "Supply" &&
                                  foundCPN?.label !== "Consumable";

                                return (
                                  p.qtyMr > 0 && p.included
                                  // &&
                                  // supplyConsumableCond
                                );
                              })
                              .map((p) => {
                                return {
                                  ...initialMaterialRequestItem,
                                  uuid: v4(),
                                  extInventoryId: p.inventory?.id,
                                  extItemId: p.inventory?.productId,
                                  qty: p.qtyMr,
                                  extPanelCodeId: p.extPanelCodeId
                                    ? isNaN(parseInt(p.extPanelCodeId ?? ""))
                                      ? null
                                      : parseInt(p.extPanelCodeId ?? "")
                                    : null,
                                  bomItemType: p.bomLeveled.materialType,
                                };
                              }),
                            extRequestedById: autoMRRequestedById.current,
                          } as MaterialRequest;
                        } else {
                          if (selectedMaterialTypes.current.length !== 1) {
                            alert("Selected material type must be exactly 1.");
                            return;
                          }

                          if (!selectedAutoMRPanelCodeId.current) {
                            alert("Panel code must be selected.");
                            return;
                          }

                          if (!window.confirm("Create MR?")) {
                            return;
                          }

                          const foundPanelCode = jobs.jobs
                            .flatMap((j) => j.panelCodes)
                            .find(
                              (c) =>
                                `${c.masterJavaBaseModel?.id}` ===
                                `${selectedAutoMRPanelCodeId.current}`
                            );

                          return {
                            ...initialMaterialRequest,
                            uuid: v4(),
                            name: `AutoMR: ${Intl.DateTimeFormat("en-US", {
                              dateStyle: "medium",
                              timeStyle: "short",
                            }).format(new Date())}${
                              selectedAutoMRJobId.current
                                ? ` ${foundJob?.name}`
                                : ``
                            }${
                              foundPanelCode
                                ? ` ${foundPanelCode.type}:${foundPanelCode.name}`
                                : ``
                            }`,
                            job: {
                              ...initialJob,
                              id: selectedAutoMRJobId.current,
                            },
                            neededDate: makeDateString(new Date()),
                            materialRequestNumber: `AUTOMR-${makeDateString(
                              new Date()
                            )}T${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
                            type: "PRODUCTION",
                            materialRequestItems: bomParsedInfo.current
                              .filter((p) => {
                                const foundItem = items.find(
                                  (i) =>
                                    `${i.id}` === `${p.inventory?.productId}`
                                );

                                const foundCPN = categoryPartNums.find(
                                  (c) =>
                                    `${c.value}` ===
                                    `${foundItem?.categoryPartNum}`
                                );

                                const supplyConsumableCond =
                                  foundCPN?.label !== "Supply" &&
                                  foundCPN?.label !== "Consumable";

                                return (
                                  p.qtyMr > 0 && p.included
                                  // &&
                                  // supplyConsumableCond
                                );
                              })
                              .map((p) => {
                                return {
                                  ...initialMaterialRequestItem,
                                  uuid: v4(),
                                  extInventoryId: p.inventory?.id,
                                  extItemId: p.inventory?.productId,
                                  qty: p.qtyMr,
                                  extPanelCodeId:
                                    selectedAutoMRPanelCodeId.current,
                                  bomItemType: bomItemTypeFromJSON(
                                    selectedMaterialTypes.current?.[0]
                                  ),
                                };
                              }),
                            extRequestedById: autoMRRequestedById.current,
                          } as MaterialRequest;
                        }
                      })();

                      try {
                        autoMrLoading.current = true;
                        render();

                        const resp = await fetch(
                          `${process.env.REACT_APP_BASE_URL}/materialrequests-save`,
                          {
                            method: "post",
                            headers: {
                              "content-type": "application/json",
                            },
                            body: JSON.stringify({
                              ...newMR,
                            }),
                          }
                        );

                        // window.location.reload();
                        fetchMaterialRequestsData();
                        fetchExtInventoryData();
                        setAddNewAutoMR(false);
                        bomParsedInfo.current = [];
                        render();
                      } catch (e) {
                        console.error(e);
                      } finally {
                        autoMrLoading.current = false;
                        render();
                      }
                    }}
                  >
                    Create MR
                  </button>
                </div>

                <div className="mx-1">
                  <a
                    href={`${process.env.REACT_APP_BASE_URL}/automr-report/${
                      selectedAutoMRJobId.current
                    }?includedPanelCodes=${encodeURIComponent(
                      JSON.stringify([
                        selectedAutoMRPanelCodeId.current ?? undefined,
                      ])
                    )}`}
                    target="_blank"
                  >
                    <button className="btn btn-sm btn-success">
                      <CloudDownload /> XLSX
                    </button>
                  </a>
                </div>
              </div>
            </>
          )}
        </ModalFooter>
      </Modal>

      <Modal
        size="xl"
        show={addNewMr}
        onHide={() => {
          setAddNewMr(false);
        }}
      >
        <ModalHeader>
          <div className="d-flex w-100 justify-content-between">
            <h5 className="flex-grow-1">New MR</h5>
            <div className="d-flex flex-grow-1 justify-content-end">
              {!loading ? (
                <button
                  className="btn btn-primary btn-sm"
                  onClick={async () => {
                    try {
                      const categs = [
                        ...new Set(
                          newMr.materialRequestItems?.map((i) => {
                            const foundItem = items.find(
                              (ix) => `${ix.id}` === `${i.id}`
                            );

                            return foundItem?.categoryPartNum ?? "0";
                          })
                        ),
                      ];

                      console.log("categs", categs);

                      if (categs.length > 1) {
                        alert("Must be only 1 type of PartNum Category.");
                        return;
                      }

                      setLoading(true);

                      // Stock validation
                      const mrCheck =
                        newMr.materialRequestItems
                          ?.map((mri) => ({
                            materialRequestItem: mri,
                            inventory: inventory.find(
                              (i) => i.id === mri.extInventoryId
                            ),
                          }))
                          .filter(
                            (mri) =>
                              (mri.inventory?.balanceRsv ?? 0) <= 0 ||
                              (mri.inventory?.balanceRsv ?? 0) <
                                (mri.materialRequestItem.qty ?? 0)
                          ) ?? [];

                      if (!newMr?.job?.id) {
                        alert("Job ID invalid.");
                        return;
                      }
                      if (!newMr?.extRequestedById) {
                        alert("Request by invalid.");
                        return;
                      }

                      if (newMr.partNumCategory === null) {
                        alert("Part Num Category invalid.");
                        return;
                      }

                      // If material & no auth
                      if (
                        newMr?.partNumCategory === 0 &&
                        (newMr.materialRequestAuthorizations?.length ?? 0) === 0
                      ) {
                        alert("Authorizations must be filled.");
                        return;
                      }

                      console.log("mrcheck", mrCheck);

                      // disable filter
                      // if (false) {
                      if (mrCheck.length > 0) {
                        alert(
                          `Stock not enough.\n${mrCheck
                            .map(
                              (mc, i) =>
                                `${i + 1}. ${getItemFullDescription(
                                  items.find(
                                    (i) =>
                                      i.id === mc.materialRequestItem.extItemId
                                  )
                                )} - needed: ${
                                  mc.materialRequestItem.qty
                                }, free stock: ${mc.inventory?.balanceRsv}`
                            )
                            .join("\n")}`
                        );

                        return;
                      }

                      const resp = await fetch(
                        `${process.env.REACT_APP_BASE_URL}/materialrequests-save`,
                        {
                          method: "post",
                          headers: {
                            "content-type": "application/json",
                          },
                          body: JSON.stringify({
                            ...newMr,
                          }),
                        }
                      );

                      // window.location.reload();
                      fetchMaterialRequestsData();
                      fetchExtInventoryData();
                      setAddNewMr(false);
                      setSelectedMr(null);
                      setNewMr({ ...initialMaterialRequest, uuid: v4() });
                    } catch (e) {
                    } finally {
                      setLoading(false);
                    }
                  }}
                >
                  Save
                </button>
              ) : (
                <>Loading...</>
              )}
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div>
            <div>Name</div>
            <input
              className="form-control form-control-sm"
              placeholder="Name...."
              defaultValue={newMr.name ?? ""}
              onBlur={(e) => {
                setNewMr({ ...newMr, name: e.target.value });
              }}
            ></input>
            <div>MR Number</div>
            <input
              className="form-control form-control-sm"
              placeholder="MR number...."
              defaultValue={newMr.materialRequestNumber ?? ""}
              onBlur={(e) => {
                setNewMr({ ...newMr, materialRequestNumber: e.target.value });
              }}
            ></input>
            <div>For Date</div>
            <input
              type="date"
              className="form-control form-control-sm"
              placeholder="MR number...."
              defaultValue={newMr.neededDate ?? undefined}
              onBlur={(e) => {
                if (e.target.value !== "") {
                  setNewMr({ ...newMr, neededDate: e.target.value });
                }
              }}
            ></input>
            <div>For Job</div>
            <Select
              placeholder="Select job.."
              options={jobs.jobs ?? []}
              getOptionLabel={(j) => j.name ?? ""}
              onChange={(j) => {
                const val = j as PpicJob;
                if (val) {
                  setNewMr({
                    ...newMr,
                    job: {
                      ...initialJob,
                      id: isNaN(parseInt(val.masterJavaBaseModel?.id ?? ""))
                        ? null
                        : parseInt(val.masterJavaBaseModel?.id ?? ""),
                    },
                  });

                  // Set mr job manual
                  handleGetMRByJobManual({
                    jobId: val.masterJavaBaseModel?.id,
                  });
                }
              }}
            />
            <div>Request By</div>
            <Select
              placeholder="Select request by.."
              options={ctx?.extUsers.map((u) => ({
                label: `${u.username}: ${
                  ctx.extDepartments.find(
                    (d) => `${d.id}` === `${u.departmentId}`
                  )?.name
                }`,
                value: u,
              }))}
              onChange={(v) => {
                const val = v as { value: ExtUser };

                setNewMr({ ...newMr, extRequestedById: val.value.id });
              }}
            />
            <div>Type</div>
            <div className="d-flex">
              {(
                [
                  "PRODUCTION",
                  "WORKSHOP",
                  "FINISHED_GOODS",
                ] as MaterialRequestType[]
              ).map((h) => {
                return (
                  <>
                    <div>
                      <button
                        className={`btn btn-sm ${
                          h === newMr.type
                            ? `btn-primary`
                            : `btn-outline-primary`
                        }`}
                        onClick={() => {
                          setNewMr({ ...newMr, type: h });
                        }}
                      >
                        {h}
                      </button>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          <div>Part Num Category</div>
          <div>
            <Select
              options={categoryPartNumsMR}
              value={categoryPartNumsMR.find(
                (c) => `${c.value}` === `${newMr.partNumCategory}`
              )}
              onChange={(v) => {
                const val = v as { value: number };
                setNewMr({ ...newMr, partNumCategory: val.value });
              }}
              placeholder="Part num category.."
            />
          </div>

          {/*
          <div>Remark MR Khusus</div> */}
          {/* <div>
            <input
              type="textarea"
              className="form-control form-control-sm"
              onBlur={(e) => {
                setNewMr({
                  ...newMr,
                  uniqueMRRemark: newMr.uniqueMRRemark ?? "",
                });
              }}
            />
          </div> */}

          {/* If material */}
          {newMr.partNumCategory === 0 ? (
            <>
              <div
                className="d-flex"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setNewMr({
                    ...newMr,
                    titipan: !newMr.titipan,
                  });
                }}
              >
                MR Titipan
                <div>
                  <input
                    type="checkbox"
                    // className="form-check-input"
                    checked={newMr.titipan ?? false}
                  />
                </div>
              </div>

              <div>
                <strong>Authorization</strong>
              </div>
              <div>
                <Select
                  placeholder="Select user to authorize.."
                  options={ctx?.extUsers.map((u) => ({
                    label: `${u.username}: ${
                      ctx.extDepartments.find(
                        (d) => `${d.id}` === `${u.departmentId}`
                      )?.name
                    }`,
                    value: u,
                  }))}
                  onChange={(v) => {
                    const val = v as { value: ExtUser };

                    const foundUser = newMr.materialRequestAuthorizations?.find(
                      (a) => `${a.extUserId}` === `${val.value.id}`
                    );

                    if (!foundUser) {
                      setNewMr({
                        ...newMr,
                        materialRequestAuthorizations: [
                          ...(newMr.materialRequestAuthorizations ?? []),
                          {
                            ...initialMaterialRequestAuthorization,
                            uuid: v4(),
                            extUserId: val.value.id,
                          },
                        ],
                      });
                    }

                    render();
                  }}
                />
              </div>
              <ol>
                {newMr.materialRequestAuthorizations?.map((a, i) => {
                  const foundUser = ctx?.extUsers.find(
                    (u) => `${u.id}` === `${a.extUserId}`
                  );
                  return (
                    <>
                      <li>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            newMr.materialRequestAuthorizations =
                              newMr.materialRequestAuthorizations?.filter(
                                (a) => `${a.extUserId}` !== `${foundUser?.id}`
                              ) ?? [];

                            render();
                          }}
                        >
                          {foundUser?.name}
                        </div>
                      </li>
                    </>
                  );
                })}
              </ol>
              <div>
                <hr className="border border-dark" />
              </div>
              <div>
                <strong>
                  Materials available to MR ({mrsByJobManual.current.length}):
                </strong>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-outline-primary"
                  onClick={() => {
                    mrsByJobManualShow.current = !mrsByJobManualShow.current;
                    render();
                  }}
                >
                  Expand/Collapse
                </button>
              </div>
              {mrsByJobManualShow.current ? (
                <>
                  {" "}
                  <div
                    style={{ resize: "vertical", height: "25vh" }}
                    className="overflow-auto"
                  >
                    <table
                      className="table table-sm"
                      style={{ borderCollapse: "separate" }}
                    >
                      <tr>
                        {[
                          "#",
                          "MRID",
                          "Date",
                          "MFR",
                          "PN",
                          "Part Name",
                          "Part Desc",
                          "Qty MR",
                          "WH",
                        ].map((h) => (
                          <>
                            <th
                              className="bg-dark text-light p-0 m-0"
                              style={{ position: "sticky", top: 0 }}
                            >
                              {h}
                            </th>
                          </>
                        ))}
                      </tr>
                      {mrsByJobManual.current
                        .flatMap((m) =>
                          m.materialRequestItems?.map((i) => ({
                            mr: m,
                            item: i,
                          }))
                        )
                        .map((d, i) => {
                          const foundItem = items.find(
                            (ix) => `${ix.id}` === `${d?.item.extItemId}`
                          );
                          const foundInv = inventory.find(
                            (ix) => `${ix.id}` === `${d?.item.extInventoryId}`
                          );

                          return (
                            <>
                              <tr>
                                <td className="border border-dark p-0 m-0">
                                  {i + 1}
                                </td>
                                <td className="border border-dark p-0 m-0">
                                  #{d?.mr.id}
                                </td>
                                <td className="border border-dark p-0 m-0">
                                  {intlFormat({
                                    date: d?.mr.neededDate,
                                    dateStyle: "medium",
                                  })}
                                </td>
                                <td className="border border-dark p-0 m-0">
                                  {loadingItems ? "Loading..." : foundItem?.mfr}
                                </td>
                                <td className="border border-dark p-0 m-0">
                                  {loadingItems
                                    ? "Loading..."
                                    : foundItem?.partNum}
                                </td>
                                <td className="border border-dark p-0 m-0">
                                  {loadingItems
                                    ? "Loading..."
                                    : foundItem?.partName}
                                </td>
                                <td className="border border-dark p-0 m-0">
                                  {loadingItems
                                    ? "Loading..."
                                    : foundItem?.partDesc}
                                </td>
                                <td className="border border-dark p-0 m-0">
                                  {d?.item.qty}
                                </td>
                                <td className="border border-dark p-0 m-0">
                                  {loadingInventory
                                    ? "Loading..."
                                    : foundInv?.warehouse?.warehouse?.name}
                                  :
                                  {loadingInventory
                                    ? "Loading..."
                                    : foundInv?.warehouse?.whName}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </table>
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          <hr className="border border-dark"></hr>
          <div className="d-flex justify-content-between">
            <div>
              <strong>Items</strong>
            </div>
            <div>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  setSelectItem(true);
                }}
              >
                Add
              </button>
            </div>
          </div>
          <div>
            {newMr.materialRequestItems?.map((i, i_) => {
              const foundItem = items.find((ix) => ix.id === i.extItemId);
              const foundInventory = inventory.find(
                (inv) => inv.id === i.extInventoryId
              );
              const foundInventoryStock =
                i.extInventoryId && i.extInventoryId !== 0
                  ? foundInventory?.qty ?? 0
                  : inventory
                      .filter((inv) => inv.productId === i.extItemId)
                      .reduce((acc, inv) => acc + (inv.qty ?? 0), 0);

              const foundInventoryBalanceRsv =
                i.extInventoryId && i.extInventoryId !== 0
                  ? foundInventory?.balanceRsv ?? 0
                  : inventory
                      .filter((inv) => inv.productId === i.extItemId)
                      .reduce((acc, inv) => acc + (inv.balanceRsv ?? 0), 0);

              return (
                <>
                  <div className="d-flex justify-content-between">
                    <div>
                      <div>
                        {i_ + 1}# {getItemFullDescription(foundItem)}
                      </div>
                      <div>
                        <strong>
                          Warehouse:{" "}
                          {foundInventory ? (
                            <>
                              {foundInventory.warehouse?.warehouse?.name}.
                              {foundInventory.warehouse?.whName}
                            </>
                          ) : (
                            <>Not found</>
                          )}
                        </strong>
                      </div>
                      <div>
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => {
                            setNewMr({
                              ...newMr,
                              materialRequestItems:
                                newMr.materialRequestItems?.filter(
                                  (mri) => mri.extItemId !== i.extItemId
                                ) ?? newMr.materialRequestItems,
                            });
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                    <div>
                      <div>
                        <input
                          className="form-control form-control-sm"
                          style={{ maxWidth: 75 }}
                          placeholder="Qty..."
                          onBlur={(e) => {
                            setNewMr({
                              ...newMr,
                              materialRequestItems:
                                newMr.materialRequestItems?.map((mri) =>
                                  mri.extItemId === i.extItemId
                                    ? {
                                        ...mri,
                                        qty: isNaN(parseFloat(e.target.value))
                                          ? mri.qty
                                          : parseFloat(e.target.value),
                                      }
                                    : mri
                                ) ?? [],
                            });
                          }}
                        ></input>
                      </div>
                      <div>
                        {i.qty} {foundItem?.defaultUm}
                      </div>
                      <div>
                        <strong>Real stock: {foundInventoryStock}</strong>
                      </div>
                      <div>
                        <strong>Free stock: {foundInventoryBalanceRsv}</strong>
                      </div>

                      <div>
                        {(() => {
                          const unappr = checkUnapproved(
                            materialRequestsAll,
                            i.extInventoryId
                          );

                          return unappr.qty > 0 ? (
                            <>
                              <div>
                                <div>
                                  <small>
                                    <strong>Unapproved: {unappr.qty}</strong>
                                  </small>
                                </div>
                                <div>
                                  <ol>
                                    {unappr.mrs.map((mr) => {
                                      return (
                                        <>
                                          <li>
                                            <div>
                                              <small>#{mr.id}</small>
                                            </div>
                                          </li>
                                        </>
                                      );
                                    })}
                                  </ol>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          );
                        })()}
                      </div>
                    </div>
                  </div>
                  <hr className="border border-dark" />
                </>
              );
            })}
          </div>
        </ModalBody>
      </Modal>

      <Modal
        show={selectItem}
        onHide={() => {
          setSelectItem(false);
        }}
        // size="xl"

        dialogClassName="modal-xxl"
      >
        <ModalHeader closeButton>
          <h5>Select Item</h5>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex">
            Import XLS...{" "}
            {importing.current ? (
              <>
                <Spinner animation="border" /> Importing MR...
              </>
            ) : (
              <>
                <input
                  type="file"
                  onChange={async (e) => {
                    const f = e.target.files?.[0];

                    if (f) {
                      importing.current = true;
                      render();

                      const r = new FileReader();
                      r.readAsDataURL(f);

                      r.onload = async (e) => {
                        const data = r.result;
                        try {
                          const resp = await fetch(
                            `${process.env.REACT_APP_BASE_URL}/material-requests-xls-import`,
                            {
                              method: "post",
                              headers: { authorization: ctx?.apiKey ?? "" },
                              body: JSON.stringify({
                                data: (data as string).split(";base64,")?.[1],
                              }),
                            }
                          );

                          if (resp.status !== 200) {
                            throw await resp.text();
                          }

                          const mrItems =
                            (await resp.json()) as MaterialRequestItem[];

                          const mrItemFailure = mrItems.filter(
                            (i) =>
                              i.extInventoryId === null ||
                              i.extInventoryId === undefined
                          );
                          const mrItemSuccess = mrItems.filter(
                            (i) =>
                              i.extInventoryId !== null &&
                              i.extInventoryId !== undefined
                          );

                          // Disable filter
                          // if (false) {
                          if (mrItemFailure.length > 0) {
                            alert(
                              `MR Item not detected PN or stock not enough:\n${mrItemFailure
                                .map((i, i_) => `${i_ + 1}. ${i.name}`)
                                .join(`\n`)}`
                            );
                          }

                          if (newMr) {
                            setNewMr({
                              ...newMr,
                              materialRequestItems: [
                                ...(newMr.materialRequestItems ?? []),
                                ...mrItemSuccess,
                                // Disable filter
                                ...mrItemFailure,
                              ],
                            });
                            mrItemFailureData.current = mrItemFailure;
                            render();
                          }
                        } catch (e) {
                          console.error(e);
                        } finally {
                          importing.current = false;
                          render();
                        }
                      };

                      r.onerror = (e) => {};
                    }
                  }}
                />
              </>
            )}
          </div>
          <div>
            <strong>Filter Item (PN,name,desc,etc..)</strong>
          </div>
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                document.getElementById("form-item-search")?.blur();
              }}
            >
              <input
                defaultValue={searchItem}
                className="form-control form-control-sm"
                placeholder="Search by PN, name, desc, mfr...."
                onBlur={(e) => {
                  setSearchItem(e.target.value);
                }}
                id="form-item-search"
              ></input>
            </form>
          </div>

          <div>
            <strong>Filter Warehouse</strong>
          </div>

          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                document.getElementById("form-wh-search")?.blur();
              }}
            >
              <input
                defaultValue={searchWarehouse}
                className="form-control form-control-sm"
                placeholder="Search by warehouse...."
                onBlur={(e) => {
                  setSearchWarehouse(e.target.value);
                }}
                id="form-wh-search"
              ></input>
            </form>
          </div>

          {newMr.partNumCategory !== null ? (
            <>
              <div>
                Selected category:{" "}
                <strong>
                  {
                    categoryPartNums.find(
                      (c) => `${c.value}` === `${newMr.partNumCategory}`
                    )?.label
                  }
                </strong>
              </div>
            </>
          ) : (
            <></>
          )}

          {loadingItems || loadingInventory ? (
            <>
              <Spinner animation="border" />
            </>
          ) : (
            <>
              <div
                className="border border-dark overflow-auto p-3"
                style={{ height: "50vh", resize: "vertical" }}
              >
                <table
                  className="table table-sm table-striped table-bordered table-hover"
                  style={{ borderCollapse: "separate" }}
                >
                  <thead>
                    <tr>
                      {[
                        "Select Qty",
                        "Select",
                        "WH",
                        "P/N",
                        "MFR",
                        "Part Name",
                        "Desc",
                        "Qty Orig",
                        "Free stock",
                        "Free stock (autorsv)",
                        "MR Outs",
                        "UM",
                        "Category",
                        "Report Qty",
                      ].map((h) => (
                        <th
                          className="sticky-top top-0 bg-dark text-light"
                          style={{ position: "sticky", top: 0 }}
                        >
                          {h}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {relevantItems
                      .map((i) => {
                        const foundInventoryList = inventory.filter(
                          (inv) => inv.productId === i.id
                        );
                        return foundInventoryList
                          .filter((i) =>
                            searchWarehouse !== ""
                              ? `${i.warehouse?.warehouse?.name}.${i.warehouse?.whName}`
                                  .toLowerCase()
                                  .includes(searchWarehouse.toLowerCase())
                              : true
                          )
                          .map((inv) => {
                            const inv2 = inventoryRsvMr.find(
                              (ix) => `${ix.id}` === `${inv.id}`
                            );
                            const foundMRByItem = mrsByItemManual.current.find(
                              (m) => `${m.extItemId}` === `${inv.productId}`
                            );
                            return (
                              <>
                                <tr>
                                  {selectedMr?.partNumCategory}{" "}
                                  {`${inv.products?.categoryPartNum}` ===
                                    `${selectedMr?.partNumCategory}`}
                                  <td className="border border-dark p-0 m-0">
                                    <input
                                      placeholder="Qty..."
                                      onBlur={(e) => {
                                        if (
                                          !isNaN(parseFloat(e.target.value))
                                        ) {
                                          setSelectQty(
                                            parseFloat(e.target.value)
                                          );
                                        }
                                      }}
                                      className="form-control form-control-sm"
                                      style={{ width: 50 }}
                                    />
                                  </td>
                                  <td className="border border-dark p-0 m-0">
                                    <button
                                      className="btn btn-sm btn-primary"
                                      onClick={() => {
                                        // setSelectItem(false);

                                        // if (
                                        //   warehouseItemCategoryPartNumFromJSON(
                                        //     i.categoryPartNum
                                        //   ) !==
                                        //   WarehouseItemCategoryPartNum.CPN_CONSUMABLE
                                        // ) {
                                        //   alert("Warning: Item not consumable");
                                        // }

                                        // Check if material, must have autoMR first
                                        if (
                                          newMr?.partNumCategory === 0 &&
                                          !newMr.titipan
                                        ) {
                                          const foundAbleToMr =
                                            mrsByJobManual.current.find((m) =>
                                              m.materialRequestItems?.find(
                                                (i) =>
                                                  `${i.extItemId}` ===
                                                  `${inv.productId}`
                                              )
                                            );

                                          if (!foundAbleToMr) {
                                            alert(
                                              "No AutoMR detected for material manual MR. Please auto MR first."
                                            );
                                            return;
                                          }
                                        }

                                        if (
                                          newMr.materialRequestItems?.find(
                                            (mri) => mri.extItemId === i.id
                                          )
                                        ) {
                                          alert(
                                            "The item already exists in current MR."
                                          );

                                          return;
                                        }
                                        setSearchItem("");
                                        setNewMr({
                                          ...newMr,
                                          materialRequestItems:
                                            newMr.materialRequestItems
                                              ? [
                                                  ...newMr.materialRequestItems,
                                                  {
                                                    ...initialMaterialRequestItem,
                                                    extItemId: i.id,
                                                    extInventoryId: inv.id,
                                                    qty: selectQty,
                                                  },
                                                ]
                                              : newMr.materialRequestItems,
                                        });
                                      }}
                                    >
                                      Select
                                    </button>
                                  </td>
                                  <td className="border border-dark p-0 m-0">
                                    {inv.warehouse?.warehouse?.name}.
                                    {inv.warehouse?.whName}
                                  </td>
                                  <td className="border border-dark p-0 m-0">
                                    {i.partNum}
                                  </td>
                                  <td className="border border-dark p-0 m-0">
                                    {i.mfr}
                                  </td>
                                  <td className="border border-dark p-0 m-0">
                                    {i.partName}
                                  </td>
                                  <td className="border border-dark p-0 m-0">
                                    {i.partDesc}
                                  </td>
                                  <td
                                    className="border border-dark p-0 m-0"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {loadingInventory ? (
                                      <>
                                        <Spinner animation="border" />
                                      </>
                                    ) : (
                                      <>
                                        <div>
                                          <div>{inv.qty?.toFixed(2)}</div>
                                          <div>
                                            {(() => {
                                              const unappr = checkUnapproved(
                                                materialRequestsAll,
                                                inv.id
                                              );

                                              return unappr.qty > 0 ? (
                                                <>
                                                  <div>
                                                    <div>
                                                      <small>
                                                        <strong>
                                                          Unapproved:{" "}
                                                          {unappr.qty}
                                                        </strong>
                                                      </small>
                                                    </div>
                                                    <div>
                                                      <ol>
                                                        {unappr.mrs.map(
                                                          (mr) => {
                                                            return (
                                                              <>
                                                                <li>
                                                                  <div>
                                                                    <small>
                                                                      #{mr.id}
                                                                    </small>
                                                                  </div>
                                                                </li>
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                      </ol>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <></>
                                              );
                                            })()}
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </td>
                                  <td className="border border-dark p-0 m-0">
                                    {inv.balanceRsv?.toFixed(2)}
                                  </td>
                                  <td className="border border-dark p-0 m-0">
                                    {inv2?.balanceRsv?.toFixed(2)}
                                  </td>
                                  <td className="border border-dark p-0 m-0">
                                    <button
                                      className="btn btn-sm btn-primary px-1 py-0"
                                      onClick={async () => {
                                        if (!foundMRByItem) {
                                          const d = await fetchMRByJobProduct({
                                            extItemId: inv.productId,
                                            // jobId: params.jobId,
                                          });

                                          mrsByItemManual.current = [
                                            ...mrsByItemManual.current,
                                            {
                                              extItemId: inv.productId,
                                              mrs: d ?? [],
                                            },
                                          ];

                                          render();
                                        }
                                      }}
                                    >
                                      <ArrowDownward />
                                    </button>
                                    {foundMRByItem &&
                                    foundMRByItem.mrs.length > 0 ? (
                                      <>
                                        <ol>
                                          {foundMRByItem.mrs
                                            .filter(
                                              (m) => !m.hidden && m.status !== 2
                                            )
                                            .map((i) => {
                                              const mriQty =
                                                i.materialRequestItems
                                                  ?.filter(
                                                    (ix) =>
                                                      `${ix.extItemId}` ===
                                                      `${inv.productId}`
                                                  )
                                                  .reduce(
                                                    (acc, ix) =>
                                                      acc + (ix.qty ?? 0.0),
                                                    0.0
                                                  );
                                              return (
                                                <>
                                                  <li>
                                                    <div>
                                                      <div>
                                                        <small>
                                                          MR/PPIC/
                                                          {new Date(
                                                            i.createdAt ??
                                                              new Date()
                                                          ).getFullYear()}
                                                          /{i.id} (
                                                          {intlFormat({
                                                            date:
                                                              i.createdAt ??
                                                              makeDateString(
                                                                new Date()
                                                              ),
                                                            dateStyle: "medium",
                                                          })}
                                                          ) x{mriQty}
                                                        </small>
                                                      </div>
                                                    </div>
                                                  </li>
                                                </>
                                              );
                                            })}
                                        </ol>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                  <td className="border border-dark p-0 m-0">
                                    {i.defaultUm}
                                  </td>
                                  <td className={`border border-dark p-0 m-0`}>
                                    {/* {inv.products?.categoryPartNum} */}
                                    {
                                      categoryPartNums.find(
                                        (c) =>
                                          `${c.value}` ===
                                          `${inv.products?.categoryPartNum}`
                                      )?.label
                                    }
                                  </td>
                                  <td className="border border-dark p-0 m-0">
                                    <button
                                      className="btn btn-sm btn-danger"
                                      onClick={async () => {
                                        try {
                                          if (
                                            window.confirm(
                                              `Report stock for ${i.partNum}: ${i.mfr}: ${i.partName}: ${i.partDesc}?`
                                            )
                                          ) {
                                            const resp = await fetch(
                                              `${process.env.REACT_APP_BASE_URL}/report-inv`,
                                              {
                                                method: "post",
                                                headers: {
                                                  "content-type":
                                                    "application/json",
                                                  authorization:
                                                    ctx?.apiKey ?? "",
                                                },
                                                body: JSON.stringify(
                                                  PpicWarehouseReportQty.toJSON(
                                                    PpicWarehouseReportQty.fromPartial(
                                                      {
                                                        inventoryId: `${
                                                          inv.id ?? 0
                                                        }`,
                                                        qtySeen: inv.qty ?? 0,
                                                      }
                                                    )
                                                  )
                                                ),
                                              }
                                            );
                                            if (resp.status !== 200)
                                              throw await resp.text();
                                            alert("Report Success.");
                                          }
                                        } catch (e) {
                                          console.error(e);
                                        }
                                      }}
                                    >
                                      Report
                                    </button>
                                  </td>
                                </tr>
                              </>
                            );
                          });
                      })
                      .flat()}
                  </tbody>
                </table>
              </div>
            </>
          )}
          <div>
            <div
              className="border border-dark overflow-auto"
              style={{ height: "30vh", resize: "vertical" }}
            >
              <table
                className="table table-sm table-bordered"
                style={{ borderCollapse: "separate" }}
              >
                <tr>
                  {["#", "Item", "WH", "Qty"].map((h) => (
                    <>
                      <th
                        className="bg-dark text-light m-0 p-0"
                        style={{ position: "sticky", top: 0 }}
                      >
                        {h}
                      </th>
                    </>
                  ))}
                </tr>
                {newMr.materialRequestItems?.map((i, i_) => {
                  const foundItem = items.find((ix) => ix.id === i.extItemId);
                  const foundInventory = inventory.find(
                    (inv) => inv.id === i.extInventoryId
                  );
                  const foundInventoryStock =
                    i.extInventoryId && i.extInventoryId !== 0
                      ? foundInventory?.qty ?? 0
                      : inventory
                          .filter((inv) => inv.productId === i.extItemId)
                          .reduce((acc, inv) => acc + (inv.qty ?? 0), 0);

                  return (
                    <>
                      <tr>
                        <td className="border border-dark m-0 p-0">{i_ + 1}</td>
                        <td className="border border-dark m-0 p-0">
                          {foundItem ? getItemFullDescription(foundItem) : ""}
                        </td>
                        <td className="border border-dark m-0 p-0">
                          {foundInventory?.warehouse?.warehouse?.name}.
                          {foundInventory?.warehouse?.whName}
                        </td>
                        <td className="border border-dark m-0 p-0"> {i.qty}</td>
                      </tr>
                    </>
                  );
                })}
              </table>
            </div>

            {mrItemFailureData.current.length > 0 ? (
              <>
                {" "}
                <small>
                  <strong>MR import failure data:</strong>
                </small>
                <ol>
                  {mrItemFailureData.current.map((f) => {
                    const foundInvs = inventory.filter(
                      (i) => `${i.productId}` === `${f.extItemId}`
                    );

                    const foundItem = items.find(
                      (i) => `${i.id}` === `${f.extItemId}`
                    );

                    return (
                      <>
                        <li>
                          <div>
                            <small>
                              <strong>
                                {foundItem?.partNum} (Avail{" "}
                                {foundInvs
                                  .map(
                                    (i) =>
                                      `${i.warehouse?.warehouse?.name}.${i.warehouse?.whName}:${i.qty}`
                                  )
                                  .join(", ")}
                                )
                              </strong>
                            </small>
                          </div>
                        </li>
                      </>
                    );
                  })}
                </ol>
              </>
            ) : (
              <></>
            )}
          </div>

          <div>
            <h4>Found PN</h4>
          </div>

          <div
            className="overflow-auto border border-secondary shadow shadow-md"
            style={{ height: "35vh", resize: "vertical" }}
          >
            <table
              style={{ borderCollapse: "separate" }}
              className="table table-sm"
            >
              <tr>
                {["#", "MFR", "P/N", "Part Name", "Part Desc", "UM"].map(
                  (h) => (
                    <>
                      <th
                        className="bg-dark text-light"
                        style={{ position: "sticky", top: 0 }}
                      >
                        {h}
                      </th>
                    </>
                  )
                )}
              </tr>
              {relevantItems.map((i, i_) => {
                return (
                  <>
                    <tr>
                      <td className="border border-dark p-0 m-0">{i_ + 1}</td>
                      <td className="border border-dark p-0 m-0">{i.mfr}</td>
                      <td className="border border-dark p-0 m-0">
                        {i.partNum}
                      </td>
                      <td className="border border-dark p-0 m-0">
                        {i.partName}
                      </td>
                      <td className="border border-dark p-0 m-0">
                        {i.partDesc}
                      </td>
                      <td className="border border-dark p-0 m-0">
                        {i.defaultUm}
                      </td>
                    </tr>
                  </>
                );
              })}
            </table>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default MaterialRequestSimpleView;
