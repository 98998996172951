import { Box, TextField } from "@material-ui/core";
import { stat } from "fs";
import React, { useContext, useState } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import { AppContext } from "../../App";
import {
  fetchInitialData,
  fetchMeetingUser,
  fetchUser,
  parseJwt,
} from "../../helpers";
import { RequestStatus } from "../../models/RequestStatus";

const Login = (props: {}) => {
  const state = useContext(AppContext);
  const [loginInfo, setLoginInfo] = useState<{
    username: string;
    password: string;
  }>({ username: "", password: "" });

  const LoginStatusChecker = () => {
    switch (state?.loginRequestStatus) {
      case RequestStatus.Loading:
        return <Spinner animation="border" variant="primary" />;
      case RequestStatus.Error:
        return (
          <h4 style={{ color: "red" }}>Error logging in. Please try again</h4>
        );
      default:
        return <></>;
    }
  };

  const handleLogin = async (e: any, username: string, password: string) => {
    e.preventDefault();

    try {
      // API call here
      state?.setLoginRequestStatus(RequestStatus.Loading);
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: username, password: password }),
      });

      if (response.status !== 200) throw "Error logging in";
      const apiKey = await response.text();

      localStorage.setItem("apiKey", apiKey);

      state?.setLoggedIn(true);
      state?.setApiKey(apiKey);
      state?.setLoginRequestStatus(RequestStatus.NotAsked);

      const [userData] = await Promise.all([
        fetchUser({
          apiKey: apiKey,
          userId: parseJwt(apiKey)?.jti,
        }),
      ]);

      state?.setUser(userData);

      if (state) {
        await fetchInitialData(state);
      }

      // Login gspe app
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/ext-login`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ username: username, password: password }),
          }
        );

        if (response.status !== 200) {
          throw await response.text();
        }
        const gspeAppApiKey = await response.text();

        const [gspeAppUserData] = await Promise.all([
          fetchMeetingUser({
            apiKey: gspeAppApiKey,
            userId: parseJwt(gspeAppApiKey)?.jti,
          }),
        ]);

        console.log('gspe app user', gspeAppUserData)

        localStorage.setItem("gspeAppApiKey", gspeAppApiKey);
        state?.setGspeAppApiKey(gspeAppApiKey);

        state?.setGspeAppUser(gspeAppUserData);
      } catch (e) {
        alert(`GSPE app login error: ${e}`);
      }
    } catch (e) {
      state?.setLoginRequestStatus(RequestStatus.Error);

      console.log(e);
    }
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <Card border="dark" className="d-flex flex-column align-items-center p-5">
        <h3>GSPE Manufacturing</h3>
        <LoginStatusChecker />
        <form>
          <Box display="flex" flexDirection="column" alignItems="center">
            <TextField
              label="Username"
              onChange={(evt) =>
                setLoginInfo({ ...loginInfo, username: evt.target.value })
              }
              value={loginInfo.username}
            />
            <TextField
              label="Password"
              type="password"
              onChange={(evt) =>
                setLoginInfo({ ...loginInfo, password: evt.target.value })
              }
              value={loginInfo.password}
            />
            <Box mt={1}>
              <Button
                type="submit"
                variant="primary"
                onClick={(e: any) =>
                  handleLogin(e, loginInfo.username, loginInfo.password)
                }
              >
                Login
              </Button>
            </Box>
          </Box>
        </form>
      </Card>
    </div>
  );
};

export default Login;
