import {
  Add,
  ChevronRight,
  CloudDownload,
  FileCopy,
  Link,
  LocationOn,
} from "@material-ui/icons";
import ColorHash from "color-hash";
import { LatLngTuple } from "leaflet";
import { stringify } from "querystring";
import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Modal, Spinner } from "react-bootstrap";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import Select from "react-select";
import { v4 } from "uuid";
import { AppContext } from "../../../App";
import {
  extractProtoMeetingTaskTargetDate,
  fetchDepartmentTemplatesProto,
  fetchExtCrmBposProto,
  fetchExtCrmPurchaseOrdersProto,
  fetchIntegrationModulesProto,
  fetchJobsDataProto,
  fetchJobsProtoSimple,
  fetchMachinePrograms,
  fetchMachines,
  fetchMachinesProto,
  fetchMeetingSites,
  fetchMiddlewareTransactionTypes,
  fetchPanelCodeDepartmentTemplateItemsProto,
  fetchProblemCatalogs,
  fetchProcessTypes,
  fetchRcemDepartmentsProto,
  fetchSimplifiedProcessTypesProto,
  fetchTaskListTemplates,
  fetchWorkOrdersProto,
  fetchWorkOrdersProtoMongo,
  filterMeetingTasks,
  intlFormat,
  makeDateString,
  TaskListInfo,
} from "../../../helpers";
import {
  AppSource,
  appSourceToJSON,
  CrmBPO,
  CrmBPOs,
  CrmCustomer,
  CrmPurchaseOrder,
  CrmPurchaseOrders,
  Journey,
  MasterJavaBaseModel,
  MeetingMeetingTask,
  MeetingMeetingTaskComment,
  MeetingMeetingTaskInCharge,
  MeetingMeetingTaskListTemplate,
  MeetingMeetingTaskListTemplates,
  MeetingMeetingTaskProblemCatalog,
  MeetingMeetingTaskTargetDate,
  MeetingProblemCatalog,
  MeetingProblemCatalogs,
  meetingProblemTypeFromJSON,
  meetingProblemTypeToJSON,
  MeetingSite,
  MeetingSites,
  MeetingTaskList,
  MeetingTaskListsView,
  MeetingTaskListView,
  MeetingTaskProblemCatalogStatus,
  meetingTaskProblemCatalogStatusFromJSON,
  PpicDepartmentTemplates,
  PpicIntegrationModules,
  PpicJob,
  PpicJobs,
  PpicMachinePrograms,
  PpicPanelCode,
  PpicPanelCodeDepartmentTemplateItems,
  PpicRcemDepartment,
  PpicRcemDepartments,
  PpicRcemProcess,
  PpicRcemSubProcess,
  PpicSimplifiedProcessTypes,
} from "../../../masterbigsystem";
import {
  Job,
  DepartmentTemplateView,
  User,
  Machine,
  ExtUser,
  JobDeptCategory,
  IndividualEntity,
  DepartmentTemplate,
  ExtDepartment,
  ExtCustomer,
} from "../../../models/model";

const ManualWoPage = () => {
  const ctx = useContext(AppContext);

  const [problemCatalogs, setProblemCatalogs] = useState(
    MeetingProblemCatalogs.fromPartial({ catalogs: [] })
  );
  const [meetingTaskInChargeDeleteIds, setMeetingTaskInChargeDeleteIds] =
    useState<string[]>([]);
  const [meetingTaskDeleteIds, setMeetingTaskDeleteIds] = useState<string[]>(
    []
  );
  const bpos = useRef(CrmBPOs.fromPartial({ purchaseOrders: [] }));

  const [
    panelCodeDepartmentTemplateItemsProto,
    setPanelCodeDepartmentTemplateItemsProto,
  ] = useState(
    PpicPanelCodeDepartmentTemplateItems.fromPartial({
      departmentTemplateItems: [],
    })
  );
  const middlewareTransactionTypes = useRef(
    [] as { id?: number | null; name?: string | null }[]
  );
  const [, refresh] = useState(false);
  const render = () => {
    refresh((n) => !n);
  };
  const [
    meetingTaskProblemCatalogDeleteIds,
    setMeetingTaskProblemCatalogDeleteIds,
  ] = useState<string[]>([]);

  const [selectedTaskList, setSelectedTaskList] = useState(
    MeetingTaskListView.fromPartial({
      taskList: {
        reminderDays: 2,
      },
    })
  );
  const [workOrders, setWorkOrders] = useState(
    MeetingTaskListsView.fromPartial({ taskLists: [] })
  );
  const [mapShow, setMapShow] = useState(false);
  const [selectCoordinateModalShow, setSelectCoordinateModalShow] =
    useState(false);
  const [selectCoordinateModalShowTaskId, setSelectCoordinateModalShowTaskId] =
    useState<string | null>(null);
  const [
    selectCoordinateModalShowTaskUuid,
    setSelectCoordinateModalShowTaskUuid,
  ] = useState<string | null>(null);

  const [selectCoordinateIndex, setSelectCoordinateIndex] = useState(0);
  const [filters, setFilters] = useState({
    from: new Date(makeDateString(new Date())),
    to: new Date(makeDateString(new Date())),
    status: "OUTSTANDING" as string | null,
    extJobId: null as string | null | undefined,
    extUserIds: [] as number[],
  });

  const customTaskCopyDate = useRef(null as string | null);

  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState<Job[]>([]);
  const [jobsProto, setJobsProto] = useState<PpicJobs | null | undefined>(null);
  const [selectedSite, setSelectedSite] = useState<MeetingSite | null>(null);
  const [depts, setDepts] = useState<DepartmentTemplateView[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [machines, setMachines] = useState<Machine[]>([]);
  const [modalContent, setModalContent] = useState<ReactNode | null>(null);
  const [workOrderModalOpen, setWorkOrderModalOpen] = useState(false);
  const [selectedWorkOrderModalUser, setSelectedWorkOrderModalUser] =
    useState<ExtUser | null>(null);
  const [selectedWorkOrderModalDate, setSelectedWorkOrderModalDate] = useState<
    string | null
  >(null);
  const [departmentTemplates, setDepartmentTemplates] =
    useState<PpicDepartmentTemplates | null>(null);
  const [selectedWorkOrders, setSelectedWorkOrders] = useState<
    MeetingTaskListView[]
  >([]);
  const [machinePrograms, setMachinePrograms] = useState<
    PpicMachinePrograms | null | undefined
  >(null);
  const [integrationModules, setIntegrationModules] =
    useState<PpicIntegrationModules | null>(null);
  const [processTypes, setProcessTypes] =
    useState<PpicSimplifiedProcessTypes | null>(null);
  const [rcemDepartments, setRcemDepartments] =
    useState<PpicRcemDepartments | null>(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [templates, setTemplates] = useState(
    MeetingMeetingTaskListTemplates.fromPartial({ templates: [] })
  );
  const [getDataFlip, setGetDataFlip] = useState(false);
  const [showFullPanelCode, setShowFullPanelCode] = useState(false);
  const [hiddenJobDeptProcesses, setHiddenJobDeptProcesses] = useState<
    DepartmentTemplate[]
  >([]);
  const [showEditorWoModal, setShowEditorWoModal] = useState(false);
  const [sites, setSites] = useState(MeetingSites.fromPartial({}));
  const pos = useRef<CrmPurchaseOrders | null>(null);

  useEffect(() => {
    if (getDataFlip) {
      handleInit();
    }
  }, [getDataFlip]);

  useEffect(() => {
    handleGetInitialData();
  }, []);

  const fetchBPOsData = async () => {
    // setPurchaseOrdersLoading(true);
    const d = await fetchExtCrmBposProto({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      bpos.current = d;
    }
    // setPurchaseOrdersLoading(false);
    render();
  };

  const handleGetInitialData = async () => {
    fetchMachinesData();
    fetchDepartmentTemplatesData();
    fetchRcemDepartmentsData();
    fetchProcessTypesData();
    fetchIntegrationModulesData();
    fetchJobsData();
    fetchMachineProgramsData();
    fetchMeetingMeetingTaskTemplatesData();
    fetchMeetingTaskProblemCatalogsData();
    fetchPanelCodeDeparmentTemplateItemsData();
    fetchMeetingSitesData();
    fetchMiddlewareTransactionTypesData();
    fetchPurchaseOrdersData();
    fetchBPOsData();
  };

  const handleInit = async () => {
    if (!loading) {
      setLoading(true);
      await Promise.all([fetchTaskListsData()]);
      setLoading(false);
      setGetDataFlip(false);
    }
  };

  const fetchPurchaseOrdersData = async () => {
    const d = await fetchExtCrmPurchaseOrdersProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      pos.current = d;
    }
    render();
  };
  const fetchMeetingSitesData = async () => {
    const d = await fetchMeetingSites({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      setSites(d);
    }
  };
  const fetchPanelCodeDeparmentTemplateItemsData = async () => {
    setPanelCodeDepartmentTemplateItemsProto(
      await fetchPanelCodeDepartmentTemplateItemsProto({
        apiKey: ctx?.apiKey ?? "",
      })
    );
  };
  const fetchMeetingTaskProblemCatalogsData = async () => {
    setProblemCatalogs(
      await fetchProblemCatalogs({ apiKey: ctx?.apiKey ?? "" })
    );
  };
  const fetchMachinesData = async () => {
    setMachines(await fetchMachines({ apiKey: ctx?.apiKey ?? "" }));
  };
  const fetchMachineProgramsData = async () => {
    setMachinePrograms(
      await fetchMachinePrograms({ apiKey: ctx?.apiKey ?? "" })
    );
  };

  const fetchDepartmentTemplatesData = async () => {
    setDepartmentTemplates(
      await fetchDepartmentTemplatesProto({ apiKey: ctx?.apiKey ?? "" })
    );
  };
  const fetchRcemDepartmentsData = async () => {
    setRcemDepartments(
      await fetchRcemDepartmentsProto({ apiKey: ctx?.apiKey ?? "" })
    );
  };
  const fetchProcessTypesData = async () => {
    setProcessTypes(
      await fetchSimplifiedProcessTypesProto({ apiKey: ctx?.apiKey ?? "" })
    );
  };
  const fetchIntegrationModulesData = async () => {
    setIntegrationModules(
      await fetchIntegrationModulesProto({ apiKey: ctx?.apiKey ?? "" })
    );
  };
  const fetchJobsData = async () => {
    setJobsProto(
      await fetchJobsProtoSimple({
        withProducts: true,
        apiKey: ctx?.apiKey ?? "",
      })
    );
  };

  const fetchMiddlewareTransactionTypesData = async () => {
    const d = await fetchMiddlewareTransactionTypes({
      apiKey: ctx?.apiKey ?? "",
    });
    if (d) {
      middlewareTransactionTypes.current = d;
      render();
    }
  };
  const [mapCenter, setMapCenter] = useState<LatLngTuple>([
    -6.227026, 106.816581,
  ]);
  const [mapWidth, setMapWidth] = useState("100%");
  const fetchMeetingMeetingTaskTemplatesData = async () => {
    setTemplates(await fetchTaskListTemplates({ apiKey: ctx?.apiKey ?? "" }));
  };

  const fetchTaskListsData = async () => {
    setLoading(true);

    setWorkOrders(
      await fetchWorkOrdersProto({
        apiKey: ctx?.apiKey ?? "",
        outstanding: (() => {
          switch (filters.status) {
            case "OUTSTANDING":
              return true;

            case "COMPLETED":
              return false;

            default:
              return undefined;
          }
        })(),
        from: makeDateString(filters.from),
        to: makeDateString(filters.to),
        extJobId: filters.extJobId,
        extUserIds: filters.extUserIds,
      })
    );

    setLoading(false);
  };
  const fetchTaskListsDataMongo = async () => {
    setLoading(true);

    setWorkOrders(
      await fetchWorkOrdersProtoMongo({
        apiKey: ctx?.apiKey ?? "",
        outstanding: (() => {
          switch (filters.status) {
            case "OUTSTANDING":
              return true;

            case "COMPLETED":
              return false;

            default:
              return undefined;
          }
        })(),
        from: makeDateString(filters.from),
        to: makeDateString(filters.to),
        extJobId: filters.extJobId,
        extUserIds: filters.extUserIds,
      })
    );
    setLoading(false);
  };

  const fetchWoButtonData = async () => {
    setGetDataFlip(!getDataFlip);
  };

  const handleSaveWo = async (params?: { copy?: boolean }) => {
    try {
      setLoading(true);

      const taskListToSave = selectedTaskList;

      // Check if complete but no date
      const foundCompleteNoDate = taskListToSave.taskList?.meetingTasks
        .filter((mt) => mt.status === "COMPLETED")
        .find((mt) => !mt.completedDate || mt.completedDate == "");

      if (foundCompleteNoDate) {
        alert("Completed task must have date.");
        return;
      }

      if (params?.copy) {
        if (taskListToSave.taskList?.masterJavaBaseModel) {
          taskListToSave.taskList.masterJavaBaseModel.id = undefined;
        }

        // task
        taskListToSave.taskList?.meetingTasks.forEach((d) => {
          if (customTaskCopyDate.current) {
            d.start = customTaskCopyDate.current;
            d.meetingTaskTargetDates.push(
              MeetingMeetingTaskTargetDate.fromPartial({
                masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                  uuid: v4(),
                }),
                date: customTaskCopyDate.current,
              })
            );
          }

          if (d.masterJavaBaseModel) {
            d.masterJavaBaseModel.id = undefined;
          }

          // MeetingTaskNote
          d.meetingTaskNotes.forEach((d) => {
            if (d.masterJavaBaseModel) {
              d.masterJavaBaseModel.id = undefined;
            }
          });

          // MeetingTaskIssue
          d.meetingTaskIssues.forEach((d) => {
            if (d.masterJavaBaseModel) {
              d.masterJavaBaseModel.id = undefined;
            }
          });

          // MeetingUser
          d.peopleInCharge.forEach((d) => {
            if (d.masterJavaBaseModel) {
              d.masterJavaBaseModel.id = undefined;
            }
          });

          // MeetingMeetingTaskTargetDate
          d.meetingTaskTargetDates.forEach((d) => {
            if (d.masterJavaBaseModel) {
              d.masterJavaBaseModel.id = undefined;
            }
          });

          // MeetingMeetingTaskInCharge
          d.meetingTaskInCharges.forEach((d) => {
            if (d.masterJavaBaseModel) {
              d.masterJavaBaseModel.id = undefined;
            }
          });

          // MeetingTaskDepartment
          d.meetingTaskDepartments.forEach((d) => {
            if (d.masterJavaBaseModel) {
              d.masterJavaBaseModel.id = undefined;
            }
          });

          // MeetingTaskRemark
          d.meetingTaskRemarks.forEach((d) => {
            if (d.masterJavaBaseModel) {
              d.masterJavaBaseModel.id = undefined;
            }
          });

          // MeeetingTaskAttachment
          d.meetingTaskAttachment.forEach((d) => {
            if (d.masterJavaBaseModel) {
              d.masterJavaBaseModel.id = undefined;
            }
          });

          // MeetingMeetingTaskComment
          d.meetingTaskComments.forEach((d) => {
            if (d.masterJavaBaseModel) {
              d.masterJavaBaseModel.id = undefined;
            }
          });

          // MeetingMeetingTaskProblemCatalog
          d.meetingTaskProblemCatalogs.forEach((d) => {
            if (d.masterJavaBaseModel) {
              d.masterJavaBaseModel.id = undefined;
            }
          });

          // MeetingMeetingTaskSerialNumber
          d.meetingTaskSerialNumbers.forEach((d) => {
            if (d.masterJavaBaseModel) {
              d.masterJavaBaseModel.id = undefined;
            }
          });

          // Journey
          d.journeys.forEach((d) => {
            if (d.masterJavaBaseModel) {
              d.masterJavaBaseModel.id = undefined;
            }
          });
        });

        // targetdate
        taskListToSave.taskList?.taskListTargetDates.forEach((d) => {
          if (d.masterJavaBaseModel) {
            d.masterJavaBaseModel.id = undefined;
          }
        });

        // MeetingTaskListInCharge
        taskListToSave.taskList?.taskListInCharges.forEach((d) => {
          if (d.masterJavaBaseModel) {
            d.masterJavaBaseModel.id = undefined;
          }
        });

        // MeetingTaskListSites
        taskListToSave.taskList?.taskListSites.forEach((d) => {
          if (d.masterJavaBaseModel) {
            d.masterJavaBaseModel.id = undefined;
          }
        });

        // MeetingTaskListCashAdvancement
        taskListToSave.taskList?.taskListTargetDates.forEach((d) => {
          if (d.masterJavaBaseModel) {
            d.masterJavaBaseModel.id = undefined;
          }
        });

        // MeetingTaskListCashAdvancementWorkOrder
        taskListToSave.taskList?.taskListCashAdvancementWorkOrders.forEach(
          (d) => {
            if (d.masterJavaBaseModel) {
              d.masterJavaBaseModel.id = undefined;
            }
          }
        );

        // MeetingTaskListReference
        taskListToSave.taskList?.references.forEach((d) => {
          if (d.masterJavaBaseModel) {
            d.masterJavaBaseModel.id = undefined;
          }
        });
      }
      // Delete all meetingtask in charges
      await Promise.all([
        ...taskListToSave.meetingTaskInChargeDeleteIds.map(async (id) => {
          try {
            const resp = await fetch(
              `${process.env.REACT_APP_MEETING_URL}/meetingtaskincharges/${id}/emptyRefresh`,
              {
                method: "delete",
                headers: { authorization: ctx?.apiKey ?? "" },
              }
            );
          } catch (e) {}
        }),
      ]);

      // Delete all meetingtask ids
      await Promise.all([
        ...meetingTaskDeleteIds.map(async (id) => {
          try {
            const resp = await fetch(
              `${process.env.REACT_APP_MEETING_URL}/meetingtasks/${id}/empty`,
              {
                method: "delete",
                headers: { authorization: ctx?.apiKey ?? "" },
              }
            );
          } catch (e) {}
        }),
      ]);

      // Delete all problemcatalog ids
      await Promise.all([
        ...meetingTaskProblemCatalogDeleteIds.map(async (id) => {
          try {
            const resp = await fetch(
              `${process.env.REACT_APP_MEETING_URL}/meetingtaskproblemcatalogs/${id}/empty`,
              {
                method: "delete",
                headers: { authorization: ctx?.apiKey ?? "" },
              }
            );
          } catch (e) {}
        }),
      ]);

      const resp = await fetch(
        `${process.env.REACT_APP_MEETING_URL}/tasklists-proto-save`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: ctx?.apiKey ?? "",
          },
          body: JSON.stringify(taskListToSave),
        }
      );

      const taskListBody = MeetingTaskList.fromPartial(await resp.json());
      setShowEditorWoModal(false);

      setWorkOrders({
        ...workOrders,
        taskLists: workOrders.taskLists
          ? workOrders.taskLists.map((tLx) =>
              (
                tLx.taskList?.masterJavaBaseModel?.id &&
                tLx.taskList?.masterJavaBaseModel?.id !== null
                  ? tLx.taskList.masterJavaBaseModel.id ===
                    taskListBody.masterJavaBaseModel?.id
                  : tLx.taskList?.masterJavaBaseModel?.uuid ===
                    taskListBody.masterJavaBaseModel?.uuid
              )
                ? {
                    ...selectedTaskList,
                    taskList: selectedTaskList.taskList
                      ? {
                          ...selectedTaskList.taskList,
                          masterJavaBaseModel: selectedTaskList.taskList
                            .masterJavaBaseModel
                            ? {
                                ...selectedTaskList.taskList
                                  .masterJavaBaseModel,
                                id: taskListBody.masterJavaBaseModel?.id,
                              }
                            : selectedTaskList.taskList.masterJavaBaseModel,
                        }
                      : selectedTaskList.taskList,
                  }
                : tLx
            )
          : workOrders.taskLists,
      });

      // handleInit();
      if (filters.extUserIds.length > 0 || filters.extJobId) {
        fetchTaskListsDataMongo();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const MapSelectCoord = () => {
    return (
      <>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url={process.env.REACT_APP_OSM_TILE_URL ?? ""}
        ></TileLayer>
        {selectedSite ? (
          <>
            {" "}
            <Marker position={[selectedSite.lat ?? 0, selectedSite.lon ?? 0]} />
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <>
      <div>
        <div className="d-flex">
          <div>
            <h4>Manual WO </h4>
          </div>
          {loading ? (
            <>
              <Spinner animation="border" />
            </>
          ) : (
            <></>
          )}
        </div>
        <hr className="border border-dark" />
        <div className="d-flex">
          <div>
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                setShowEditorWoModal(true);
                setSelectedTaskList(
                  MeetingTaskListView.fromPartial({
                    taskList: MeetingTaskList.fromPartial({
                      masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                        uuid: v4(),
                      }),
                    }),
                  })
                );
              }}
            >
              Add
            </button>
          </div>
          <div className="flex-grow-1">
            <Select
              placeholder="Filter by dept"
              options={ctx?.extDepartments}
              getOptionLabel={(d) => `${d.name}`}
              onChange={(v) => {
                const val = v as unknown as ExtDepartment;
                setFilters({
                  ...filters,
                  extUserIds:
                    ctx?.extUsers
                      .filter((u) => val.id && u.departmentId === val.id)
                      .map((u) => u.id as number) ?? [],
                });
              }}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          </div>
          <div className="flex-grow-1">
            <Select
              placeholder="Filter by user"
              options={ctx?.extUsers}
              getOptionLabel={(u) =>
                `${u.username}: ${u.name}: ${u.departmentName}`
              }
              onChange={(v) => {
                const val = v as unknown as ExtUser;
                setFilters({
                  ...filters,
                  extUserIds: [val.id ?? 0],
                });
              }}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          </div>

          <div className="flex-grow-1">
            <Select
              placeholder="Filter job"
              options={jobsProto?.jobs ?? []}
              getOptionLabel={(j) => `${j.name}`}
              onChange={(v) => {
                const val = v as unknown as PpicJob;
                setFilters({
                  ...filters,
                  extJobId: val.masterJavaBaseModel?.id,
                });
              }}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          </div>
          <div className="flex-grow-1">
            <Select
              placeholder="Filter status"
              options={[
                { label: "OUTSTANDING", value: "OUTSTANDING" },
                { label: "COMPLETED", value: "COMPLETED" },
              ]}
              value={[
                { label: "OUTSTANDING", value: "OUTSTANDING" },
                { label: "COMPLETED", value: "COMPLETED" },
              ].find((d) =>
                filters.status ? d.value === filters.status : null
              )}
              //   getOptionLabel={(s) => s}
              onChange={(v) => {
                const val = v as unknown as { label: string; value: string };
                setFilters({ ...filters, status: val.value });
              }}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          </div>
          <div className="flex-grow-1">
            <input
              type="date"
              placeholder="Filter from"
              className="form-control form-control-sm"
              onBlur={(e) => {
                setFilters({
                  ...filters,
                  from: new Date(makeDateString(new Date(e.target.value))),
                });
              }}
            />
            From: {makeDateString(filters.from)}
          </div>
          <div className="flex-grow-1">
            <input
              type="date"
              placeholder="Filter from"
              className="form-control form-control-sm"
              onBlur={(e) => {
                setFilters({
                  ...filters,
                  to: new Date(makeDateString(new Date(e.target.value))),
                });
              }}
            />
            To: {makeDateString(filters.to)}
          </div>
          <div className="mx-1">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                fetchTaskListsData();
              }}
            >
              Get data
            </button>
          </div>
          <div className="mx-1">
            <button
              className="btn btn-sm btn-success"
              onClick={() => {
                fetchTaskListsDataMongo();
              }}
            >
              Get data (fast)
            </button>
          </div>
          <div className="mx-1">
            <a href="/#/woreportpage" target="_blank">
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  // fetchTaskListsDataMongo();
                }}
              >
                <Link /> Report Page
              </button>
            </a>
          </div>
          <div className="mx-2">
            <a
              target="_blank"
              href={`${
                process.env.REACT_APP_MEETING_URL
              }/woreport?start=${makeDateString(
                filters.from
              )}&end=${makeDateString(
                filters.to
              )}&extUserIds=${encodeURIComponent(
                JSON.stringify(filters.extUserIds)
              )}&isWoInstaller=null`}
            >
              <button className="btn btn-sm btn-success">
                <CloudDownload /> XLS
              </button>
            </a>
          </div>
        </div>
        <div>
          <div
            className="overflow-auto border border-dark"
            style={{ height: "75vh", resize: "vertical" }}
          >
            <table
              className="table table-sm"
              style={{ borderCollapse: "separate" }}
            >
              <thead>
                <tr>
                  {[
                    "#",
                    "Desc",
                    "PIC",
                    "Start",
                    "End",
                    "Source",
                    "PO",
                    "SPV Status",
                    "PIC Status",

                    "Comments",
                    "Info",
                  ].map((h) => (
                    <th
                      className="bg-dark text-light"
                      style={{ position: "sticky", top: 0 }}
                    >
                      {h}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {(() => {
                  const tLs = workOrders.taskLists
                    .map((tL) =>
                      filterMeetingTasks(
                        tL.taskList?.meetingTasks,
                        null,
                        null,
                        ctx
                      ).map((mt) => ({
                        meetingTask: mt,
                        taskList: tL,
                      }))
                    )
                    .flat();

                  tLs.sort((a, b) =>
                    (b?.meetingTask.start ?? "").localeCompare(
                      a?.meetingTask.start ?? ""
                    )
                  );

                  return tLs.filter((d) => {
                    if (filters.extUserIds.length === 0) {
                      return;
                    }

                    const inChargeFilter = filters.extUserIds.find(
                      (id) =>
                        `${d?.taskList.taskList?.extInChargeId}` === `${id}` ||
                        d?.meetingTask.meetingTaskInCharges.find(
                          (c) => `${c.extUserId}` === `${id}`
                        )
                    );

                    const statusFilter = (() => {
                      if (filters.status === "OUTSTANDING") {
                        return d.meetingTask.status === "OUTSTANDING";
                      } else if (filters.status === "COMPLETED") {
                        return d.meetingTask.status === "COMPLETED";
                      } else {
                        return true;
                      }
                    })();

                    return inChargeFilter && statusFilter;
                  });
                })().map((tL, i) => {
                  return (
                    <>
                      <tr>
                        <td className="border border-dark">
                          <div>
                            <div>
                              <small>
                                {i + 1}. WO
                                <span
                                  style={{
                                    backgroundColor: new ColorHash({
                                      lightness: [0.8],
                                    }).hex(
                                      tL?.taskList.taskList?.masterJavaBaseModel
                                        ?.id ?? ""
                                    ),
                                  }}
                                >
                                  #
                                  {
                                    tL?.taskList.taskList?.masterJavaBaseModel
                                      ?.id
                                  }
                                </span>
                              </small>
                            </div>
                          </div>{" "}
                        </td>
                        <td className="border border-dark">
                          <div
                            className="text-primary"
                            style={{
                              cursor: "pointer",
                              width: 400,
                              whiteSpace: "pre-wrap",
                            }}
                            onClick={async () => {
                              setShowEditorWoModal(true);
                              if (tL?.taskList) {
                                setSelectedTaskList(tL?.taskList);
                              }
                            }}
                          >
                            {tL?.meetingTask.description &&
                            tL?.meetingTask.description !== ""
                              ? tL?.meetingTask.description
                              : "No desc"}
                          </div>
                        </td>
                        <td className="border border-dark">
                          <small>
                            spv:{" "}
                            {
                              ctx?.extUsers.find(
                                (u) =>
                                  `${u.id}` ===
                                  `${tL?.taskList.taskList?.extInChargeId}`
                              )?.username
                            }{" "}
                            | pic:{" "}
                            {tL?.meetingTask.meetingTaskInCharges
                              .map((mtic) => {
                                const fUser = ctx?.extUsers.find(
                                  (u) => `${u.id}` === `${mtic.extUserId}`
                                );
                                return `${fUser?.username}`;
                              })
                              .join(", ")}
                          </small>
                        </td>
                        <td className="border border-dark">
                          {intlFormat({
                            date: tL?.meetingTask.start,
                            dateStyle: "medium",
                          })}
                        </td>

                        <td className="border border-dark">
                          {intlFormat({
                            date: extractProtoMeetingTaskTargetDate(
                              tL?.meetingTask
                            )?.date,
                            dateStyle: "medium",
                          })}
                        </td>
                        <td className="border border-dark">
                          {tL?.taskList.taskList?.source}
                        </td>
                        <td
                          className="border border-dark"
                          style={{
                            color:
                              tL?.meetingTask.status === "COMPLETED"
                                ? `green`
                                : `red`,
                          }}
                        >
                          {(() => {
                            if (!tL?.taskList.taskList?.extPurchaseOrderId) {
                              return "";
                            }

                            const po = pos.current?.purchaseOrders.find(
                              (p) =>
                                `${p.id}` ===
                                `${tL?.taskList.taskList?.extPurchaseOrderId}`
                            );

                            if (!po) {
                              return "";
                            }

                            return `${po.purchaseOrderNumber} (${
                              po.account?.name ?? ""
                            })`;
                          })()}
                        </td>
                        <td
                          className="border border-dark"
                          style={{
                            color:
                              tL?.meetingTask.status === "COMPLETED"
                                ? `green`
                                : `red`,
                          }}
                        >
                          {tL?.meetingTask.status}
                        </td>
                        <td
                          className="border border-dark"
                          style={{
                            color: tL?.meetingTask.extPicCompletedDate
                              ? `green`
                              : `red`,
                          }}
                        >
                          {tL?.meetingTask.extPicCompletedDate
                            ? `COMPLETED`
                            : `OUTSTANDING`}
                        </td>

                        <td className="border border-dark">
                          {tL?.meetingTask.meetingTaskComments?.length}
                        </td>

                        <td className="border border-dark"></td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        size="xl"
        show={selectCoordinateModalShow}
        onHide={() => {
          setSelectCoordinateModalShow(false);
          // setMapShow(false);
          setMapWidth("100%");
        }}
      >
        <Modal.Header>
          <Modal.Title>Select coordinate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Select
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            options={sites.sites.map((s) => ({
              label: `${s.name}: ${s.lat},${s.lon}`,
              value: s,
            }))}
            onChange={(v) => {
              const val = v as { value: MeetingSite };

              setSelectedSite(val.value);
            }}
          />
          <div className="overflow-auto" style={{ resize: "both" }}>
            <MapContainer
              center={
                // osrmData || osrmDataActual
                //   ? undefined
                //   :
                selectedSite
                  ? [selectedSite.lon ?? 0, selectedSite.lat ?? 0]
                  : mapCenter
              }
              zoom={
                // osrmData || osrmDataActual
                //   ? undefined
                //   :
                9
              }
              scrollWheelZoom={true}
              style={{
                height: `400px`,
                width: "100%",
              }}
            >
              {mapShow ? (
                <>
                  <MapSelectCoord />
                </>
              ) : (
                <>Loading...</>
              )}
            </MapContainer>

            <div>Site name: {selectedSite?.name}</div>
            <div>Lat: {selectedSite?.lat}</div>
            <div>Lon: {selectedSite?.lon}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              setSelectedTaskList({
                ...selectedTaskList,
                taskList: selectedTaskList.taskList
                  ? {
                      ...selectedTaskList.taskList,
                      meetingTasks: selectedTaskList.taskList?.meetingTasks
                        ? selectedTaskList.taskList.meetingTasks.map((mtx) =>
                            mtx &&
                            (mtx.masterJavaBaseModel?.uuid &&
                            mtx.masterJavaBaseModel.uuid !== ""
                              ? `${selectCoordinateModalShowTaskUuid}` ===
                                `${mtx.masterJavaBaseModel?.uuid}`
                              : `${selectCoordinateModalShowTaskId}` ===
                                `${mtx.masterJavaBaseModel?.id}`)
                              ? {
                                  ...mtx,
                                  journeys: mtx.journeys.map((j, jx) =>
                                    jx === selectCoordinateIndex
                                      ? {
                                          ...j,
                                          siteName: selectedSite?.name,
                                          lat: selectedSite?.lat,
                                          lon: selectedSite?.lon,
                                        }
                                      : j
                                  ),
                                }
                              : mtx
                          )
                        : selectedTaskList.taskList?.meetingTasks,
                    }
                  : selectedTaskList.taskList,
              });
              setSelectCoordinateModalShow(false);
            }}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="xl"
        show={showEditorWoModal}
        onHide={() => setShowEditorWoModal(false)}
      >
        <Modal.Header>
          <Modal.Title>Work Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="d-flex">
              Is WO installer?{" "}
              <input
                type="checkbox"
                checked={selectedTaskList.taskList?.isWoInstaller}
                onClick={() => {
                  setSelectedTaskList({
                    ...selectedTaskList,
                    taskList: selectedTaskList.taskList
                      ? {
                          ...selectedTaskList.taskList,
                          isWoInstaller:
                            !selectedTaskList.taskList.isWoInstaller,
                        }
                      : selectedTaskList.taskList,
                  });
                }}
              />
              (WO installer start in GSPE app:{" "}
              <input
                type="date"
                defaultValue={
                  selectedTaskList.taskList?.start
                    ? selectedTaskList.taskList?.start.split("T")?.[0]
                    : undefined
                }
                onBlur={(e) => {
                  setSelectedTaskList({
                    ...selectedTaskList,
                    taskList: selectedTaskList.taskList
                      ? {
                          ...selectedTaskList.taskList,
                          start: `${e.target.value}T00:00:00Z`,
                        }
                      : selectedTaskList.taskList,
                  });
                }}
              />
              to{" "}
              <input
                type="date"
                defaultValue={
                  selectedTaskList.taskList?.deadline
                    ? selectedTaskList.taskList?.deadline.split("T")?.[0]
                    : undefined
                }
                onBlur={(e) => {
                  setSelectedTaskList({
                    ...selectedTaskList,
                    taskList: selectedTaskList.taskList
                      ? {
                          ...selectedTaskList.taskList,
                          deadline: `${e.target.value}T00:00:00Z`,
                        }
                      : selectedTaskList.taskList,
                  });
                }}
              />
              )
            </div>
            <div className="d-flex">
              <div>Bypass workload limiter:</div>
              <input type="checkbox" checked onClick={() => {}} />
            </div>
            <div>
              <TaskListInfo
                customers={ctx?.extCustomers}
                crmCustomers={ctx?.extCrmCustomers}
                tL={selectedTaskList}
                extUsers={ctx?.extUsers ?? []}
                jobsProto={jobsProto ?? PpicJobs.fromPartial({})}
                machines={machines}
                processTypes={
                  processTypes ?? PpicSimplifiedProcessTypes.fromPartial({})
                }
                rcemDepartments={rcemDepartments}
                integrationModules={integrationModules}
                machinePrograms={machinePrograms}
                departmentTemplates={departmentTemplates}
                panelCodeDepartmentTemplateItems={
                  panelCodeDepartmentTemplateItemsProto
                }
                instantTime={false}
                ctx={ctx}
              />
            </div>

            <div className="d-flex">
              <div className="flex-grow-1">
                <small>
                  <strong>SPV:</strong>
                </small>
                <div>
                  <Select
                    options={ctx?.extUsers ?? []}
                    placeholder="Select SPV.."
                    getOptionLabel={(u) => `${u.name} (${u.departmentName})`}
                    onChange={(v) => {
                      const val = v as unknown as ExtUser;
                      setSelectedTaskList({
                        ...selectedTaskList,
                        taskList: selectedTaskList.taskList
                          ? {
                              ...selectedTaskList.taskList,
                              extInChargeId:
                                val.id !== undefined && val.id !== null
                                  ? `${val.id}`
                                  : undefined,
                            }
                          : selectedTaskList.taskList,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="flex-grow-1">
                <small>
                  <strong>
                    App source: (
                    {selectedTaskList.taskList?.source
                      ? appSourceToJSON(selectedTaskList.taskList?.source)
                      : "No source"}
                    )
                  </strong>
                  <Select
                    options={
                      [
                        {
                          label: appSourceToJSON(AppSource.ACCOUNTING),
                          value: AppSource.ACCOUNTING,
                        },
                        {
                          label: appSourceToJSON(AppSource.CRM),
                          value: AppSource.CRM,
                        },
                        {
                          label: appSourceToJSON(AppSource.LOGISTIC),
                          value: AppSource.LOGISTIC,
                        },
                        {
                          label: appSourceToJSON(AppSource.PPC_APP),
                          value: AppSource.PPC_APP,
                        },
                        {
                          label: appSourceToJSON(AppSource.PURCHASE),
                          value: AppSource.PURCHASE,
                        },
                        {
                          label: appSourceToJSON(AppSource.WAREHOUSE),
                          value: AppSource.WAREHOUSE,
                        },
                        {
                          label: appSourceToJSON(AppSource.WORKSHOP),
                          value: AppSource.WORKSHOP,
                        },
                      ] as { label: string; value: AppSource }[]
                    }
                    placeholder="Select source.."
                    onChange={(v) => {
                      const val = v as unknown as {
                        label: string;
                        value: AppSource;
                      };
                      setSelectedTaskList({
                        ...selectedTaskList,
                        taskList: selectedTaskList.taskList
                          ? {
                              ...selectedTaskList.taskList,
                              source: val.value,
                            }
                          : selectedTaskList.taskList,
                      });
                    }}
                  />
                </small>
              </div>

              <div className="flex-grow-1">
                <small>
                  <strong>Middleware TransactionType: ()</strong>
                  <Select
                    options={middlewareTransactionTypes.current.map((t) => ({
                      label: `${t.name}`,
                      value: t,
                    }))}
                    value={middlewareTransactionTypes.current
                      .map((t) => ({
                        label: `${t.name}`,
                        value: t,
                      }))
                      .find(
                        (t) =>
                          `${t.value.id}` ===
                          `${selectedTaskList.taskList?.extMiddlewareTransactionTypeId}`
                      )}
                    placeholder="TransactionType.."
                    onChange={(v) => {
                      const val = v as { value: { id?: number | null } };
                      setSelectedTaskList({
                        ...selectedTaskList,
                        taskList: selectedTaskList.taskList
                          ? {
                              ...selectedTaskList.taskList,
                              extMiddlewareTransactionTypeId: val.value.id
                                ? `${val.value.id}`
                                : undefined,
                            }
                          : selectedTaskList.taskList,
                      });
                    }}
                  />
                </small>
              </div>

              <div className="flex-grow-1">
                <small>
                  <strong>Reminder Days</strong>
                  <input
                    onBlur={(e) => {
                      if (!isNaN(parseInt(e.target.value))) {
                        setSelectedTaskList({
                          ...selectedTaskList,
                          taskList: selectedTaskList.taskList
                            ? {
                                ...selectedTaskList.taskList,
                                reminderDays: parseInt(e.target.value),
                              }
                            : selectedTaskList.taskList,
                        });
                      }
                    }}
                    defaultValue={selectedTaskList.taskList?.reminderDays}
                    className="form-control"
                    placeholder="Reminder days before... `"
                  />
                </small>
              </div>
            </div>

            <hr className="border border-dark" />

            <div>
              <small>
                <strong>Job</strong>
              </small>
            </div>
            <div className="d-flex">
              <div className="flex-grow-1">
                <Select
                  options={jobsProto?.jobs}
                  getOptionLabel={(j) => `${j.name}`}
                  value={jobsProto?.jobs.find(
                    (j) =>
                      `${j.masterJavaBaseModel?.id}` ===
                      `${selectedTaskList.taskList?.extJobId}`
                  )}
                  placeholder="Job..."
                  onChange={(v) => {
                    const val = v as unknown as PpicJob;
                    setSelectedTaskList({
                      ...selectedTaskList,
                      taskList: selectedTaskList.taskList
                        ? {
                            ...selectedTaskList.taskList,
                            extJobId: val.masterJavaBaseModel?.id,
                          }
                        : selectedTaskList.taskList,
                    });
                  }}
                />
              </div>
              <div className="flex-grow-1">
                <Select
                  options={ctx?.extCrmCustomers.customers ?? []}
                  getOptionLabel={(c) => `${c.text}`}
                  value={ctx?.extCrmCustomers.customers.find(
                    (c) =>
                      `${c?.value}` ===
                      `${selectedTaskList.taskList?.extCustomerId}`
                  )}
                  placeholder="Customer..."
                  onChange={(v) => {
                    const val = v as unknown as CrmCustomer;
                    setSelectedTaskList({
                      ...selectedTaskList,
                      taskList: selectedTaskList.taskList
                        ? {
                            ...selectedTaskList.taskList,
                            extCustomerId: val.value
                              ? `${val.value}`
                              : undefined,
                          }
                        : selectedTaskList.taskList,
                    });
                  }}
                />
              </div>
            </div>

            <hr className="border border-dark" />

            <small>
              <strong>WO Summary</strong>
            </small>
            <textarea
              className="form-control form-control-sm"
              defaultValue={selectedTaskList.taskList?.name ?? ""}
              placeholder="Description..."
              onBlur={(e) => {
                setSelectedTaskList({
                  ...selectedTaskList,
                  taskList: selectedTaskList.taskList
                    ? {
                        ...selectedTaskList.taskList,
                        name: e.target.value,
                      }
                    : selectedTaskList.taskList,
                });
              }}
            />

            <hr className="border border-dark" />

            <small>
              <strong>Purchase Order</strong>
            </small>
            <div>
              <Select
                placeholder="Select PO.."
                options={pos.current?.purchaseOrders?.map((po) => ({
                  label: `${po.purchaseOrderNumber}: ${po?.account?.name}:${
                    po.products.length ?? 0
                  } item(s)`,
                  value: po,
                }))}
                value={pos.current?.purchaseOrders
                  ?.map((po) => ({
                    label: `${po.purchaseOrderNumber}: ${po?.account?.name}:${
                      po.products.length ?? 0
                    } item(s)`,
                    value: po,
                  }))
                  .find(
                    (p) =>
                      `${p.value.id}` ===
                      `${selectedTaskList.taskList?.extPurchaseOrderId}`
                  )}
                onChange={(v) => {
                  const val = v as { value: CrmPurchaseOrder };
                  if (selectedTaskList) {
                    setSelectedTaskList({
                      ...selectedTaskList,
                      taskList: selectedTaskList.taskList
                        ? {
                            ...selectedTaskList.taskList,
                            extPurchaseOrderId: val.value.id,
                          }
                        : selectedTaskList.taskList,
                    });
                  }
                }}
              />
            </div>
            <small>
              <strong>BPO</strong>
            </small>
            <div>
              {/* {selectedTaskList?.taskList?.extBpoId} */}
              <Select
                placeholder="Select BPO.."
                options={bpos.current?.purchaseOrders?.map((po) => ({
                  label: `${po.bpoNumber}: ${po?.account?.name}:${
                    po.products.length ?? 0
                  } item(s)`,
                  value: po,
                }))}
                value={bpos.current?.purchaseOrders
                  ?.map((po) => ({
                    label: `${po.bpoNumber}: ${po?.account?.name}:${
                      po.products.length ?? 0
                    } item(s)`,
                    value: po,
                  }))
                  .find(
                    (p) =>
                      `${p.value.id}` ===
                      `${selectedTaskList.taskList?.extBpoId}`
                  )}
                onChange={(v) => {
                  const val = v as { value: CrmBPO };
                  if (selectedTaskList) {
                    setSelectedTaskList({
                      ...selectedTaskList,
                      taskList: selectedTaskList.taskList
                        ? {
                            ...selectedTaskList.taskList,
                            extBpoId: val.value.id,
                          }
                        : selectedTaskList.taskList,
                    });
                  }
                }}
              />
            </div>

            <hr className="border border-dark" />

            {selectedTaskList.taskList?.extJobId ? (
              <>
                {" "}
                <div>
                  <small>
                    <strong>Product</strong>
                  </small>
                </div>
                <div>
                  <Select
                    options={
                      jobsProto?.jobs.find(
                        (j) =>
                          `${j.masterJavaBaseModel?.id}` ===
                          `${selectedTaskList.taskList?.extJobId}`
                      )?.panelCodes
                    }
                    getOptionLabel={(c) =>
                      `${c.type}: ${c.name}: ${c.qty} unit(s)`
                    }
                    value={jobsProto?.jobs
                      .map((j) => j.panelCodes)
                      .flat()
                      .find(
                        (pc) =>
                          `${pc.masterJavaBaseModel?.id}` ===
                          `${selectedTaskList.taskList?.extPanelCodeId}`
                      )}
                    onChange={(v) => {
                      const val = v as unknown as PpicPanelCode;
                      setSelectedTaskList({
                        ...selectedTaskList,
                        taskList: selectedTaskList.taskList
                          ? {
                              ...selectedTaskList.taskList,
                              extPanelCodeId: val.masterJavaBaseModel?.id,
                              extJobId: jobsProto?.jobs.find((j) =>
                                j.panelCodes.find(
                                  (pc) =>
                                    `${pc.masterJavaBaseModel?.id}` ===
                                    `${val.masterJavaBaseModel?.id}`
                                )
                              )?.masterJavaBaseModel?.id,
                            }
                          : selectedTaskList.taskList,
                      });
                    }}
                  />
                </div>
                <hr className="border border-dark" />
              </>
            ) : (
              <></>
            )}

            {/* <div>
              <textarea
                className="form-control form-control-sm"
                placeholder="Work order name..."
                defaultValue={selectedTaskList.taskList?.name ?? ""}
                onBlur={(e) => {
                  setSelectedTaskList({
                    ...selectedTaskList,
                    taskList: selectedTaskList.taskList
                      ? {
                          ...selectedTaskList.taskList,
                          name: e.target.value,
                        }
                      : selectedTaskList.taskList,
                  });
                }}
              />
            </div>
            <hr className="border border-dark" /> */}
            <div className="d-flex">
              <strong>Tasks:</strong>
              <div>
                <button
                  className="btn btn-outline-primary btn-sm"
                  onClick={() => {
                    setSelectedTaskList({
                      ...selectedTaskList,
                      taskList: selectedTaskList.taskList
                        ? {
                            ...selectedTaskList.taskList,
                            meetingTasks: [
                              ...selectedTaskList.taskList.meetingTasks,
                              MeetingMeetingTask.fromPartial({
                                masterJavaBaseModel:
                                  MasterJavaBaseModel.fromPartial({
                                    uuid: v4(),
                                  }),
                              }),
                            ],
                          }
                        : selectedTaskList.taskList,
                    });
                  }}
                >
                  <Add /> Add
                </button>
              </div>
              <div className="flex-grow-1">
                <Select
                  placeholder="Add from template..."
                  options={templates.templates}
                  getOptionLabel={(t) => t.name ?? "No name"}
                  onChange={(v) => {
                    const val = v as unknown as MeetingMeetingTaskListTemplate;
                    setSelectedTaskList({
                      ...selectedTaskList,
                      taskList: selectedTaskList.taskList
                        ? {
                            ...selectedTaskList.taskList,
                            meetingTasks: val.items.map((i) => {
                              return MeetingMeetingTask.fromPartial({
                                description: i.name,
                                durationMins: i.workingMins,
                                masterJavaBaseModel:
                                  MasterJavaBaseModel.fromPartial({
                                    uuid: v4(),
                                  }),
                              });
                            }),
                          }
                        : selectedTaskList.taskList,
                    });
                  }}
                />
              </div>
            </div>
            <div>
              <ol>
                {filterMeetingTasks(
                  selectedTaskList.taskList?.meetingTasks,
                  null,
                  null,
                  ctx
                ).map((mt) => {
                  return (
                    <>
                      <li>
                        <div>
                          <div>
                            <small>#{mt.masterJavaBaseModel?.id}</small>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              Description
                              {/* {mt.masterJavaBaseModel?.uuid} */}
                              <textarea
                                key={`mtd-${mt.masterJavaBaseModel?.uuid}`}
                                className="form-control form-control-sm"
                                defaultValue={mt.description}
                                placeholder="Description..."
                                onBlur={(e) => {
                                  setSelectedTaskList({
                                    ...selectedTaskList,
                                    taskList: selectedTaskList.taskList
                                      ? {
                                          ...selectedTaskList.taskList,
                                          meetingTasks: selectedTaskList
                                            .taskList?.meetingTasks
                                            ? selectedTaskList.taskList.meetingTasks.map(
                                                (mtx) =>
                                                  mtx &&
                                                  (mtx.masterJavaBaseModel
                                                    ?.uuid &&
                                                  mtx.masterJavaBaseModel
                                                    .uuid !== ""
                                                    ? `${mt.masterJavaBaseModel?.uuid}` ===
                                                      `${mtx.masterJavaBaseModel?.uuid}`
                                                    : `${mt.masterJavaBaseModel?.id}` ===
                                                      `${mtx.masterJavaBaseModel?.id}`)
                                                    ? {
                                                        ...mtx,
                                                        description:
                                                          e.target.value,
                                                      }
                                                    : mtx
                                              )
                                            : selectedTaskList.taskList
                                                ?.meetingTasks,
                                        }
                                      : selectedTaskList.taskList,
                                  });
                                }}
                              />
                            </div>

                            <div className="flex-grow-1">
                              Start
                              <input
                                className="form-control form-control-sm"
                                type="date"
                                defaultValue={mt.start}
                                onBlur={(e) => {
                                  {
                                    if (e && e.target.value !== "")
                                      setSelectedTaskList({
                                        ...selectedTaskList,
                                        taskList: selectedTaskList.taskList
                                          ? {
                                              ...selectedTaskList.taskList,
                                              meetingTasks: selectedTaskList
                                                .taskList?.meetingTasks
                                                ? selectedTaskList.taskList.meetingTasks.map(
                                                    (mtx) =>
                                                      mtx &&
                                                      (mtx.masterJavaBaseModel
                                                        ?.uuid &&
                                                      mtx.masterJavaBaseModel
                                                        .uuid !== ""
                                                        ? `${mt.masterJavaBaseModel?.uuid}` ===
                                                          `${mtx.masterJavaBaseModel?.uuid}`
                                                        : `${mt.masterJavaBaseModel?.id}` ===
                                                          `${mtx.masterJavaBaseModel?.id}`)
                                                        ? {
                                                            ...mtx,
                                                            start:
                                                              e.target.value,
                                                          }
                                                        : mtx
                                                  )
                                                : selectedTaskList.taskList
                                                    ?.meetingTasks,
                                            }
                                          : selectedTaskList.taskList,
                                      });
                                  }
                                }}
                              />
                            </div>

                            <div className="flex-grow-1">
                              End
                              <input
                                className="form-control form-control-sm"
                                type="date"
                                defaultValue={
                                  extractProtoMeetingTaskTargetDate(mt)?.date
                                }
                                onBlur={(e) => {
                                  {
                                    if (e && e.target.value !== "")
                                      setSelectedTaskList({
                                        ...selectedTaskList,
                                        taskList: selectedTaskList.taskList
                                          ? {
                                              ...selectedTaskList.taskList,
                                              meetingTasks: selectedTaskList
                                                .taskList?.meetingTasks
                                                ? selectedTaskList.taskList.meetingTasks.map(
                                                    (mtx) =>
                                                      mtx &&
                                                      (mtx.masterJavaBaseModel
                                                        ?.uuid &&
                                                      mtx.masterJavaBaseModel
                                                        .uuid !== ""
                                                        ? `${mt.masterJavaBaseModel?.uuid}` ===
                                                          `${mtx.masterJavaBaseModel?.uuid}`
                                                        : `${mt.masterJavaBaseModel?.id}` ===
                                                          `${mtx.masterJavaBaseModel?.id}`)
                                                        ? {
                                                            ...mtx,
                                                            meetingTaskTargetDates:
                                                              [
                                                                ...mt.meetingTaskTargetDates,
                                                                MeetingMeetingTaskTargetDate.fromPartial(
                                                                  {
                                                                    date: e
                                                                      .target
                                                                      .value,
                                                                    masterJavaBaseModel:
                                                                      MasterJavaBaseModel.fromPartial(
                                                                        {
                                                                          uuid: v4(),
                                                                        }
                                                                      ),
                                                                  }
                                                                ),
                                                              ],
                                                          }
                                                        : mtx
                                                  )
                                                : selectedTaskList.taskList
                                                    ?.meetingTasks,
                                            }
                                          : selectedTaskList.taskList,
                                      });
                                  }
                                }}
                              />
                            </div>

                            <div className="flex-grow-1">
                              Working Mins
                              <input
                                placeholder="Time in mins..."
                                className="form-control form-control-sm"
                                defaultValue={mt.durationMins}
                                onBlur={(e) => {
                                  {
                                    if (e && e.target.value !== "")
                                      setSelectedTaskList({
                                        ...selectedTaskList,
                                        taskList: selectedTaskList.taskList
                                          ? {
                                              ...selectedTaskList.taskList,
                                              meetingTasks: selectedTaskList
                                                .taskList?.meetingTasks
                                                ? selectedTaskList.taskList.meetingTasks.map(
                                                    (mtx) =>
                                                      mtx &&
                                                      (mtx.masterJavaBaseModel
                                                        ?.uuid &&
                                                      mtx.masterJavaBaseModel
                                                        .uuid !== ""
                                                        ? `${mt.masterJavaBaseModel?.uuid}` ===
                                                          `${mtx.masterJavaBaseModel?.uuid}`
                                                        : `${mt.masterJavaBaseModel?.id}` ===
                                                          `${mtx.masterJavaBaseModel?.id}`)
                                                        ? {
                                                            ...mtx,
                                                            durationMins: isNaN(
                                                              parseInt(
                                                                e.target.value
                                                              )
                                                            )
                                                              ? mtx.durationMins
                                                              : parseInt(
                                                                  e.target.value
                                                                ),
                                                          }
                                                        : mtx
                                                  )
                                                : selectedTaskList.taskList
                                                    ?.meetingTasks,
                                            }
                                          : selectedTaskList.taskList,
                                      });
                                  }
                                }}
                              />
                            </div>

                            <div className="flex-grow-1">
                              Status
                              {mt.status !== "COMPLETED" ? (
                                <>
                                  <div>
                                    <button
                                      className="btn btn-sm btn-success"
                                      onClick={() => {
                                        setSelectedTaskList({
                                          ...selectedTaskList,
                                          taskList: selectedTaskList.taskList
                                            ? {
                                                ...selectedTaskList.taskList,
                                                meetingTasks: selectedTaskList
                                                  .taskList?.meetingTasks
                                                  ? selectedTaskList.taskList.meetingTasks.map(
                                                      (mtx) =>
                                                        mtx &&
                                                        `${mt.masterJavaBaseModel?.uuid}` ===
                                                          `${mtx.masterJavaBaseModel?.uuid}`
                                                          ? {
                                                              ...mtx,
                                                              status:
                                                                "COMPLETED",
                                                              completedDate:
                                                                makeDateString(
                                                                  new Date()
                                                                ),
                                                            }
                                                          : mtx
                                                    )
                                                  : selectedTaskList.taskList
                                                      ?.meetingTasks,
                                              }
                                            : selectedTaskList.taskList,
                                        });
                                      }}
                                    >
                                      Complete
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              {mt.status === "COMPLETED" ? (
                                <>
                                  <div>
                                    {mt.completedDate ? (
                                      <>{mt.completedDate}</>
                                    ) : (
                                      <></>
                                    )}
                                    <input
                                      className="form-control form-control-sm"
                                      type="date"
                                      defaultValue={mt.completedDate}
                                      onBlur={(e) => {
                                        if (e.target.value !== "") {
                                          setSelectedTaskList({
                                            ...selectedTaskList,
                                            taskList: selectedTaskList.taskList
                                              ? {
                                                  ...selectedTaskList.taskList,
                                                  meetingTasks: selectedTaskList
                                                    .taskList?.meetingTasks
                                                    ? selectedTaskList.taskList.meetingTasks.map(
                                                        (mtx) =>
                                                          mtx &&
                                                          `${mt.masterJavaBaseModel?.uuid}` ===
                                                            `${mtx.masterJavaBaseModel?.uuid}`
                                                            ? {
                                                                ...mtx,
                                                                status:
                                                                  "COMPLETED",
                                                                completedDate:
                                                                  e.target
                                                                    .value,
                                                              }
                                                            : mtx
                                                      )
                                                    : selectedTaskList.taskList
                                                        ?.meetingTasks,
                                                }
                                              : selectedTaskList.taskList,
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <button
                                      className="btn btn-sm btn-secondary"
                                      onClick={() => {
                                        setSelectedTaskList({
                                          ...selectedTaskList,
                                          taskList: selectedTaskList.taskList
                                            ? {
                                                ...selectedTaskList.taskList,
                                                meetingTasks: selectedTaskList
                                                  .taskList?.meetingTasks
                                                  ? selectedTaskList.taskList.meetingTasks.map(
                                                      (mtx) =>
                                                        mtx &&
                                                        (mtx.masterJavaBaseModel
                                                          ?.uuid &&
                                                        mtx.masterJavaBaseModel
                                                          .uuid !== ""
                                                          ? `${mt.masterJavaBaseModel?.uuid}` ===
                                                            `${mtx.masterJavaBaseModel?.uuid}`
                                                          : `${mt.masterJavaBaseModel?.id}` ===
                                                            `${mtx.masterJavaBaseModel?.id}`)
                                                          ? {
                                                              ...mtx,
                                                              status:
                                                                "OUTSTANDING",
                                                            }
                                                          : mtx
                                                    )
                                                  : selectedTaskList.taskList
                                                      ?.meetingTasks,
                                              }
                                            : selectedTaskList.taskList,
                                        });
                                      }}
                                    >
                                      Undo
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>

                            <div className="flex-grow-1">
                              PIC Status
                              {mt.extPicCompletedDate ? (
                                <>
                                  <div>
                                    <div className="font-weight-bold text-success">
                                      <small>Completed</small>
                                    </div>
                                    <div>{mt.extPicCompletedDate}</div>
                                    <div>
                                      <button
                                        className="btn btn-sm btn-primary"
                                        onClick={() => {
                                          setSelectedTaskList({
                                            ...selectedTaskList,
                                            taskList: selectedTaskList.taskList
                                              ? {
                                                  ...selectedTaskList.taskList,
                                                  meetingTasks: selectedTaskList
                                                    .taskList?.meetingTasks
                                                    ? selectedTaskList.taskList.meetingTasks.map(
                                                        (mtx) =>
                                                          mtx &&
                                                          (mtx
                                                            .masterJavaBaseModel
                                                            ?.uuid &&
                                                          mtx
                                                            .masterJavaBaseModel
                                                            .uuid !== ""
                                                            ? `${mt.masterJavaBaseModel?.uuid}` ===
                                                              `${mtx.masterJavaBaseModel?.uuid}`
                                                            : `${mt.masterJavaBaseModel?.id}` ===
                                                              `${mtx.masterJavaBaseModel?.id}`)
                                                            ? {
                                                                ...mtx,
                                                                extPicCompletedDate:
                                                                  undefined,
                                                              }
                                                            : mtx
                                                      )
                                                    : selectedTaskList.taskList
                                                        ?.meetingTasks,
                                                }
                                              : selectedTaskList.taskList,
                                          });
                                        }}
                                      >
                                        Undo
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div>
                                    <input
                                      // defaultValue={}
                                      className="form-control form-control-sm"
                                      type="date"
                                      defaultValue={mt.extPicCompletedDate}
                                      onBlur={(e) => {
                                        // alert(e.target.value);
                                        if (e.target.value !== "") {
                                          setSelectedTaskList({
                                            ...selectedTaskList,
                                            taskList: selectedTaskList.taskList
                                              ? {
                                                  ...selectedTaskList.taskList,
                                                  meetingTasks: selectedTaskList
                                                    .taskList?.meetingTasks
                                                    ? selectedTaskList.taskList.meetingTasks.map(
                                                        (mtx) =>
                                                          mtx &&
                                                          `${mt.masterJavaBaseModel?.uuid}` ===
                                                            `${mtx.masterJavaBaseModel?.uuid}`
                                                            ? {
                                                                ...mtx,
                                                                extPicCompletedDate:
                                                                  e.target
                                                                    .value,
                                                                // new Date(
                                                                //   e.target.value
                                                                // ).toISOString(),
                                                              }
                                                            : mtx
                                                      )
                                                    : selectedTaskList.taskList
                                                        ?.meetingTasks,
                                                }
                                              : selectedTaskList.taskList,
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <small>
                              <strong>PICs:</strong>
                            </small>
                            <div className="mx-2 flex-grow-1">
                              <Select
                                options={ctx?.extUsers ?? []}
                                placeholder="Select PIC.."
                                getOptionLabel={(u) =>
                                  `${u.name} (${u.departmentName})`
                                }
                                onChange={(v) => {
                                  const val = v as unknown as ExtUser;

                                  setSelectedTaskList({
                                    ...selectedTaskList,
                                    taskList: selectedTaskList.taskList
                                      ? {
                                          ...selectedTaskList.taskList,
                                          meetingTasks: selectedTaskList
                                            .taskList?.meetingTasks
                                            ? selectedTaskList.taskList.meetingTasks.map(
                                                (mtx) =>
                                                  mtx &&
                                                  (mtx.masterJavaBaseModel
                                                    ?.uuid &&
                                                  mtx.masterJavaBaseModel
                                                    .uuid !== ""
                                                    ? `${mt.masterJavaBaseModel?.uuid}` ===
                                                      `${mtx.masterJavaBaseModel?.uuid}`
                                                    : `${mt.masterJavaBaseModel?.id}` ===
                                                      `${mtx.masterJavaBaseModel?.id}`)
                                                    ? {
                                                        ...mtx,
                                                        meetingTaskInCharges: [
                                                          ...mt.meetingTaskInCharges,
                                                          MeetingMeetingTaskInCharge.fromPartial(
                                                            {
                                                              extUserId: val.id
                                                                ? `${val.id}`
                                                                : undefined,
                                                              masterJavaBaseModel:
                                                                MasterJavaBaseModel.fromPartial(
                                                                  {
                                                                    uuid: v4(),
                                                                  }
                                                                ),
                                                            }
                                                          ),
                                                        ],
                                                      }
                                                    : mtx
                                              )
                                            : selectedTaskList.taskList
                                                ?.meetingTasks,
                                        }
                                      : selectedTaskList.taskList,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <small>
                              <strong>
                                RCEM:{" "}
                                {(() => {
                                  const foundRcem = rcemDepartments?.departments
                                    .map((d) =>
                                      d.processes
                                        .map((p) =>
                                          p.subProcesses.map((s) => ({
                                            dept: d,
                                            process: p,
                                            subProcess: s,
                                          }))
                                        )
                                        .flat()
                                    )
                                    .flat()
                                    .find(
                                      (d) =>
                                        `${d.subProcess.masterJavaBaseModel?.id}` ===
                                        `${mt.extRcemSubProcessId}`
                                    );

                                  if (foundRcem) {
                                    return (
                                      <>
                                        {foundRcem.dept.name}:
                                        {foundRcem.process.name}:
                                        {foundRcem.subProcess.name}:
                                        {foundRcem.subProcess.timeProcess}h
                                      </>
                                    );
                                  } else {
                                    return <></>;
                                  }
                                })()}
                              </strong>
                              <Select
                                options={rcemDepartments?.departments
                                  .map((d) =>
                                    d.processes
                                      .map((p) =>
                                        p.subProcesses.map((s) => ({
                                          dept: d,
                                          process: p,
                                          subProcess: s,
                                        }))
                                      )
                                      .flat()
                                  )
                                  .flat()}
                                placeholder="Select RCEM.."
                                getOptionLabel={(p) =>
                                  `${p.dept?.name}: ${p.process.name}: ${p.subProcess.name}:${p.subProcess.timeProcess}h`
                                }
                                onChange={(v) => {
                                  const val = v as unknown as {
                                    dept: PpicRcemDepartment;
                                    process: PpicRcemProcess;
                                    subProcess: PpicRcemSubProcess;
                                  };

                                  setSelectedTaskList({
                                    ...selectedTaskList,
                                    taskList: selectedTaskList.taskList
                                      ? {
                                          ...selectedTaskList.taskList,
                                          meetingTasks: selectedTaskList
                                            .taskList?.meetingTasks
                                            ? selectedTaskList.taskList.meetingTasks.map(
                                                (mtx) =>
                                                  mtx &&
                                                  (mtx.masterJavaBaseModel
                                                    ?.uuid &&
                                                  mtx.masterJavaBaseModel
                                                    .uuid !== ""
                                                    ? `${mt.masterJavaBaseModel?.uuid}` ===
                                                      `${mtx.masterJavaBaseModel?.uuid}`
                                                    : `${mt.masterJavaBaseModel?.id}` ===
                                                      `${mtx.masterJavaBaseModel?.id}`)
                                                    ? {
                                                        ...mtx,
                                                        extRcemSubProcessId:
                                                          val.subProcess
                                                            .masterJavaBaseModel
                                                            ?.id,
                                                      }
                                                    : mtx
                                              )
                                            : selectedTaskList.taskList
                                                ?.meetingTasks,
                                        }
                                      : selectedTaskList.taskList,
                                  });
                                }}
                              />
                            </small>
                          </div>
                          <div>
                            <ol>
                              {mt.meetingTaskInCharges.map((mtic) => {
                                const fUser = ctx?.extUsers.find(
                                  (u) => `${u.id}` === `${mtic.extUserId}`
                                );
                                return (
                                  <>
                                    <li>
                                      <div className="d-flex">
                                        <div
                                          style={{ cursor: "pointer" }}
                                          className="px-1"
                                          onClick={() => {
                                            setSelectedTaskList({
                                              ...selectedTaskList,
                                              meetingTaskInChargeDeleteIds: [
                                                ...selectedTaskList.meetingTaskInChargeDeleteIds,
                                                mtic.masterJavaBaseModel?.id ??
                                                  "",
                                              ],
                                              taskList:
                                                selectedTaskList.taskList
                                                  ? {
                                                      ...selectedTaskList.taskList,
                                                      meetingTasks:
                                                        selectedTaskList
                                                          .taskList
                                                          ?.meetingTasks
                                                          ? selectedTaskList.taskList.meetingTasks.map(
                                                              (mtx) =>
                                                                mtx &&
                                                                (mtx
                                                                  .masterJavaBaseModel
                                                                  ?.uuid &&
                                                                mtx
                                                                  .masterJavaBaseModel
                                                                  .uuid !== ""
                                                                  ? `${mt.masterJavaBaseModel?.uuid}` ===
                                                                    `${mtx.masterJavaBaseModel?.uuid}`
                                                                  : `${mt.masterJavaBaseModel?.id}` ===
                                                                    `${mtx.masterJavaBaseModel?.id}`)
                                                                  ? {
                                                                      ...mtx,
                                                                      meetingTaskInCharges:
                                                                        mtx.meetingTaskInCharges.map(
                                                                          (
                                                                            mticx
                                                                          ) =>
                                                                            `${mticx.extUserId}` ===
                                                                            `${mtic.extUserId}`
                                                                              ? {
                                                                                  ...mticx,
                                                                                  isQc: !mticx.isQc,
                                                                                }
                                                                              : mticx
                                                                        ),
                                                                    }
                                                                  : mtx
                                                            )
                                                          : selectedTaskList
                                                              .taskList
                                                              ?.meetingTasks,
                                                    }
                                                  : selectedTaskList.taskList,
                                            });
                                          }}
                                        >
                                          QC
                                          <input
                                            type="checkbox"
                                            checked={mtic.isQc ?? false}
                                          />
                                        </div>
                                        <div
                                          style={{ cursor: "pointer" }}
                                          className="px-1"
                                          onClick={() => {
                                            setSelectedTaskList({
                                              ...selectedTaskList,
                                              meetingTaskInChargeDeleteIds: [
                                                ...selectedTaskList.meetingTaskInChargeDeleteIds,
                                                mtic.masterJavaBaseModel?.id ??
                                                  "",
                                              ],
                                              taskList:
                                                selectedTaskList.taskList
                                                  ? {
                                                      ...selectedTaskList.taskList,
                                                      meetingTasks:
                                                        selectedTaskList
                                                          .taskList
                                                          ?.meetingTasks
                                                          ? selectedTaskList.taskList.meetingTasks.map(
                                                              (mtx) =>
                                                                mtx &&
                                                                (mtx
                                                                  .masterJavaBaseModel
                                                                  ?.uuid &&
                                                                mtx
                                                                  .masterJavaBaseModel
                                                                  .uuid !== ""
                                                                  ? `${mt.masterJavaBaseModel?.uuid}` ===
                                                                    `${mtx.masterJavaBaseModel?.uuid}`
                                                                  : `${mt.masterJavaBaseModel?.id}` ===
                                                                    `${mtx.masterJavaBaseModel?.id}`)
                                                                  ? {
                                                                      ...mtx,
                                                                      meetingTaskInCharges:
                                                                        mtx.meetingTaskInCharges.map(
                                                                          (
                                                                            mticx
                                                                          ) =>
                                                                            `${mticx.extUserId}` ===
                                                                            `${mtic.extUserId}`
                                                                              ? {
                                                                                  ...mticx,
                                                                                  isQa: !mticx.isQa,
                                                                                }
                                                                              : mticx
                                                                        ),
                                                                    }
                                                                  : mtx
                                                            )
                                                          : selectedTaskList
                                                              .taskList
                                                              ?.meetingTasks,
                                                    }
                                                  : selectedTaskList.taskList,
                                            });
                                          }}
                                        >
                                          QA
                                          <input
                                            type="checkbox"
                                            checked={mtic.isQa ?? false}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        onClick={() => {
                                          setSelectedTaskList({
                                            ...selectedTaskList,
                                            meetingTaskInChargeDeleteIds: [
                                              ...selectedTaskList.meetingTaskInChargeDeleteIds,
                                              mtic.masterJavaBaseModel?.id ??
                                                "",
                                            ],
                                            taskList: selectedTaskList.taskList
                                              ? {
                                                  ...selectedTaskList.taskList,
                                                  meetingTasks: selectedTaskList
                                                    .taskList?.meetingTasks
                                                    ? selectedTaskList.taskList.meetingTasks.map(
                                                        (mtx) =>
                                                          mtx &&
                                                          (mtx
                                                            .masterJavaBaseModel
                                                            ?.uuid &&
                                                          mtx
                                                            .masterJavaBaseModel
                                                            .uuid !== ""
                                                            ? `${mt.masterJavaBaseModel?.uuid}` ===
                                                              `${mtx.masterJavaBaseModel?.uuid}`
                                                            : `${mt.masterJavaBaseModel?.id}` ===
                                                              `${mtx.masterJavaBaseModel?.id}`)
                                                            ? {
                                                                ...mtx,
                                                                meetingTaskInCharges:
                                                                  mtx.meetingTaskInCharges.filter(
                                                                    (mticx) =>
                                                                      `${mticx.extUserId}` !==
                                                                      `${mtic.extUserId}`
                                                                  ),
                                                              }
                                                            : mtx
                                                      )
                                                    : selectedTaskList.taskList
                                                        ?.meetingTasks,
                                                }
                                              : selectedTaskList.taskList,
                                          });
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <div>
                                          {fUser?.name} ({fUser?.departmentName}
                                          )
                                        </div>
                                      </div>
                                    </li>
                                  </>
                                );
                              })}
                            </ol>{" "}
                          </div>
                          <div className="d-flex">
                            <small>
                              <strong>Problems:</strong>
                            </small>
                            <div className="flex-grow-1">
                              <Select
                                placeholder="Problem catalog..."
                                getOptionLabel={(c) =>
                                  `${
                                    ctx?.extDepartments.find(
                                      (d) =>
                                        `${d.id}` === `${c.extDepartmentId}`
                                    )?.name
                                  }: ${
                                    c.problemType
                                      ? meetingProblemTypeToJSON(c.problemType)
                                      : ""
                                  } ${c.problemIntIdentifier}: ${
                                    ctx?.extDepartments.find(
                                      (d) =>
                                        `${d.id}` ===
                                        `${c.extDepartmentVersusId}`
                                    )?.name
                                  }`
                                }
                                options={problemCatalogs.catalogs}
                                onChange={(v) => {
                                  const val =
                                    v as unknown as MeetingProblemCatalog;

                                  setSelectedTaskList({
                                    ...selectedTaskList,
                                    taskList: selectedTaskList.taskList
                                      ? {
                                          ...selectedTaskList.taskList,
                                          meetingTasks: selectedTaskList
                                            .taskList?.meetingTasks
                                            ? selectedTaskList.taskList.meetingTasks.map(
                                                (mtx) =>
                                                  mtx &&
                                                  (mtx.masterJavaBaseModel
                                                    ?.uuid &&
                                                  mtx.masterJavaBaseModel
                                                    .uuid !== ""
                                                    ? `${mt.masterJavaBaseModel?.uuid}` ===
                                                      `${mtx.masterJavaBaseModel?.uuid}`
                                                    : `${mt.masterJavaBaseModel?.id}` ===
                                                      `${mtx.masterJavaBaseModel?.id}`)
                                                    ? {
                                                        ...mtx,
                                                        meetingTaskProblemCatalogs:
                                                          !mtx.meetingTaskProblemCatalogs.find(
                                                            (c) =>
                                                              `${c.problemCatalogId}` ===
                                                              `${val.masterJavaBaseModel?.id}`
                                                          )
                                                            ? [
                                                                ...mtx.meetingTaskProblemCatalogs,
                                                                MeetingMeetingTaskProblemCatalog.fromPartial(
                                                                  {
                                                                    problemCatalogId:
                                                                      val
                                                                        .masterJavaBaseModel
                                                                        ?.id,
                                                                    masterJavaBaseModel:
                                                                      MasterJavaBaseModel.fromPartial(
                                                                        {
                                                                          uuid: v4(),
                                                                        }
                                                                      ),
                                                                  }
                                                                ),
                                                              ]
                                                            : mtx.meetingTaskProblemCatalogs,
                                                      }
                                                    : mtx
                                              )
                                            : selectedTaskList.taskList
                                                ?.meetingTasks,
                                        }
                                      : selectedTaskList.taskList,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div>
                            <ol>
                              {mt.meetingTaskProblemCatalogs.map((c) => {
                                const foundProblemCatalog =
                                  problemCatalogs.catalogs.find(
                                    (cx) =>
                                      `${cx.masterJavaBaseModel?.id}` ===
                                      `${c.problemCatalogId}`
                                  );
                                return (
                                  <>
                                    <li>
                                      <div>
                                        <div>
                                          {foundProblemCatalog?.problemType ? (
                                            <>
                                              <small
                                                onClick={() => {
                                                  setMeetingTaskProblemCatalogDeleteIds(
                                                    [
                                                      ...meetingTaskProblemCatalogDeleteIds,
                                                      c.masterJavaBaseModel
                                                        ?.id ?? "",
                                                    ]
                                                  );
                                                  setSelectedTaskList({
                                                    ...selectedTaskList,
                                                    taskList:
                                                      selectedTaskList.taskList
                                                        ? {
                                                            ...selectedTaskList.taskList,
                                                            meetingTasks:
                                                              selectedTaskList
                                                                .taskList
                                                                ?.meetingTasks
                                                                ? selectedTaskList.taskList.meetingTasks.map(
                                                                    (mtx) =>
                                                                      mtx &&
                                                                      (mtx
                                                                        .masterJavaBaseModel
                                                                        ?.uuid &&
                                                                      mtx
                                                                        .masterJavaBaseModel
                                                                        .uuid !==
                                                                        ""
                                                                        ? `${mt.masterJavaBaseModel?.uuid}` ===
                                                                          `${mtx.masterJavaBaseModel?.uuid}`
                                                                        : `${mt.masterJavaBaseModel?.id}` ===
                                                                          `${mtx.masterJavaBaseModel?.id}`)
                                                                        ? {
                                                                            ...mtx,
                                                                            meetingTaskProblemCatalogs:
                                                                              mtx.meetingTaskProblemCatalogs.filter(
                                                                                (
                                                                                  cx
                                                                                ) =>
                                                                                  `${cx.masterJavaBaseModel?.uuid}` !==
                                                                                  `${c.masterJavaBaseModel?.uuid}`
                                                                              ),
                                                                          }
                                                                        : mtx
                                                                  )
                                                                : selectedTaskList
                                                                    .taskList
                                                                    ?.meetingTasks,
                                                          }
                                                        : selectedTaskList.taskList,
                                                  });
                                                }}
                                                className="text-light rounded rounded-lg px-2 py-1"
                                                style={{
                                                  cursor: "pointer",
                                                  backgroundColor:
                                                    new ColorHash().hex(
                                                      meetingProblemTypeToJSON(
                                                        foundProblemCatalog.problemType
                                                      )
                                                    ),
                                                }}
                                              >
                                                <strong>
                                                  {meetingProblemTypeToJSON(
                                                    foundProblemCatalog?.problemType
                                                  )}
                                                  :
                                                  {ctx?.extDepartments.find(
                                                    (d) =>
                                                      `${d.id}` ===
                                                      `${foundProblemCatalog.extDepartmentId}`
                                                  )?.name ?? "No dept"}
                                                  {"->"}
                                                  {ctx?.extDepartments.find(
                                                    (d) =>
                                                      `${d.id}` ===
                                                      `${foundProblemCatalog.extDepartmentVersusId}`
                                                  )?.name ?? "No dept"}
                                                </strong>
                                              </small>
                                            </>
                                          ) : (
                                            "No type"
                                          )}{" "}
                                          {/* {foundProblemCatalog?.alias} */}
                                        </div>
                                        <div className="d-flex">
                                          <div className="flex-grow-1">
                                            {" "}
                                            <div>
                                              <textarea
                                                className="form-control form-control-sm"
                                                placeholder="Remark..."
                                                defaultValue={c.remark ?? ""}
                                                key={
                                                  c.masterJavaBaseModel?.uuid
                                                }
                                                onBlur={(e) => {
                                                  setSelectedTaskList({
                                                    ...selectedTaskList,
                                                    taskList:
                                                      selectedTaskList.taskList
                                                        ? {
                                                            ...selectedTaskList.taskList,
                                                            meetingTasks:
                                                              selectedTaskList
                                                                .taskList
                                                                ?.meetingTasks
                                                                ? selectedTaskList.taskList.meetingTasks.map(
                                                                    (mtx) =>
                                                                      mtx &&
                                                                      (mtx
                                                                        .masterJavaBaseModel
                                                                        ?.uuid &&
                                                                      mtx
                                                                        .masterJavaBaseModel
                                                                        .uuid !==
                                                                        ""
                                                                        ? `${mt.masterJavaBaseModel?.uuid}` ===
                                                                          `${mtx.masterJavaBaseModel?.uuid}`
                                                                        : `${mt.masterJavaBaseModel?.id}` ===
                                                                          `${mtx.masterJavaBaseModel?.id}`)
                                                                        ? {
                                                                            ...mtx,
                                                                            meetingTaskProblemCatalogs:
                                                                              mtx.meetingTaskProblemCatalogs.map(
                                                                                (
                                                                                  cx
                                                                                ) =>
                                                                                  `${cx.masterJavaBaseModel?.uuid}` ===
                                                                                  `${c.masterJavaBaseModel?.uuid}`
                                                                                    ? {
                                                                                        ...cx,
                                                                                        remark:
                                                                                          e
                                                                                            .target
                                                                                            .value,
                                                                                      }
                                                                                    : cx
                                                                              ),
                                                                          }
                                                                        : mtx
                                                                  )
                                                                : selectedTaskList
                                                                    .taskList
                                                                    ?.meetingTasks,
                                                          }
                                                        : selectedTaskList.taskList,
                                                  });
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div className="flex-grow-1">
                                            <div>Target date</div>
                                            <div>
                                              <input
                                                className="form-control form-control-sm"
                                                type="date"
                                                key={`prob-catalog-date-${c.masterJavaBaseModel?.uuid} `}
                                                defaultValue={c.targetDate}
                                                onBlur={(e) => {
                                                  if (e.target.value !== "") {
                                                    setSelectedTaskList({
                                                      ...selectedTaskList,
                                                      taskList:
                                                        selectedTaskList.taskList
                                                          ? {
                                                              ...selectedTaskList.taskList,
                                                              meetingTasks:
                                                                selectedTaskList
                                                                  .taskList
                                                                  ?.meetingTasks
                                                                  ? selectedTaskList.taskList.meetingTasks.map(
                                                                      (mtx) =>
                                                                        mtx &&
                                                                        (mtx
                                                                          .masterJavaBaseModel
                                                                          ?.uuid &&
                                                                        mtx
                                                                          .masterJavaBaseModel
                                                                          .uuid !==
                                                                          ""
                                                                          ? `${mt.masterJavaBaseModel?.uuid}` ===
                                                                            `${mtx.masterJavaBaseModel?.uuid}`
                                                                          : `${mt.masterJavaBaseModel?.id}` ===
                                                                            `${mtx.masterJavaBaseModel?.id}`)
                                                                          ? {
                                                                              ...mtx,
                                                                              meetingTaskProblemCatalogs:
                                                                                mtx.meetingTaskProblemCatalogs.map(
                                                                                  (
                                                                                    cx
                                                                                  ) =>
                                                                                    `${cx.masterJavaBaseModel?.uuid}` ===
                                                                                    `${c.masterJavaBaseModel?.uuid}`
                                                                                      ? {
                                                                                          ...cx,
                                                                                          targetDate:
                                                                                            e
                                                                                              .target
                                                                                              .value,
                                                                                        }
                                                                                      : cx
                                                                                ),
                                                                            }
                                                                          : mtx
                                                                    )
                                                                  : selectedTaskList
                                                                      .taskList
                                                                      ?.meetingTasks,
                                                            }
                                                          : selectedTaskList.taskList,
                                                    });
                                                  }
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div className="flex-grow-1">
                                            <div>Problem PIC</div>
                                            <Select
                                              placeholder="Select user..."
                                              onChange={(v) => {
                                                const val = v as {
                                                  value: ExtUser;
                                                };

                                                setSelectedTaskList({
                                                  ...selectedTaskList,
                                                  taskList:
                                                    selectedTaskList.taskList
                                                      ? {
                                                          ...selectedTaskList.taskList,
                                                          meetingTasks:
                                                            selectedTaskList
                                                              .taskList
                                                              ?.meetingTasks
                                                              ? selectedTaskList.taskList.meetingTasks.map(
                                                                  (mtx) =>
                                                                    mtx &&
                                                                    (mtx
                                                                      .masterJavaBaseModel
                                                                      ?.uuid &&
                                                                    mtx
                                                                      .masterJavaBaseModel
                                                                      .uuid !==
                                                                      ""
                                                                      ? `${mt.masterJavaBaseModel?.uuid}` ===
                                                                        `${mtx.masterJavaBaseModel?.uuid}`
                                                                      : `${mt.masterJavaBaseModel?.id}` ===
                                                                        `${mtx.masterJavaBaseModel?.id}`)
                                                                      ? {
                                                                          ...mtx,
                                                                          meetingTaskProblemCatalogs:
                                                                            mtx.meetingTaskProblemCatalogs.map(
                                                                              (
                                                                                cx
                                                                              ) =>
                                                                                `${cx.masterJavaBaseModel?.uuid}` ===
                                                                                `${c.masterJavaBaseModel?.uuid}`
                                                                                  ? {
                                                                                      ...cx,
                                                                                      extUserId:
                                                                                        val
                                                                                          .value
                                                                                          .id
                                                                                          ? `${val.value.id}`
                                                                                          : undefined,
                                                                                    }
                                                                                  : cx
                                                                            ),
                                                                        }
                                                                      : mtx
                                                                )
                                                              : selectedTaskList
                                                                  .taskList
                                                                  ?.meetingTasks,
                                                        }
                                                      : selectedTaskList.taskList,
                                                });
                                              }}
                                              options={ctx?.extUsers.map(
                                                (u) => ({
                                                  label: `${u.username} (${
                                                    ctx.extDepartments.find(
                                                      (d) =>
                                                        `${d.id}` ===
                                                        `${u.departmentId}`
                                                    )?.name
                                                  })`,
                                                  value: u,
                                                })
                                              )}
                                              value={ctx?.extUsers
                                                .map((u) => ({
                                                  label: `${u.username} (${
                                                    ctx.extDepartments.find(
                                                      (d) =>
                                                        `${d.id}` ===
                                                        `${u.departmentId}`
                                                    )?.name
                                                  })`,
                                                  value: u,
                                                }))
                                                .find(
                                                  (u) =>
                                                    `${u.value.id}` ===
                                                    `${c.extUserId}`
                                                )}
                                            />
                                            {/* {c.extUserId} */}
                                          </div>
                                          <div className="flex-grow-1">
                                            Status
                                            <div className="d-flex">
                                              <button
                                                className={`btn btn-sm ${
                                                  meetingTaskProblemCatalogStatusFromJSON(
                                                    c.status
                                                  ) ===
                                                  MeetingTaskProblemCatalogStatus.PROBLEM_STATUS_OPEN
                                                    ? `btn-danger`
                                                    : `btn-outline-danger`
                                                }`}
                                                onClick={() => {
                                                  setSelectedTaskList({
                                                    ...selectedTaskList,
                                                    taskList:
                                                      selectedTaskList.taskList
                                                        ? {
                                                            ...selectedTaskList.taskList,
                                                            meetingTasks:
                                                              selectedTaskList
                                                                .taskList
                                                                ?.meetingTasks
                                                                ? selectedTaskList.taskList.meetingTasks.map(
                                                                    (mtx) =>
                                                                      mtx &&
                                                                      (mtx
                                                                        .masterJavaBaseModel
                                                                        ?.uuid &&
                                                                      mtx
                                                                        .masterJavaBaseModel
                                                                        .uuid !==
                                                                        ""
                                                                        ? `${mt.masterJavaBaseModel?.uuid}` ===
                                                                          `${mtx.masterJavaBaseModel?.uuid}`
                                                                        : `${mt.masterJavaBaseModel?.id}` ===
                                                                          `${mtx.masterJavaBaseModel?.id}`)
                                                                        ? {
                                                                            ...mtx,
                                                                            meetingTaskProblemCatalogs:
                                                                              mtx.meetingTaskProblemCatalogs.map(
                                                                                (
                                                                                  cx
                                                                                ) =>
                                                                                  `${cx.masterJavaBaseModel?.uuid}` ===
                                                                                  `${c.masterJavaBaseModel?.uuid}`
                                                                                    ? {
                                                                                        ...cx,
                                                                                        status:
                                                                                          MeetingTaskProblemCatalogStatus.PROBLEM_STATUS_OPEN,
                                                                                      }
                                                                                    : cx
                                                                              ),
                                                                          }
                                                                        : mtx
                                                                  )
                                                                : selectedTaskList
                                                                    .taskList
                                                                    ?.meetingTasks,
                                                          }
                                                        : selectedTaskList.taskList,
                                                  });
                                                }}
                                              >
                                                Open
                                              </button>
                                              <button
                                                className={`btn btn-sm ${
                                                  meetingTaskProblemCatalogStatusFromJSON(
                                                    c.status
                                                  ) ===
                                                  MeetingTaskProblemCatalogStatus.PROBLEM_STATUS_CLOSED
                                                    ? `btn-success`
                                                    : `btn-outline-success`
                                                }`}
                                                onClick={() => {
                                                  setSelectedTaskList({
                                                    ...selectedTaskList,
                                                    taskList:
                                                      selectedTaskList.taskList
                                                        ? {
                                                            ...selectedTaskList.taskList,
                                                            meetingTasks:
                                                              selectedTaskList
                                                                .taskList
                                                                ?.meetingTasks
                                                                ? selectedTaskList.taskList.meetingTasks.map(
                                                                    (mtx) =>
                                                                      mtx &&
                                                                      (mtx
                                                                        .masterJavaBaseModel
                                                                        ?.uuid &&
                                                                      mtx
                                                                        .masterJavaBaseModel
                                                                        .uuid !==
                                                                        ""
                                                                        ? `${mt.masterJavaBaseModel?.uuid}` ===
                                                                          `${mtx.masterJavaBaseModel?.uuid}`
                                                                        : `${mt.masterJavaBaseModel?.id}` ===
                                                                          `${mtx.masterJavaBaseModel?.id}`)
                                                                        ? {
                                                                            ...mtx,
                                                                            meetingTaskProblemCatalogs:
                                                                              mtx.meetingTaskProblemCatalogs.map(
                                                                                (
                                                                                  cx
                                                                                ) =>
                                                                                  `${cx.masterJavaBaseModel?.uuid}` ===
                                                                                  `${c.masterJavaBaseModel?.uuid}`
                                                                                    ? {
                                                                                        ...cx,
                                                                                        status:
                                                                                          MeetingTaskProblemCatalogStatus.PROBLEM_STATUS_CLOSED,
                                                                                      }
                                                                                    : cx
                                                                              ),
                                                                          }
                                                                        : mtx
                                                                  )
                                                                : selectedTaskList
                                                                    .taskList
                                                                    ?.meetingTasks,
                                                          }
                                                        : selectedTaskList.taskList,
                                                  });
                                                }}
                                              >
                                                Closed
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </>
                                );
                              })}
                            </ol>{" "}
                          </div>
                          <div>
                            <hr className="border border-dark" />
                          </div>
                          <div className="d-flex">
                            <small>
                              <strong>Comments:</strong>
                            </small>
                          </div>
                          <div>
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => {
                                setSelectedTaskList({
                                  ...selectedTaskList,
                                  taskList: selectedTaskList.taskList
                                    ? {
                                        ...selectedTaskList.taskList,
                                        meetingTasks: selectedTaskList.taskList
                                          ?.meetingTasks
                                          ? selectedTaskList.taskList.meetingTasks.map(
                                              (mtx) =>
                                                mtx &&
                                                (mtx.masterJavaBaseModel
                                                  ?.uuid &&
                                                mtx.masterJavaBaseModel.uuid !==
                                                  ""
                                                  ? `${mt.masterJavaBaseModel?.uuid}` ===
                                                    `${mtx.masterJavaBaseModel?.uuid}`
                                                  : `${mt.masterJavaBaseModel?.id}` ===
                                                    `${mtx.masterJavaBaseModel?.id}`)
                                                  ? {
                                                      ...mtx,
                                                      meetingTaskComments:
                                                        mtx.meetingTaskComments
                                                          ? [
                                                              ...mtx.meetingTaskComments,
                                                              MeetingMeetingTaskComment.fromPartial(
                                                                {
                                                                  masterJavaBaseModel:
                                                                    MasterJavaBaseModel.fromPartial(
                                                                      {
                                                                        uuid: v4(),
                                                                      }
                                                                    ),
                                                                }
                                                              ),
                                                            ]
                                                          : mtx.meetingTaskComments,
                                                    }
                                                  : mtx
                                            )
                                          : selectedTaskList.taskList
                                              ?.meetingTasks,
                                      }
                                    : selectedTaskList.taskList,
                                });
                              }}
                            >
                              <Add /> Add comment
                            </button>
                          </div>
                          <div>
                            {mt.meetingTaskComments.map((c, j) => {
                              return (
                                <>
                                  <div>
                                    <div>
                                      {j + 1}. By:{" "}
                                      {ctx?.extUsers.find(
                                        (u) => `${u.id}` === `${c.extUserId}`
                                      )?.username ?? "Admin Manufacturing"}{" "}
                                      (
                                      {(() => {
                                        try {
                                          return Intl.DateTimeFormat("en-US", {
                                            dateStyle: "short",
                                            timeStyle: "short",
                                            hourCycle: "h23",
                                          } as any).format(
                                            new Date(
                                              c.masterJavaBaseModel
                                                ?.createdAt ?? ""
                                            )
                                          );
                                        } catch (e) {
                                          return "invalid date";
                                        }
                                      })()}
                                      )
                                    </div>
                                    <div>
                                      <textarea
                                        className="form-control form-control-sm"
                                        defaultValue={c.comment}
                                        placeholder="Comment..."
                                        key={`mt-${mt.masterJavaBaseModel?.uuid}-${mt.masterJavaBaseModel?.id}-${j}`}
                                        onBlur={(e) => {
                                          setSelectedTaskList({
                                            ...selectedTaskList,
                                            taskList: selectedTaskList.taskList
                                              ? {
                                                  ...selectedTaskList.taskList,
                                                  meetingTasks: selectedTaskList
                                                    .taskList?.meetingTasks
                                                    ? selectedTaskList.taskList.meetingTasks.map(
                                                        (mtx) =>
                                                          mtx &&
                                                          (mtx
                                                            .masterJavaBaseModel
                                                            ?.uuid &&
                                                          mtx
                                                            .masterJavaBaseModel
                                                            .uuid !== ""
                                                            ? `${mt.masterJavaBaseModel?.uuid}` ===
                                                              `${mtx.masterJavaBaseModel?.uuid}`
                                                            : `${mt.masterJavaBaseModel?.id}` ===
                                                              `${mtx.masterJavaBaseModel?.id}`)
                                                            ? {
                                                                ...mtx,
                                                                meetingTaskComments:
                                                                  mtx.meetingTaskComments.map(
                                                                    (cx, jx) =>
                                                                      jx === j
                                                                        ? {
                                                                            ...cx,
                                                                            comment:
                                                                              e
                                                                                .target
                                                                                .value,
                                                                          }
                                                                        : cx
                                                                  ),
                                                              }
                                                            : mtx
                                                      )
                                                    : selectedTaskList.taskList
                                                        ?.meetingTasks,
                                                }
                                              : selectedTaskList.taskList,
                                          });
                                        }}
                                      />
                                    </div>
                                    <div className="d-flex">
                                      Read by spv?{" "}
                                      <input
                                        type="checkbox"
                                        checked={
                                          c.readBySpvTimestamp ? true : false
                                        }
                                        onClick={() => {
                                          setSelectedTaskList({
                                            ...selectedTaskList,
                                            taskList: selectedTaskList.taskList
                                              ? {
                                                  ...selectedTaskList.taskList,
                                                  meetingTasks: selectedTaskList
                                                    .taskList?.meetingTasks
                                                    ? selectedTaskList.taskList.meetingTasks.map(
                                                        (mtx) =>
                                                          mtx &&
                                                          (mtx
                                                            .masterJavaBaseModel
                                                            ?.uuid &&
                                                          mtx
                                                            .masterJavaBaseModel
                                                            .uuid !== ""
                                                            ? `${mt.masterJavaBaseModel?.uuid}` ===
                                                              `${mtx.masterJavaBaseModel?.uuid}`
                                                            : `${mt.masterJavaBaseModel?.id}` ===
                                                              `${mtx.masterJavaBaseModel?.id}`)
                                                            ? {
                                                                ...mtx,
                                                                meetingTaskComments:
                                                                  mtx.meetingTaskComments.map(
                                                                    (cx, jx) =>
                                                                      j === jx
                                                                        ? {
                                                                            ...cx,
                                                                            readBySpvTimestamp:
                                                                              cx.readBySpvTimestamp
                                                                                ? undefined
                                                                                : new Date().toISOString(),
                                                                          }
                                                                        : cx
                                                                  ),
                                                              }
                                                            : mtx
                                                      )
                                                    : selectedTaskList.taskList
                                                        ?.meetingTasks,
                                                }
                                              : selectedTaskList.taskList,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                          <div>
                            <hr className="border border-dark" />
                          </div>
                          <div className="d-flex">
                            <small>
                              <strong>Journeys:</strong>
                            </small>
                          </div>
                          <div>
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => {
                                setSelectedTaskList({
                                  ...selectedTaskList,
                                  taskList: selectedTaskList.taskList
                                    ? {
                                        ...selectedTaskList.taskList,
                                        meetingTasks: selectedTaskList.taskList
                                          ?.meetingTasks
                                          ? selectedTaskList.taskList.meetingTasks.map(
                                              (mtx) =>
                                                mtx &&
                                                (mtx.masterJavaBaseModel
                                                  ?.uuid &&
                                                mtx.masterJavaBaseModel.uuid !==
                                                  ""
                                                  ? `${mt.masterJavaBaseModel?.uuid}` ===
                                                    `${mtx.masterJavaBaseModel?.uuid}`
                                                  : `${mt.masterJavaBaseModel?.id}` ===
                                                    `${mtx.masterJavaBaseModel?.id}`)
                                                  ? {
                                                      ...mtx,
                                                      journeys: mtx.journeys
                                                        ? [
                                                            ...mtx.journeys,
                                                            Journey.fromPartial(
                                                              {
                                                                masterJavaBaseModel:
                                                                  MasterJavaBaseModel.fromPartial(
                                                                    {
                                                                      uuid: v4(),
                                                                    }
                                                                  ),
                                                              }
                                                            ),
                                                          ]
                                                        : mtx.journeys,
                                                    }
                                                  : mtx
                                            )
                                          : selectedTaskList.taskList
                                              ?.meetingTasks,
                                      }
                                    : selectedTaskList.taskList,
                                });
                              }}
                            >
                              <Add /> Add
                            </button>
                          </div>
                          <div>
                            <ol>
                              {mt.journeys.map((j, jx) => {
                                return (
                                  <>
                                    <li>
                                      <div className="my-1">
                                        <div className="d-flex">
                                          <div>
                                            <button
                                              className="btn btn-sm btn-primary"
                                              onClick={() => {
                                                setSelectCoordinateModalShow(
                                                  true
                                                );
                                                setMapShow(true);
                                                setMapWidth("90%");
                                                setSelectCoordinateIndex(jx);
                                                setSelectCoordinateModalShowTaskId(
                                                  mt.masterJavaBaseModel?.id ??
                                                    null
                                                );
                                                setSelectCoordinateModalShowTaskUuid(
                                                  mt.masterJavaBaseModel
                                                    ?.uuid ?? null
                                                );

                                                setTimeout(() => {
                                                  // setMapCenter([
                                                  //   -6.227026, 106.81659,
                                                  // ]);
                                                  setMapWidth("100%");
                                                }, 1000);
                                              }}
                                            >
                                              <small>
                                                <LocationOn /> Select Coordinate
                                              </small>
                                            </button>
                                          </div>
                                          <div>
                                            {j.siteName}:{j.lat}, {j.lon}
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </>
                                );
                              })}
                            </ol>
                          </div>
                        </div>
                        <div>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => {
                              setMeetingTaskDeleteIds((m) => [
                                ...m,
                                mt.masterJavaBaseModel?.id ?? "",
                              ]);
                              setSelectedTaskList({
                                ...selectedTaskList,
                                taskList: selectedTaskList.taskList
                                  ? {
                                      ...selectedTaskList.taskList,
                                      meetingTasks: selectedTaskList.taskList
                                        ?.meetingTasks
                                        ? selectedTaskList.taskList.meetingTasks.filter(
                                            (mtx) =>
                                              mtx &&
                                              (mtx.masterJavaBaseModel?.uuid &&
                                              mtx.masterJavaBaseModel.uuid !==
                                                ""
                                                ? `${mt.masterJavaBaseModel?.uuid}` !==
                                                  `${mtx.masterJavaBaseModel?.uuid}`
                                                : `${mt.masterJavaBaseModel?.id}` !==
                                                  `${mtx.masterJavaBaseModel?.id}`)
                                          )
                                        : selectedTaskList.taskList
                                            ?.meetingTasks,
                                    }
                                  : selectedTaskList.taskList,
                              });
                            }}
                          >
                            Delete
                          </button>
                        </div>

                        <hr className="border border-dark" />
                      </li>
                    </>
                  );
                })}
              </ol>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex">
            {loading ? (
              <>
                <Spinner animation="border" />
              </>
            ) : (
              <>
                <div className="d-flex">
                  <div>
                    <input
                      className="form-control form-control-sm"
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          return;
                        }

                        customTaskCopyDate.current = e.target.value;
                        render();
                      }}
                      type="date"
                    />
                  </div>
                  <button
                    className="btn btn-sm mx-3 btn-success"
                    onClick={() => {
                      handleSaveWo({ copy: true });
                    }}
                  >
                    <FileCopy /> Copy WO
                  </button>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      handleSaveWo();
                    }}
                  >
                    Save WO
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManualWoPage;
