import { createMuiTheme, withWidth } from "@material-ui/core";
import { lightBlue } from "@material-ui/core/colors";
import React, { useContext } from "react";
import { Button, Form, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { AppContext } from "../../../App";
import { parseJwt } from "../../../helpers";

export interface AppBarMenuItem {
  name: string;
  linkTo: string;
}

export interface AppBarMenuSelection {
  name: string;
  menuItems: AppBarMenuItem[];
}

const blueTheme = createMuiTheme({
  palette: {
    primary: {
      main: lightBlue[700],
    },
  },
});

const MainAppBar = (props: { width: string }) => {
  const state = useContext(AppContext);
  const apiKey = state?.apiKey ? state.apiKey : "";
  const parsedJwt = parseJwt(apiKey);

  const name = parsedJwt ? parsedJwt.sub : "";
  const roles = parsedJwt ? parsedJwt.roles : [];

  console.log("roles:", roles);

  const handleLogout = () => {
    localStorage.removeItem("apiKey");

    state?.setApiKey(null);
    state?.setLoggedIn(false);
    window.location.reload();
  };

  const appBarMenus = [
    {
      name: "Initial Data",
      menuItems: [
        {
          name: "Admin Setup",
          linkTo: "/adminsetup",
          allowedRoles: ["Administrator", "Engineer", "Ppc"],
        },

        {
          name: "Product Tree",
          linkTo: "/integrationlist",
          allowedRoles: ["Administrator", "Engineer", "Ppc"],
        },
        // {
        //   name: "Integration Detail",
        //   linkTo: "/integrationlist/:id",
        //   allowedRoles: ["Administrator", "Engineer", "Ppc"],
        // },

        {
          name: "BOM Group",
          linkTo: "/bomgroups",
          allowedRoles: ["Administrator", "Engineer", "Ppc"],
        },

        {
          name: "BOM Document Hierarchy",
          linkTo: "/bomdocumentshierarchy",
          allowedRoles: ["Administrator", "Engineer", "Ppc"],
        },

        {
          name: "Request New PN",
          linkTo: "/requestitems",
          allowedRoles: ["Administrator", "Ppc"],
        },

        {
          name: "FEP Document",
          linkTo: "/fepdocuments",
          allowedRoles: ["Administrator", "Engineer", "Ppc"],
        },
        {
          name: "FEP Document Simplified",
          linkTo: "/fepdocumentssimplified",
          allowedRoles: ["Administrator", "Engineer", "Ppc"],
        },

        {
          name: "Process Types",
          linkTo: "/processtypes",
          allowedRoles: [
            "Administrator",
            "Engineer",
            "Ppc",
            "Production Manager",
          ],
        },
        {
          name: "Panel Templates",
          linkTo: "/paneltemplates",
          allowedRoles: ["Administrator", "Ppc", "Production Manager"],
        },
        // {
        //   name: "FEP Document v2",
        //   linkTo: "/fepdocumentsv2",
        //   allowedRoles: ["Administrator", "Engineer", "Ppc"],
        // },
        // {
        //   name: "FEP Document Wiring",
        //   linkTo: "/wiringfepdocuments",
        //   allowedRoles: ["Administrator", "Engineer", "Ppc"],
        // },
        // { name : 'FEP', linkTo: '/fep' },
        // { name : 'FEP (rev)', linkTo: '/fepMultis' },
        {
          name: "Users",
          linkTo: "/users",
          allowedRoles: ["Administrator", "Ppc"],
        },
        // {
        //   name: "Items",
        //   linkTo: "/items",
        //   allowedRoles: ["Administrator", "Ppc"],
        // },

        {
          name: "RCEM",
          linkTo: "/rcem",
          allowedRoles: ["Administrator", "Ppc"],
        },
        {
          name: "Machine",
          linkTo: "/machines",
          allowedRoles: ["Administrator", "Ppc"],
        },
        {
          name: "Customer",
          linkTo: "/individualentities",
          allowedRoles: ["Administrator", "Ppc"],
        },
        {
          name: "RAL",
          linkTo: "/rals",
          allowedRoles: ["Administrator", "Ppc"],
        },

        // {
        //   name: "Manufacturer",
        //   linkTo: "/manufacturers",
        //   allowedRoles: ["Administrator", "Ppc"],
        // },
        // {
        //   name: "Warehouse",
        //   linkTo: "/warehouses",
        //   allowedRoles: ["Administrator", "Ppc"],
        // },
        {
          name: "Problem Type",
          linkTo: "/problemtypes",
          allowedRoles: ["Administrator", "Ppc"],
        },
        {
          name: "Machine Programs",
          linkTo: "/machineprograms",
          allowedRoles: ["Administrator", "Ppc"],
        },
        {
          name: "Department Template",
          linkTo: "/departmenttemplate",
          allowedRoles: ["Administrator", "Ppc"],
        },

        {
          name: "Skill Catalog",
          linkTo: "/skillcatalog",
          allowedRoles: ["Administrator", "Ppc"],
        },
        // {
        //   name: "Department Rule",
        //   linkTo: "/departmentrule",
        //   allowedRoles: ["Administrator", "Ppc"],
        // },

        // {
        //   name: "Panel Type",
        //   linkTo: "/paneltypes",
        //   allowedRoles: ["Administrator", "Ppc"],
        // },
        // { name : 'Wiring Process Type', linkTo: '/wiringProcessType' },
        // { name : 'Warehouse', linkTo: '/warehouse' },
        // { name : 'Program', linkTo: '/program' },
      ],
    },

    {
      name: "Initial Data 2",
      menuItems: [
        {
          name: "Department Rule",
          linkTo: "/departmentrule",
          allowedRoles: ["Administrator", "Engineer", "Ppc"],
        },
        {
          name: "Working Hour",
          linkTo: "/workinghour",
          allowedRoles: ["Administrator", "Engineer", "Ppc"],
        },
        {
          name: "Job Overview Col",
          linkTo: "/joboverviewcol",
          allowedRoles: ["Administrator", "Engineer", "Ppc"],
        },
        {
          name: "Problem Catalog",
          linkTo: "/problemcatalog",
          allowedRoles: ["Administrator", "Engineer", "Ppc", "User"],
        },
        {
          name: "Program Tree Settings",
          linkTo: "/programtreesettings",
          allowedRoles: ["Administrator", "Engineer", "Ppc", "User"],
        },
        {
          name: "WO Template",
          linkTo: "/workordertemplates",
          allowedRoles: ["Administrator", "Ppc"],
        },
        {
          name: "WO Problem Catalogs",
          linkTo: "/woproblemcatalog",
          allowedRoles: ["Administrator", "Ppc"],
        },
        {
          name: "Holiday Calendar",
          linkTo: "/holidaycalendar",
          allowedRoles: ["Administrator", "Ppc"],
        },
        {
          name: "WhatsApp",
          linkTo: "/whatsapp",
          allowedRoles: ["Administrator", "Ppc"],
        },
        {
          name: "MP Assistance",
          linkTo: "/mp-assistance",
          allowedRoles: ["Administrator", "Ppc"],
        },
        {
          name: "ProgramTree Multiplier",
          linkTo: "/pt-multiplier",
          allowedRoles: ["Administrator", "Ppc"],
        },
        {
          name: "New Job Notification",
          linkTo: "/new-job-notification",
          allowedRoles: ["Administrator", "Ppc"],
        },
        {
          name: "Default MR Auth",
          linkTo: "/default-mr-auth",
          allowedRoles: ["Administrator", "Ppc"],
        },
        
      ],
    },
    {
      name: "Operations",
      menuItems: [
        {
          name: "Projects (For Production)",
          linkTo: "/projects",
          allowedRoles: ["Administrator", "Ppc", "Production Manager"],
        },
        {
          name: "Projects (For PPIC)",
          linkTo: "/projectsnew",
          allowedRoles: ["Administrator", "Ppc"],
        },
        {
          name: "Job Summary",
          linkTo: "/jobsummary",
          allowedRoles: ["Administrator", "Ppc"],
        },
        {
          name: "Jobs",
          linkTo: "/jobs",
          allowedRoles: ["Administrator", "Ppc"],
        },
        // { name : 'Work Orders', linkTo: '/workorders' },
        {
          name: "Work Orders",
          linkTo: "/workordersrev",
          allowedRoles: [
            "Administrator",
            "Ppc",
            "Production Manager",
            "Production Worker",
          ],
        },
        {
          name: "Resource Planning (Machine)",
          linkTo: "/resourceplanning",
          allowedRoles: [
            "Administrator",
            "Ppc",
            "Production Manager",
            "Production Worker",
          ],
        },

        {
          name: "Work Orders v2 (Wiring)",
          linkTo: "/workordersv2",
          allowedRoles: [
            "Administrator",
            "Ppc",
            "Production Manager",
            "Production Worker",
          ],
        },

        {
          name: "Manpower Planning",
          linkTo: "/manpowerplanning",
          allowedRoles: ["Administrator", "Ppc", "Production Manager"],
        },
        // {
        //   name: "Wiring Manpower Plan",
        //   linkTo: "/wiringmanpower",
        //   allowedRoles: ["Administrator", "Ppc"],
        // },
        {
          name: "Production Manpower Assignment",
          linkTo: "/wiringmanpowerassignment",
          allowedRoles: ["Administrator", "Ppc", "Production Manager"],
        },
        {
          name: "Customer <-> GSPE PN",
          linkTo: "/partnumbers",
          allowedRoles: ["Administrator", "Ppc"],
        },
        // {
        //   name: "Customer <-> GSPE PN v2",
        //   linkTo: "/partnumbersv2",
        //   allowedRoles: ["Administrator", "Ppc"],
        // },

        {
          name: "Manual WO Assignment",
          linkTo: "/manualwo",
          allowedRoles: ["Administrator", "Ppc", "User", "Engineer"],
        },
        {
          name: "Handover",
          linkTo: "/handover",
          allowedRoles: ["Administrator", "Ppc", "User"],
        },
        {
          name: "DN Manual",
          linkTo: "/dnmanual",
          allowedRoles: ["Administrator", "Ppc", "User"],
        },
        {
          name: "DeliveryPlan",
          linkTo: "/deliveryplan",
          allowedRoles: ["Administrator", "Ppc", "User"],
        },

        // { name : 'MPS Detailed', linkTo: '/mpsDetailed' },
        // { name : 'MPS Wiring', linkTo: '/mpsWiring' },
        // { name : 'MPS Summary', linkTo: '/mpsSummary' },
        // { name : 'Machine Master Schedule', linkTo: '/machineMasterSchedule' },
        // { name : 'Manpower Routing', linkTo: '/manpowerRouting' },
      ],
    },
    {
      name: "Material",
      menuItems: [
        {
          name: "Warehouse Summary",
          linkTo: "/warehousesummary",
          allowedRoles: ["Administrator", "Ppc"],
        },
        // {
        //   name: "Item Buffer",
        //   linkTo: "/itembuffer",
        //   allowedRoles: ["Administrator", "Ppc"],
        // },
        // {
        //   name: "Material Request",
        //   linkTo: "/materialrequests",
        //   allowedRoles: ["Administrator", "Ppc"],
        // },
        {
          name: "Material Request",
          linkTo: "/materialrequestssimpleview",
          allowedRoles: ["Administrator", "Ppc"],
        },
        {
          name: "Material Request Print",
          linkTo: "/materialrequestsprint",
          allowedRoles: ["Administrator", "User"],
        },

        {
          name: "MR Report",
          linkTo: "/mrreport",
          allowedRoles: ["Administrator", "Ppc"],
        },

        {
          name: "Reserve List",
          linkTo: "/reservelist",
          allowedRoles: ["Administrator", "Ppc"],
        },
        {
          name: "Purchase Request",
          linkTo: "/purchaserequests",
          allowedRoles: ["Administrator", "Ppc"],
        },
        {
          name: "AutoMR Warehouse Filter",
          linkTo: "/automr-warehouse-filter",
          allowedRoles: ["Administrator", "Ppc"],
        },
        {
          name: "Inventory Transaction Report",
          linkTo: "/inventory-transaction-report",
          allowedRoles: ["Administrator", "Ppc"],
        },
        {
          name: "Duplicate PN Report",
          linkTo: "/double-pn-report",
          allowedRoles: ["Administrator", "Ppc"],
        },

        // { name : 'Purchase Request', linkTo: '/purchaseRequest' },
        // { name : 'Shortage List', linkTo: '/shortage' },
        // { name : 'Warehouse Summary', linkTo: '/warehouseSummary1' },
      ],
    },
    {
      name: "Reports",
      menuItems: [
        // { name : 'MPS', linkTo: '/mps' },
        // {
        //   name: "MPS",
        //   linkTo: "/mpsrev",
        //   allowedRoles: ["Administrator", "Ppc", "Guest"],
        // },
        {
          name: "MPS Detailed",
          linkTo: "/mpsdetailed",
          allowedRoles: ["Administrator", "Ppc", "Guest"],
        },
        {
          name: "Wiring Report",
          linkTo: "/wiringreport",
          allowedRoles: ["Administrator", "Ppc", "Guest"],
        },
        {
          name: "Job Dept Progress",
          linkTo: "/jobdeptprogress",
          allowedRoles: ["Administrator", "Ppc", "Guest"],
        },
        {
          name: "Job Overview",
          linkTo: "/joboverview",
          allowedRoles: ["Administrator", "Ppc", "Guest"],
        },
        {
          name: "Monitoring",
          linkTo: "/monitoring",
          allowedRoles: ["Administrator", "Ppc", "Guest"],
        },
        {
          name: "Attendance",
          linkTo: "/attendance",
          allowedRoles: ["Administrator", "Ppc", "Guest"],
        },
        {
          name: "Product Monitoring",
          linkTo: "/productmonitoring",
          allowedRoles: ["Administrator", "Ppc", "Guest"],
        },
        {
          name: "Remote Control",
          linkTo: "/remotecontrol",
          allowedRoles: ["Administrator", "Ppc", "Guest"],
        },
        {
          name: "KPI",
          linkTo: "/rcem-kpi",
          allowedRoles: ["Administrator", "Ppc", "Guest"],
        },
        {
          name: "SN Report",
          linkTo: "/serialnumbers",
          allowedRoles: ["Administrator", "Ppc", "Guest"],
        },
        {
          name: "Planning Report",
          linkTo: "/planningreport",
          allowedRoles: ["Administrator", "Ppc", "Guest"],
        },
        {
          name: "Job Dept Template Overview",
          linkTo: "/jobdepttemplates",
          allowedRoles: ["Administrator", "Ppc", "Guest"],
        },
        {
          name: "Project Document Integration",
          linkTo: "/projectdocumentintegrations",
          allowedRoles: ["Administrator", "Ppc", "Guest"],
        },

        // { name : 'KPI', linkTo: '/kpi' },
        // { name : 'Shortage List', linkTo: '/shortageList' },
        // { name : 'MR vs BOM Comparison', linkTo: '/mrVsBomComparison' },
        // { name : 'Warehouse Summary', linkTo: '/warehouseSummary' },
        // { name : 'Outstanding Engineering Document', linkTo: '/outstandingEngineeringDoc' },
      ],
    },
    // {
    //   name: 'Dashboard',
    //   menuItems: [
    //     { name : 'MPS', linkTo: '/' },
    //   ]
    // },
    //'Operations', 'Material', 'Reports', 'Dashboard'
  ];

  // return (
  //   <AppBar position="static">
  //     <Box display="flex" justifyContent="space-between" alignItems="center">
  //       {props.width === 'sm' || props.width === 'xs'
  //         ? <>
  //             <Box display="flex" alignItems="center">
  //               <IconButton color="inherit">
  //                 <MenuIcon />
  //               </IconButton>
  //               <Button color="inherit"><h3>PPIC</h3></Button>
  //             </Box>
  //           </>
  //         : <>
  //             <Box display="flex" alignItems="center">
  //               <Button color="inherit"><h3>PPIC</h3></Button>
  //               {
  //                 appBarMenus.map((menu, index) => {
  //                   // console.log("Role:", roles, "api key:", props.apiKey);
  //                   return (
  //                     <AppBarMenu
  //                       key={`AppBarMenu_${index}`}
  //                       name={menu.name}
  //                       menuItems ={menu.menuItems.filter(menuItem => {
  //                         return roles
  //                           .map(role => menuItem.allowedRoles.includes(role))
  //                           .filter(res => res === true)
  //                           .length > 0
  //                       })}
  //                     />
  //                   )
  //                 })
  //               }
  //             </Box>

  //             <ThemeProvider theme={createMuiTheme()}>
  //               <Box mr={2}>
  //                 <Link to="/">
  //                   <Button variant="contained">
  //                     Settings <SettingsIcon />
  //                   </Button>
  //                 </Link>
  //                 <a href={`http://${window.location.hostname}:${window.location.port}/documentation.pdf`} target="_blank">
  //                   <ThemeProvider theme={blueTheme}>
  //                     <Button color="primary" variant="contained">
  //                       Documentation&nbsp;<LinkIcon />
  //                     </Button>
  //                   </ThemeProvider>
  //                 </a>
  //                 <Link to="/">
  //                   <Button color="secondary" variant="contained" onClick={props.handleLogout}>
  //                     Logout <PowerSettingsNewIcon />
  //                   </Button>
  //                 </Link>
  //               </Box>
  //             </ThemeProvider>
  //           </>
  //       }
  //     </Box>
  //   </AppBar>
  // )
  return (
    <Navbar expand="lg" variant="dark" style={{ backgroundColor: "darkblue" }}>
      <Navbar.Brand href="/#/">Manufacturing</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {appBarMenus.map((menu) => (
            <NavDropdown title={menu.name} id={menu.name}>
              {menu.menuItems
                .filter(
                  (menuItem) =>
                    roles.filter((roleName) =>
                      menuItem.allowedRoles.includes(roleName)
                    ).length > 0
                )
                .map((menuItem) => (
                  <LinkContainer to={menuItem.linkTo}>
                    <NavDropdown.Item>{menuItem.name}</NavDropdown.Item>
                  </LinkContainer>
                ))}
            </NavDropdown>
          ))}
        </Nav>
        <Form inline>
          <a
            href={`http://${window.location.hostname}:${window.location.port}/documentation.pdf`}
          >
            <Button variant="info">Documentation</Button>
          </a>
          <Button variant="danger" onClick={() => handleLogout()}>
            Logout
          </Button>
        </Form>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default withWidth()(MainAppBar);
