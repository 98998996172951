import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { v4 } from "uuid";
import { AppContext } from "../../../App";
import { fetchProblemCatalogs } from "../../../helpers";
import {
  MasterJavaBaseModel,
  MeetingProblemCatalog,
  MeetingProblemCatalogs,
  MeetingProblemType,
  meetingProblemTypeFromJSON,
  meetingProblemTypeToJSON,
} from "../../../masterbigsystem";
import { ExtDepartment } from "../../../models/model";

const WoProblemCatalogPage = () => {
  const ctx = useContext(AppContext);

  const [selectedDepartment, setSelectedDepartment] =
    useState<ExtDepartment | null>(null);
  const [problemCatalogs, setProblemCatalogs] = useState(
    MeetingProblemCatalogs.fromPartial({ catalogs: [] })
  );

  const handleInit = () => {
    fetchProblemCatalogsData();
  };

  const fetchProblemCatalogsData = async () => {
    setProblemCatalogs(
      await fetchProblemCatalogs({ apiKey: ctx?.apiKey ?? "" })
    );
  };

  useEffect(() => {
    handleInit();
  }, []);

  const handleSave = async () => {
    try {
      const resp = await fetch(
        `${process.env.REACT_APP_MEETING_URL}/problemcatalogs`,
        {
          method: "post",
          headers: {
            authorization: ctx?.apiKey ?? "",
            "content-type": "application/json",
          },
          body: JSON.stringify(MeetingProblemCatalogs.toJSON(problemCatalogs)),
        }
      );
      window.location.reload();
    } catch (e) {}
  };

  return (
    <>
      <div>
        <div className="d-flex align-items-center">
          <div>
            <h4>WO Problems</h4>
          </div>
          <div>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </button>
          </div>
        </div>
        <hr className="border border-dark" />
        <div>
          <Select
            options={ctx?.extDepartments ?? []}
            getOptionLabel={(d) => `${d.name}`}
            placeholder="Select department..."
            onChange={(v) => {
              const val = v as unknown as ExtDepartment;

              setSelectedDepartment(val);
            }}
          />
        </div>
        <div className="d-flex border border-dark">
          {[
            {
              label: "Internal",
              value: MeetingProblemType.PROBLEM_TYPE_INTERNAL,
            },
            {
              label: "External",
              value: MeetingProblemType.PROBLEM_TYPE_EXTERNAL,
            },
          ].map((d) => {
            return (
              <>
                <div
                  className="border border-dark flex-grow-1 overflow-auto"
                  style={{ height: "75vh", resize: "vertical" }}
                >
                  <div>{d.label}</div>
                  {selectedDepartment ? (
                    <>
                      <div className="overflow-auto">
                        <table
                          className="table table-sm"
                          style={{ borderCollapse: "separate" }}
                        >
                          <tr>
                            {[
                              "Action",
                              "Identifier",
                              "Alias",
                              "DeptID",
                              "Quality?",
                              "Cost?",
                            ].map((h) => (
                              <>
                                <th
                                  className="bg-dark text-light"
                                  style={{ position: "sticky", top: 0 }}
                                >
                                  {h}
                                </th>
                              </>
                            ))}
                          </tr>
                          {[...Array(20)].map((_, i) => {
                            const foundProblemCatalog =
                              problemCatalogs.catalogs.find(
                                (c) =>
                                  `${c.extDepartmentId}` ===
                                    `${selectedDepartment.id}` &&
                                  `${c.problemIntIdentifier}` === `${i + 1}` &&
                                  c.problemType &&
                                  meetingProblemTypeFromJSON(
                                    meetingProblemTypeToJSON(c.problemType)
                                  ) ===
                                    meetingProblemTypeFromJSON(
                                      meetingProblemTypeToJSON(d.value)
                                    )
                              );
                            return (
                              <>
                                <tr>
                                  <td
                                    className={`border border-dark ${
                                      foundProblemCatalog
                                        ? `bg-success`
                                        : `bg-danger`
                                    }`}
                                  >
                                    {foundProblemCatalog ? (
                                      <>OK</>
                                    ) : (
                                      <>
                                        <div>
                                          Not found{" "}
                                          <button
                                            className="btn btn-sm btn-primary"
                                            onClick={() => {
                                              setProblemCatalogs({
                                                ...problemCatalogs,
                                                catalogs: [
                                                  ...problemCatalogs.catalogs,
                                                  MeetingProblemCatalog.fromPartial(
                                                    {
                                                      extDepartmentId:
                                                        selectedDepartment.id
                                                          ? `${selectedDepartment.id}`
                                                          : undefined,
                                                      problemIntIdentifier:
                                                        i + 1,
                                                      problemType: d.value,
                                                      masterJavaBaseModel:
                                                        MasterJavaBaseModel.fromPartial(
                                                          { uuid: v4() }
                                                        ),
                                                    }
                                                  ),
                                                ],
                                              });
                                            }}
                                          >
                                            Add
                                          </button>
                                        </div>
                                      </>
                                    )}
                                  </td>
                                  <td className="border border-dark">
                                    {i + 1}
                                  </td>
                                  {foundProblemCatalog ? (
                                    <>
                                      <td className="border border-dark">
                                        <input
                                          key={
                                            foundProblemCatalog
                                              .masterJavaBaseModel?.uuid
                                          }
                                          defaultValue={
                                            foundProblemCatalog.alias
                                          }
                                          className="form-control form-control-sm"
                                          onBlur={(e) => {
                                            setProblemCatalogs({
                                              ...problemCatalogs,
                                              catalogs:
                                                problemCatalogs.catalogs.map(
                                                  (c, ix) =>
                                                    `${c.extDepartmentId}` ===
                                                      `${selectedDepartment.id}` &&
                                                    `${c.problemIntIdentifier}` ===
                                                      `${i + 1}` &&
                                                    c.problemType &&
                                                    meetingProblemTypeFromJSON(
                                                      meetingProblemTypeToJSON(
                                                        c.problemType
                                                      )
                                                    ) ===
                                                      meetingProblemTypeFromJSON(
                                                        meetingProblemTypeToJSON(
                                                          d.value
                                                        )
                                                      )
                                                      ? {
                                                          ...c,
                                                          alias: e.target.value,
                                                        }
                                                      : c
                                                ),
                                            });
                                          }}
                                          placeholder="Alias..."
                                        />
                                        {foundProblemCatalog.alias ?? "None"}
                                      </td>
                                      <td className="border border-dark">
                                        <Select
                                          options={
                                            ctx?.extDepartments.map((d) => ({
                                              label: d.name ?? "",
                                              value: d,
                                            })) ?? []
                                          }
                                          placeholder="Dept..."
                                          value={(() => {
                                            const fDept =
                                              ctx?.extDepartments.find(
                                                (d) =>
                                                  `${d.id}` ===
                                                  `${foundProblemCatalog.extDepartmentVersusId}`
                                              );

                                            if (!fDept) {
                                              return null;
                                            }

                                            return {
                                              label: fDept.name,
                                              value: fDept,
                                            };
                                          })()}
                                          onChange={(v) => {
                                            const val = v as unknown as {
                                              value: ExtDepartment;
                                            };

                                            setProblemCatalogs({
                                              ...problemCatalogs,
                                              catalogs:
                                                problemCatalogs.catalogs.map(
                                                  (c, ix) =>
                                                    `${c.extDepartmentId}` ===
                                                      `${selectedDepartment.id}` &&
                                                    `${c.problemIntIdentifier}` ===
                                                      `${i + 1}` &&
                                                    c.problemType &&
                                                    meetingProblemTypeFromJSON(
                                                      meetingProblemTypeToJSON(
                                                        c.problemType
                                                      )
                                                    ) ===
                                                      meetingProblemTypeFromJSON(
                                                        meetingProblemTypeToJSON(
                                                          d.value
                                                        )
                                                      )
                                                      ? {
                                                          ...c,
                                                          extDepartmentVersusId:
                                                            val.value.id
                                                              ? `${val.value.id}`
                                                              : undefined,
                                                        }
                                                      : c
                                                ),
                                            });
                                          }}
                                        />
                                        <small>
                                          {
                                            ctx?.extDepartments.find(
                                              (d) =>
                                                `${d.id}` ===
                                                `${foundProblemCatalog.extDepartmentVersusId}`
                                            )?.name
                                          }
                                        </small>
                                      </td>
                                      <td className="border border-dark">
                                        <input
                                          type="checkbox"
                                          checked={foundProblemCatalog.isCost}
                                          onClick={() => {
                                            setProblemCatalogs({
                                              ...problemCatalogs,
                                              catalogs:
                                                problemCatalogs.catalogs.map(
                                                  (c, ix) =>
                                                    `${c.extDepartmentId}` ===
                                                      `${selectedDepartment.id}` &&
                                                    `${c.problemIntIdentifier}` ===
                                                      `${i + 1}` &&
                                                    c.problemType &&
                                                    meetingProblemTypeFromJSON(
                                                      meetingProblemTypeToJSON(
                                                        c.problemType
                                                      )
                                                    ) ===
                                                      meetingProblemTypeFromJSON(
                                                        meetingProblemTypeToJSON(
                                                          d.value
                                                        )
                                                      )
                                                      ? {
                                                          ...c,
                                                          isCost: !c.isCost,
                                                        }
                                                      : c
                                                ),
                                            });
                                          }}
                                        />
                                      </td>
                                      <td className="border border-dark">
                                        <input
                                          type="checkbox"
                                          checked={
                                            foundProblemCatalog.isQuality
                                          }
                                          onClick={() => {
                                            setProblemCatalogs({
                                              ...problemCatalogs,
                                              catalogs:
                                                problemCatalogs.catalogs.map(
                                                  (c, ix) =>
                                                    `${c.extDepartmentId}` ===
                                                      `${selectedDepartment.id}` &&
                                                    `${c.problemIntIdentifier}` ===
                                                      `${i + 1}` &&
                                                    c.problemType &&
                                                    meetingProblemTypeFromJSON(
                                                      meetingProblemTypeToJSON(
                                                        c.problemType
                                                      )
                                                    ) ===
                                                      meetingProblemTypeFromJSON(
                                                        meetingProblemTypeToJSON(
                                                          d.value
                                                        )
                                                      )
                                                      ? {
                                                          ...c,
                                                          isQuality:
                                                            !c.isQuality,
                                                        }
                                                      : c
                                                ),
                                            });
                                          }}
                                        />
                                      </td>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </tr>
                              </>
                            );
                          })}
                        </table>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default WoProblemCatalogPage;
