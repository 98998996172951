import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../App";
import {
  JobTypeSelection,
  fetchApps,
  fetchDepartmentTemplatesView,
  fetchJobExternalDepartmentTemplates,
  getChromaColor,
  jobClosedStatuses,
  jobDeptCategories,
} from "../../../helpers";
import {
  JobExtDepartmentTemplateJobWrapper,
  JobExtDepartmentTemplates,
  PpicJob_PpicJobDeptCategory,
  PpicPanelCodeDepartmentTemplateItem,
  PpicPanelCodeDepartmentTemplateItems,
} from "../../../masterbigsystem";
import {
  DepartmentTemplateView,
  ExtApp,
  JobDeptCategory,
} from "../../../models/model";
import Select from "react-select";
import murmur from "murmurhash-js";
import { Refresh } from "@material-ui/icons";

const JobDeptTemplatesPage = () => {
  const ctx = useContext(AppContext);

  const expandedjobs = useRef([] as (string | undefined)[]);
  const jobExternalDepartmentTemplates = useRef(
    null as JobExtDepartmentTemplates | null
  );
  const jobExternalDepartmentTemplatesFormer = useRef(
    null as JobExtDepartmentTemplates | null
  );

  const appParam = useRef("");
  const apps = useRef([] as ExtApp[]);
  const [, refresh] = useState(false);
  const showDate = useRef(false);
  const editMode = useRef(false);

  const [departmentTemplates, setDepartmentTemplates] = useState<
    DepartmentTemplateView[] | null | undefined
  >(null);

  const selectedJobType = useRef(null as JobDeptCategory | null);
  const selectedJobStatusClosed = useRef(false as boolean | null);

  useEffect(() => {
    handleInit();
  }, []);

  const saveLoading = useRef(false);

  const handleSave = async () => {
    try {
      saveLoading.current = true;
      render();

      const pcdtisFormer =
        jobExternalDepartmentTemplatesFormer.current?.jobs.flatMap(
          (j) =>
            j.data?.panelCodes.flatMap(
              (c) => c.panelCode?.panelCodeDepartmentTemplateItems ?? []
            ) ?? []
        ) ?? [];

      const pcdtis =
        jobExternalDepartmentTemplates.current?.jobs.flatMap(
          (j) =>
            j.data?.panelCodes.flatMap(
              (c) => c.panelCode?.panelCodeDepartmentTemplateItems ?? []
            ) ?? []
        ) ?? [];

      const diffs = pcdtis.filter((p) => {
        const found = pcdtisFormer.find(
          (px) =>
            `${px.masterJavaBaseModel?.id}` === `${p.masterJavaBaseModel?.id}`
        );

        if (found) {
          return (
            murmur.murmur3(JSON.stringify(p)) !==
            murmur.murmur3(JSON.stringify(found))
          );
        } else {
          return false;
        }
      });

      console.log(pcdtis, pcdtisFormer, diffs);

      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/panelcodedepartmenttemplateitems-proto-save-bulk`,
        {
          method: "post",
          headers: {
            "content-type": "application/json",
            authorization: ctx?.apiKey ?? "",
          },
          body: JSON.stringify(
            PpicPanelCodeDepartmentTemplateItems.fromPartial({
              departmentTemplateItems: diffs,
            })
          ),
        }
      );

      if (resp.status !== 200) {
        throw await resp.text();
      }

      window.location.reload();
    } catch (e) {
      console.log("error saving jdt", e);
    } finally {
      saveLoading.current = false;
      render();
    }
  };

  const render = () => {
    refresh((n) => !n);
  };
  const fetchjobExternalDepartmentTemplatesData = async () => {
    const d = await fetchJobExternalDepartmentTemplates({
      apiKey: ctx?.apiKey ?? "",
      closed: false,
    });

    if (d) {
      jobExternalDepartmentTemplates.current = structuredClone(d);
      jobExternalDepartmentTemplatesFormer.current = structuredClone(d);

      render();
    }
  };

  const fetchAppsData = async () => {
    const d = await fetchApps(ctx?.apiKey ?? "");

    if (d) {
      apps.current = d;
      render();
    }
  };

  const handleInit = async () => {
    fetchjobExternalDepartmentTemplatesData();
    fetchAppsData();

    const [departmentTemplatesData] = await Promise.all([
      fetchDepartmentTemplatesView({ apiKey: ctx?.apiKey ?? "" }),
    ]);

    setDepartmentTemplates(departmentTemplatesData);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    // console.log(urlParams.get("app"));

    const app = urlParams.get("app");

    if (app) {
      appParam.current = app;
      render();
    }
  }, []);

  const foundApp = apps.current.find(
    (a) => `${a.name?.toLowerCase()}` === `${appParam.current.toLowerCase()}`
  );

  const getJobPanelCodeInfos = (j: JobExtDepartmentTemplateJobWrapper) => {
    return (
      j?.panelCodes.map((c) => {
        const includedPCDTIS = c.panelCode?.panelCodeDepartmentTemplates
          .filter((t) => {
            const foundDeptTemplate = departmentTemplates?.find(
              (tx) =>
                `${tx.departmentTemplate?.id}` === `${t.departmentTemplateId}`
            );

            return (
              !t.masterJavaBaseModel?.hidden &&
              (foundApp?.id
                ? `${foundApp.id}` ===
                  `${foundDeptTemplate?.departmentTemplate?.extAppId}`
                : true)
            );
          })
          .flatMap((t) =>
            t.departmentTemplate?.items.filter(
              (i) => !i.masterJavaBaseModel?.hidden
            )
          )
          .map((i) => {
            const foundPCDTI =
              c.panelCode?.panelCodeDepartmentTemplateItems.find(
                (ix) =>
                  ix.departmentTemplateItemId &&
                  i?.masterJavaBaseModel?.id &&
                  !ix.excluded &&
                  `${ix.departmentTemplateItemId}` ===
                    `${i?.masterJavaBaseModel?.id}`
              );
            return foundPCDTI;
          })
          .filter((p) => p)
          .map((p) => p as PpicPanelCodeDepartmentTemplateItem);

        const completedPCDTIS = includedPCDTIS?.filter((p) => p.completedDate);
        return { panelCode: c, includedPCDTIS, completedPCDTIS };
      }) ?? []
    );
  };

  const filteredJobs =
    jobExternalDepartmentTemplates.current?.jobs.filter((j) => {
      const panelCodeInfos = j.data ? getJobPanelCodeInfos(j.data) : [];

      const totalPCDTI = panelCodeInfos?.reduce(
        (acc, p) => acc + (p.includedPCDTIS?.length ?? 0),
        0
      );
      const completedPCDTI = panelCodeInfos?.reduce(
        (acc, p) => acc + (p.completedPCDTIS?.length ?? 0),
        0
      );

      return (
        (selectedJobType.current
          ? `${j.data?.job?.category}` === `${selectedJobType.current}`
          : true) &&
        (selectedJobStatusClosed.current !== null
          ? totalPCDTI > 0
            ? (() => {
                switch (selectedJobStatusClosed.current) {
                  case true:
                    return completedPCDTI >= totalPCDTI;
                  case false:
                    return completedPCDTI < totalPCDTI;
                  default:
                    return false;
                }
              })()
            : false
          : true)
      );
    }) ?? [];

  return (
    <>
      <div>
        <div className="d-flex">
          <h4>
            Job Dept Templates Overview [App: {foundApp ? foundApp.name : "All"}
            ]
          </h4>
          <button
            className="btn btn-sm btn-success"
            onClick={() => {
              window.location.reload();
            }}
          >
            <Refresh />{" "}
          </button>
        </div>
        <div className="d-flex">
          <div>Show date?</div>
          <div>
            <input
              type="checkbox"
              checked={showDate.current}
              onClick={() => {
                showDate.current = !showDate.current;
                render();
              }}
            />
          </div>

          <div className="ml-2">Edit mode?</div>
          <div>
            <input
              type="checkbox"
              checked={editMode.current}
              onClick={() => {
                editMode.current = !editMode.current;
                render();
              }}
            />
          </div>
        </div>
        <div>
          <hr className="border border-dark" />
        </div>
        <div className="d-flex">
          <div className="mx-2">
            <small>
              <strong>Job Type </strong>
            </small>
            <div style={{ width: 200 }}>
              <Select
                options={[...jobDeptCategories, { label: "All", value: null }]}
                placeholder="Job type..."
                value={[
                  ...jobDeptCategories,
                  { label: "All", value: null },
                ].find((c) => c.value === selectedJobType.current)}
                onChange={(v) => {
                  const val = v as { value: JobDeptCategory | null };

                  selectedJobType.current = val.value;
                  render();
                }}
              />
            </div>
          </div>
          <div className="mx-2">
            <small>
              <strong>Job Status</strong>
            </small>
            <div style={{ width: 200 }}>
              <Select
                options={jobClosedStatuses}
                value={jobClosedStatuses.find(
                  (s) => s.value === selectedJobStatusClosed.current
                )}
                placeholder="Job status..."
                onChange={(v) => {
                  const val = v as { value: boolean | null };

                  selectedJobStatusClosed.current = val.value;
                  render();
                }}
              />
            </div>
          </div>

          <div className="mx-2">
            {saveLoading.current ? (
              <>
                <div className="spinner-border spinner-border-sm"></div>
              </>
            ) : (
              <>
                <button
                  className="btn btn-sm btn-primary px-1 py-0"
                  onClick={() => {
                    handleSave();
                  }}
                >
                  Save
                </button>
              </>
            )}
          </div>
        </div>
        <div>
          <hr className="border border-dark" />
        </div>
        <div>
          <ol>
            {filteredJobs.map((j) => {
              const panelCodeInfos = j.data ? getJobPanelCodeInfos(j.data) : [];

              const totalPCDTI = panelCodeInfos?.reduce(
                (acc, p) => acc + (p.includedPCDTIS?.length ?? 0),
                0
              );
              const completedPCDTI = panelCodeInfos?.reduce(
                (acc, p) => acc + (p.completedPCDTIS?.length ?? 0),
                0
              );
              const foundExpandedJobId = expandedjobs.current.find(
                (id) => `${id}` === `${j.data?.job?.masterJavaBaseModel?.id}`
              );

              return (
                <>
                  <li>
                    <div>
                      <div className="d-flex">
                        <div
                          className="mx-1 border border-dark px-1"
                          style={{
                            minWidth: 75,
                            backgroundColor: getChromaColor(
                              (completedPCDTI ?? 0) / (totalPCDTI ?? 1)
                            ),
                          }}
                        >
                          <strong>
                            {(
                              ((completedPCDTI ?? 0) / (totalPCDTI ?? 1)) *
                              100
                            ).toFixed(0)}
                            %
                          </strong>
                        </div>
                        <div className="mx-2">
                          ({completedPCDTI}/{totalPCDTI})
                        </div>
                        <div>
                          [{j.data?.job?.category}] {j.data?.job?.name}
                        </div>
                        <div>
                          <button
                            className="btn btn-sm btn-outline-primary px-1 py-0"
                            onClick={() => {
                              if (foundExpandedJobId) {
                                expandedjobs.current =
                                  expandedjobs.current.filter(
                                    (id) =>
                                      `${id}` !==
                                      `${j.data?.job?.masterJavaBaseModel?.id}`
                                  );
                              } else {
                                expandedjobs.current.push(
                                  `${j.data?.job?.masterJavaBaseModel?.id}`
                                );
                              }

                              render();
                            }}
                          >
                            Expand/Collapse
                          </button>
                        </div>
                      </div>
                      {foundExpandedJobId ? (
                        <>
                          {" "}
                          <div>
                            <ol>
                              {j.data?.panelCodes.map((c) => {
                                const sortedPanelCodeDepartmentTemplates =
                                  c.panelCode?.panelCodeDepartmentTemplates.filter(
                                    (t) => {
                                      const foundDeptTemplate =
                                        departmentTemplates?.find(
                                          (tx) =>
                                            `${tx.departmentTemplate?.id}` ===
                                            `${t.departmentTemplateId}`
                                        );

                                      return foundApp?.id
                                        ? `${foundApp.id}` ===
                                            `${foundDeptTemplate?.departmentTemplate?.extAppId}`
                                        : true;
                                    }
                                  ) ?? [];
                                return (
                                  <>
                                    <li>
                                      <div>
                                        <div>
                                          {c.panelCode?.type}:{" "}
                                          {c.panelCode?.name}
                                        </div>
                                        <div>
                                          <ol>
                                            {sortedPanelCodeDepartmentTemplates.map(
                                              (t) => {
                                                const foundDeptTemplate =
                                                  departmentTemplates?.find(
                                                    (tx) =>
                                                      `${tx.departmentTemplate?.id}` ===
                                                      `${t.departmentTemplateId}`
                                                  );
                                                const foundApp =
                                                  apps.current.find(
                                                    (a) =>
                                                      `${a.id}` ===
                                                      `${foundDeptTemplate?.departmentTemplate?.extAppId}`
                                                  );
                                                return (
                                                  <>
                                                    <li>
                                                      <div className="mt-1">
                                                        <div className="d-flex">
                                                          <div className="border border-dark px-1 py-0 bg-dark text-light">
                                                            {
                                                              t.departmentTemplateName
                                                            }{" "}
                                                            {foundApp
                                                              ? `[${foundApp.name}]`
                                                              : ``}
                                                          </div>
                                                          <div
                                                            className="d-flex flex-wrap flex-grow-1"
                                                            style={{}}
                                                          >
                                                            {t.departmentTemplate?.items
                                                              .filter(
                                                                (i) =>
                                                                  !i
                                                                    .masterJavaBaseModel
                                                                    ?.hidden
                                                              )
                                                              .map((i) => {
                                                                const foundPCDTI =
                                                                  c.panelCode?.panelCodeDepartmentTemplateItems.find(
                                                                    (ix) =>
                                                                      `${ix.departmentTemplateItemId}` ===
                                                                      `${i.masterJavaBaseModel?.id}`
                                                                  );
                                                                return (
                                                                  <>
                                                                    <div
                                                                      className="border border-dark mx-1"
                                                                      style={{
                                                                        backgroundColor:
                                                                          foundPCDTI
                                                                            ? foundPCDTI?.excluded
                                                                              ? `grey`
                                                                              : foundPCDTI.completedDate
                                                                              ? `lightgreen`
                                                                              : ``
                                                                            : `lightsalmon`,
                                                                      }}
                                                                    >
                                                                      <div>
                                                                        <div>
                                                                          {
                                                                            i.name
                                                                          }{" "}
                                                                          {showDate.current ? (
                                                                            <>
                                                                              (#
                                                                              {
                                                                                foundPCDTI
                                                                                  ?.masterJavaBaseModel
                                                                                  ?.id
                                                                              }
                                                                              )
                                                                            </>
                                                                          ) : (
                                                                            <>

                                                                            </>
                                                                          )}
                                                                        </div>

                                                                        {editMode.current ? (
                                                                          <>
                                                                            <div>
                                                                              {/* <div className="d-flex">
                                                                                <div>
                                                                                  <small>
                                                                                    <strong>
                                                                                      Start
                                                                                    </strong>
                                                                                  </small>
                                                                                  <input
                                                                                    value={
                                                                                      foundPCDTI?.startDate?.split(
                                                                                        "T"
                                                                                      )?.[0]
                                                                                    }
                                                                                    type="date"
                                                                                    onChange={(
                                                                                      e
                                                                                    ) => {
                                                                                      if (
                                                                                        foundPCDTI
                                                                                      ) {
                                                                                        foundPCDTI.startDate = `${e.target.value}T00:00:00Z`;
                                                                                        render();
                                                                                      }
                                                                                    }}
                                                                                  />
                                                                                </div>
                                                                                <div>
                                                                                  <small>
                                                                                    <strong>
                                                                                      End
                                                                                    </strong>
                                                                                  </small>

                                                                                  <input
                                                                                    value={
                                                                                      foundPCDTI?.endDate?.split(
                                                                                        "T"
                                                                                      )?.[0]
                                                                                    }
                                                                                    type="date"
                                                                                    onChange={(
                                                                                      e
                                                                                    ) => {
                                                                                      if (
                                                                                        foundPCDTI
                                                                                      ) {
                                                                                        foundPCDTI.endDate = `${e.target.value}T00:00:00Z`;
                                                                                        render();
                                                                                      }
                                                                                    }}
                                                                                  />
                                                                                </div>
                                                                              </div> */}
                                                                              <div className="d-flex">
                                                                                <small>
                                                                                  <strong>
                                                                                    Completed
                                                                                  </strong>
                                                                                </small>

                                                                                {foundPCDTI?.completedDate ? (
                                                                                  <>
                                                                                    Completed:{" "}
                                                                                    {(() => {
                                                                                      try {
                                                                                        return Intl.DateTimeFormat(
                                                                                          "en-US",
                                                                                          {
                                                                                            dateStyle:
                                                                                              "medium",
                                                                                          }
                                                                                        ).format(
                                                                                          new Date(
                                                                                            foundPCDTI?.completedDate ??
                                                                                              ""
                                                                                          )
                                                                                        );
                                                                                      } catch (e) {
                                                                                        return "";
                                                                                      }
                                                                                    })()}
                                                                                    <button
                                                                                      className="btn btn-sm btn-secondary px-1 py-0"
                                                                                      onClick={() => {
                                                                                        foundPCDTI.completedDate =
                                                                                          undefined;
                                                                                        render();
                                                                                      }}
                                                                                    >
                                                                                      Undo
                                                                                    </button>
                                                                                  </>
                                                                                ) : (
                                                                                  <>
                                                                                    <input
                                                                                      value={
                                                                                        foundPCDTI?.completedDate?.split(
                                                                                          "T"
                                                                                        )?.[0]
                                                                                      }
                                                                                      type="date"
                                                                                      onChange={(
                                                                                        e
                                                                                      ) => {
                                                                                        if (
                                                                                          foundPCDTI
                                                                                        ) {
                                                                                          foundPCDTI.completedDate = `${e.target.value}T00:00:00Z`;
                                                                                          render();
                                                                                        }
                                                                                      }}
                                                                                    />
                                                                                  </>
                                                                                )}
                                                                              </div>
                                                                            </div>
                                                                          </>
                                                                        ) : (
                                                                          <></>
                                                                        )}
                                                                        {showDate.current ? (
                                                                          <>
                                                                            <div>
                                                                              <strong>
                                                                                {(() => {
                                                                                  try {
                                                                                    return Intl.DateTimeFormat(
                                                                                      "en-US",
                                                                                      {
                                                                                        dateStyle:
                                                                                          "medium",
                                                                                      }
                                                                                    ).format(
                                                                                      new Date(
                                                                                        foundPCDTI?.startDate ??
                                                                                          ""
                                                                                      )
                                                                                    );
                                                                                  } catch (e) {
                                                                                    return "";
                                                                                  }
                                                                                })()}{" "}
                                                                                to{" "}
                                                                                {(() => {
                                                                                  try {
                                                                                    return Intl.DateTimeFormat(
                                                                                      "en-US",
                                                                                      {
                                                                                        dateStyle:
                                                                                          "medium",
                                                                                      }
                                                                                    ).format(
                                                                                      new Date(
                                                                                        foundPCDTI?.endDate ??
                                                                                          ""
                                                                                      )
                                                                                    );
                                                                                  } catch (e) {
                                                                                    return "";
                                                                                  }
                                                                                })()}
                                                                              </strong>
                                                                            </div>
                                                                            {foundPCDTI?.completedDate ? (
                                                                              <>
                                                                                Completed:{" "}
                                                                                {(() => {
                                                                                  try {
                                                                                    return Intl.DateTimeFormat(
                                                                                      "en-US",
                                                                                      {
                                                                                        dateStyle:
                                                                                          "medium",
                                                                                      }
                                                                                    ).format(
                                                                                      new Date(
                                                                                        foundPCDTI?.completedDate ??
                                                                                          ""
                                                                                      )
                                                                                    );
                                                                                  } catch (e) {
                                                                                    return "";
                                                                                  }
                                                                                })()}
                                                                              </>
                                                                            ) : (
                                                                              <>

                                                                              </>
                                                                            )}
                                                                          </>
                                                                        ) : (
                                                                          <></>
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                  </>
                                                                );
                                                              })}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </li>
                                                  </>
                                                );
                                              }
                                            )}
                                          </ol>
                                        </div>
                                      </div>
                                    </li>
                                  </>
                                );
                              })}
                            </ol>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </li>
                </>
              );
            })}
          </ol>
        </div>
      </div>
    </>
  );
};

export default JobDeptTemplatesPage;
